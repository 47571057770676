
import WDElement from '../wd-element';
import WDButton from '../button/wd-button';
import WDUtil from '../../core/wd-util';

export default class WDNavbarMenu extends WDElement {

	#toggleButton;
	#wrapper;
	#list;
	#config = { 
		toggle_icon: 'fa-solid fa-bars',
		collapse: { mode:false }
	};
	
	constructor(cfg)
	{
		super().classList.add('wd-navbar-menu');
		
		this.#wrapper = wdc('<div class="wd-navbar-menu-wrapper"/>');
		this.#list = wdc('<ul class="wd-navbar-menu-list"/>');
		
		this.#wrapper.append(this.#list);
		this.append(this.#wrapper);

		if (cfg) WDUtil.merge(this.#config,cfg);

		if (this.#config.collapse?.mode) this.collapsible(this.#config.collapse);

		return this;
	};

	list() { return this.#list; };
	items() { return this.list().children; };
	itemArray() { return Array.from(this.items()); };
	
	collapsible(collapseCfg)
	{
		const mode = collapseCfg?.mode || 'menu';							//modes: 'hide', 'bar', 'icon', 'menu'
		const breakpoint = collapseCfg?.breakpoint || 'md';		//eventually look for a WDConfig default breakpoint

		console.log('Mode',mode,breakpoint);

		this.classList.add('collapsible');		
		this.setAttribute('data-collapse-mode',mode);
		this.setAttribute('data-collapse-breakpoint',breakpoint);
		
		this.toggleButton();
		
		return this;
	};

	toggleButton()
	{
		if (!this.#toggleButton)
		{
			this.#toggleButton = new WDButton({icon:this.#config.toggle_icon});
			this.#toggleButton.classList.add('wd-navbar-menu-toggler');
			this.#toggleButton.on('click',() => this.classList.toggle('is-open') );

			this.prepend(this.#toggleButton);
		}
		
		return this.#toggleButton;
	};

	//	
	add(title,cfg)
	{
		const btn = new WDButton(title,cfg);

		//add to our common container
		this.addItem(btn);
		
		return btn;
	};

	addAtIndex(title,cfg,idx)
	{
		const btn = new WDButton(title,cfg);
		const item = this.addItem(btn);
		
		//add to our common container
		this.addItemAtIndex(item,idx);
		
		return btn;
	};

	addDivider()
	{
		const item = wdc('<li class="wd-navbar-menu-divider"/>');
		this.#list.append(item);
	};
	
	addItem(obj)
	{
		const item = wdc('<li class="wd-navbar-menu-item"/>');
		if (obj) item.append(obj);

		//add to object
		this.#list.append(item);
		
		return item;
	};
	
	addText(str)
	{
		const item = this.addItem(str);
		item.classList.add('wd-navbar-menu-text');

		return item;
	};

	empty()
	{
		this.#list.replaceChildren();
		return this;
	};

	addItemAtIndex(item,idx)
	{
		const items = this.#list.querySelectorAll('.wd-navbar-menu-item');

		if (idx == 0) this.#list.prepend(item);
		else if (idx == items.length - 1) this.#list.append(item);
		else items[idx].before(item);
      
		return item;
	};

	removeItem(item)
	{
		item.remove();
	};

	/** remove menu item at specified index **/
	removeAtIndex(idx)
	{
		const items = this.#list.querySelectorAll('.wd-navbar-menu-item');
		wdc(items[idx]).remove();
	};

	/** remove all items after the passed element **/
	removeAfterItem(item)
	{
		const items = this.#list.querySelectorAll('.wd-navbar-menu-item');
		const idx = items.indexOf( item.element() );
		this.removeAfterIndex(idx);
	};

  /** remove all items after the passed index **/
  removeAfterIndex(idx)
  {
    idx = parseInt(idx) + 1;
		const items = this.#list.querySelectorAll('.wd-navbar-menu-item');

		for (const itemIdx in items)
		{
			if (itemIdx > idx) items[itemIdx].remove();
		}
  };

};

customElements.define('wd-navbar-menu',WDNavbarMenu);
