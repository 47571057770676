
import WDElement from '../wd-element';
import WDNavbar from '../navbar/wd-navbar';

export default class WDCardTabs extends WDElement {

	#cards = [];
	#navbar;
	#body;
	#options = {
		navigation_mode: 'tabs',
		collapsible: false,
		breakpoint: 'md',
		tab_position: 'right'
	};

	constructor(opts)
	{
		super().classList.add('wd-card-tabs');
		
		if (opts) Object.assign(this.#options,opts);

		if (this.#options.collapsible)
		{
			this.navbar()[this.#options.tab_position]().collapsible({mode:'menu',breakpoint:this.#options.breakpoint});
			this.navbar()[this.#options.tab_position]().toggleButton().fasIcon('ellipsis-v');
		}
	};

	cards() { return this.#cards; };

	navbar()
	{
		if (!this.#navbar)
		{
			this.#navbar = new WDNavbar();
			this.prepend(this.#navbar);
		}
		
		return this.#navbar;
	};
	
	body()
	{
		if (!this.#body)
		{
			this.#body = wdc('<div class="card-tabs-body"/>');
			this.append(this.#body);
		}
		
		return this.#body;
	};

	reset()
	{
		this.body().replaceChildren();
		this.navbar()[this.#options.tab_position]().replaceChildren();
		this.#cards = [];
	};

	addCard(card,labelName,icon)
	{
		this.body().append(card);
		card.hide();
		
		const shortcode = 'wd-card-' + labelName.toLowerCase().replace(' ','');
		card.classList.add(shortcode);

		//build our storage object
		const obj = { card: card, shortcode: shortcode, label: labelName, icon:icon };
		this.#cards.push(obj);

		this.addCardNav(obj);

		//if this is the first card, show it
		if (this.#cards.length == 1) 
		{
			this.showCard(shortcode);
		}
		
		return obj;
	};

	showCard(shortcode)
	{
		this.updateCardNav(shortcode);

		this.#cards.forEach( (cardObj,idx) => {
		
			if (cardObj.shortcode == shortcode) 
			{
				cardObj.card.trigger('card:show');
				cardObj.card.show();
				cardObj.card.trigger('card:shown');
								
				if (this.#options.collapsible == true)
				{
					this.navbar()[this.#options.tab_position]().toggleButton().text(cardObj.label);
					if (cardObj.icon) this.navbar()[this.#options.tab_position]().toggleButton().icon(cardObj.icon);
				}

			}
			else 
			{
				cardObj.card.trigger('card:hide');
				cardObj.card.hide();
				cardObj.card.trigger('card:hidden');
			}
			
		});

	};

	addCardNav(item)
	{
		const btn = this.navbar()[this.#options.tab_position]().add(item.label);
		btn.setAttribute('data-label',item.shortcode);
		btn.on('click',() => this.showCard(item.shortcode) );

		if (item.icon) btn.icon(item.icon);
	};
	
	updateCardNav(shortcode)
	{
		const sel = this.navbar()[this.#options.tab_position]().querySelector('.is-active');
		if (sel) sel.classList.remove('is-active');

		this.navbar()[this.#options.tab_position]().querySelector('[data-label="' + shortcode + '"]').classList.add('is-active');

		//this.navbar().hideMenu();
	};

	getCardIndex(card)
	{
		let idx = -1;
		
		_cards.some( (curCard,curIdx) => {
			if (curCard.card == card)
			{
				idx = curIdx;
				return true;
			}
		});
		
		return idx;
	};

	setCardLabelAt(idx,title)
	{
		const card = this.#cards[idx];
		const btn = this.navbar()[this.#options.tab_position]().items()[idx].querySelector('.title');
		
		card.labelName = title;
		btn.text(title);
	}

	removeCardAt(idx)
	{
		this.navbar()[this.#options.tab_position]().removeAtIndex(idx);		
		
		this.#cards[idx].card.remove();
		this.#cards.splice(idx,1);

		//show the first card
		if (this.#cards.length > 0)
		{
			this.showCard(this.#cards[0].shortcode);
		}

	};

};

customElements.define('wd-card-tabs',WDCardTabs);
