
/**
	*	@class - WDSiteNavigation
	*	@desc - evalulates the current or passed uri and loads the associate pane
	*					also handles the browser back button 
	*/

import WDElement from '../gui/wd-element';
import WDButton from '../gui/button/wd-button';

export default class WDSiteNavigation extends WDElement {

	#config = null;
	#panes = null;
	#toggler = null;
					
	connectedCallback()
	{
		//close the menu when user clicks on anything but a button
		this.on('click',() => this.setHidden());

		//build menu structure
		this.addToggler();
		this.addBackground();
	};

	addBackground()
	{
		//add the background we use for expanded mobile view
    this.append( wdc('<div class="wd-site-navbar-mobile-background"/>') );
	};

	addToggler()
	{
		const togglerWrapper = wdc('<div class="wd-site-navbar-toggler"/>');
		this.#toggler = new WDButton({fasIcon:'bars'});
		this.#toggler.on('click',(evt) => this.toggleVisibility(evt) );
		togglerWrapper.append(this.#toggler);
		this.prepend(togglerWrapper);
	};

	toggleVisibility(evt)
	{
		evt.stopPropagation();

		if (this.classList.contains('is-expanded'))
		{
			this.setHidden();
		}
		else
		{
			this.setVisible();
		}
	};

	setVisible()
	{
		this.classList.add('is-expanding','is-expanded');
		setTimeout( () => this.classList.remove('is-expanding'), 250);
	};
	
	setHidden()
	{
		this.classList.add('is-expanding');
		this.classList.remove('is-expanded');
		setTimeout( () => this.classList.remove('is-expanding'), 250);
	};

	hide()
	{
		this.classList.remove('is-expanded');
	};

};


/**
	*/
export class WDSiteNavbarMenu extends WDElement {

	constructor()
	{
		super().classList.add('wd-site-navbar-menu');
	};

	add(cfg)
	{
		const item = new WDSiteNavbarMenuItem(cfg);
		this.append(item);

		item.on('click',evt => {
			
			if (item.hasSubmenu()) evt.stopPropagation();
			else this.closest('wd-site-navigation').setHidden();

		});

		return item;
	};

};

/**
	*/
class WDSiteNavbarMenuItem extends WDElement {

	#button;
	#submenu = null;

	constructor(cfg)
	{
		super().classList.add('wd-site-navbar-menu-item');

		this.#button = new WDButton(cfg);
		this.append(this.#button);
	};

	icon(str) { return this.#button.icon(str); };
	fasIcon(str) { return this.#button.fasIcon(str); };
	farIcon(str) { return this.#button.farIcon(str); };
	
	label(str)
	{
		return this.#button.label(str);
	};
	
	suffix()
	{
		return this.#button.suffix();
	};

	hasSubmenu()
	{
		return this.#submenu != null;
	};

	submenu()
	{
		if (!this.#submenu)
		{
			this.#submenu = new WDSiteNavbarMenu();
			
			const sm = wdc('<div class="wd-site-navbar-submenu collapsible"/>');
			const smo = wdc('<div class="wd-site-navbar-submenu-overflow"/>');
			
			smo.append(this.#submenu);
			sm.append(smo);
			this.append(sm);

			//this.addClass('has-submenus');
			this.suffix().replaceChildren();
			this.suffix().append( wdc('<span class="fa-solid fa-chevron-right"/>') );

			this.on('click',evt => {
				evt.stopPropagation();
				this.toggleSubmenu();
			});
		}
		
		return this.#submenu;
	};

	toggleSubmenu()
	{
		const collapseWrapper = this.#submenu.parentElement.parentElement;
		if (collapseWrapper.classList.contains('is-open'))
		{
			this.hideAllSubmenus();
		}
		else
		{
			this.setSubmenuVisible();
		}
	};

	setSubmenuVisible()
	{
		const collapseWrapper = this.#submenu.parentElement.parentElement;
		this.hideAllSubmenus();
				
		collapseWrapper.classList.add('is-collapsing');
		collapseWrapper.classList.add('is-open');
		this.classList.add('is-submenu-open');

		setTimeout( () => collapseWrapper.classList.remove('is-collapsing'), 500);
	};
	
	hideAllSubmenus()
	{
		const parentMenu = this.closest('.wd-site-navbar-menu');

		parentMenu.querySelectorAll('.is-submenu-open').forEach( elem => {
			elem.classList.remove('is-submenu-open');
		});
		
		this.closest('.wd-site-navbar-menu').querySelectorAll('.wd-site-navbar-submenu.is-open').forEach( elem => {

			elem.classList.add('is-collapsing');
			elem.classList.remove('is-open');
			
			setTimeout( () => elem.classList.remove('is-collapsing'), 500);
		});
	};

	onDisabled(evtNames,handler)
	{
		return this.#button.on(evtNames,handler);
	};

};

customElements.define('wd-site-navbar-menu-item',WDSiteNavbarMenuItem);
customElements.define('wd-site-navbar-menu',WDSiteNavbarMenu);
//customElements.define('wd-site-navbar',WDSiteNavbar);
customElements.define('wd-site-navigation',WDSiteNavigation);

/**
	[
		{ 
			label:'Devices', 
			icon:'fa-solid fa-computer', 
			items: [
				{ label: 'View Devices', click: bleh() },
				{ label: 'Add Device', click: add() },				
			]
		}
	],
	[
		{
			label: 'Login',
			icon: 'fa-solid fa-login',
			permissions: 'NotLogin'
		},
		{
			label: 'Logout',
			icon: 'fa-solid fa-logout',
			permissions: 'LoggedIn'
		}
	]		

	I'm starting to think this should either be a global config, or an ability to write to
	it from other classes.  Like I want this one thing to show:
	- Login Menu (maybe we do that from the WDAuth tool)
	- Site Locations (maybe we do that from site locations)
	- which means we need a standard way to add a menu group and then add items and subitems to it

	*/

