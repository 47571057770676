
import WDElement from '../wd-element';
import WDButton from '../button/wd-button';

export default class WDModal extends WDElement {

	//build the bootstrap modal reference
	#modalRef = null;
	#container = null;
	#dialog = null;
	#content = null;
	#header = null;
	#footer = null;
	#toolbar = null;
	#title = null;
	#btns = [];
	#closeButton = null;
	#uri = null;
	#uriConfig = null;

	#options = { 
		position:'center',
		allowClose:true,
		title:"Modal Window",
		allowOutsideClose:true,
		confirmClose:false,
		closeButtonTitle:'Close',
		cleanupOnClose:true
	};

	constructor(opts)
	{
		if (!window?.WDModalIndex) window.WDModalIndex = 1;
	
		super().classList.add('modal','fade');
		this.setAttribute('tabindex','-1');
		this.setAttribute('role','dialog');

		this.style.zIndex = 50000 + WDModalIndex;

		WDModalIndex++;

		if (opts) this.#options = Object.assign({},this.#options,opts);

		if (this.#options.allowOutsideClose == false) 
		{
			this.#options.backdrop = 'static';
			this.#options.keyboard = false;
		}

		//build the modal
		this.buildLayout();

		return this;	
	};

	//readonly accessors
	header() { return this.#header;};
	footer() { return this.#footer;};
	toolbar() { return this.#toolbar;};
	body() { return this._body;};

  uri(str)
  {
    if (typeof(str)=='undefined') return this.#uri;
    else 
    {
      this.#uri = str;
      return this;
    }
  };

  uriConfig(str)
  {
    if (typeof(str)=='undefined') return this.#uriConfig;
    else 
    {
      this.#uriConfig = str;
      return this;
    }
  };

	buildLayout()
	{
		this.#dialog = wdc('<div class="modal-dialog"/>');
		this.#content = wdc('<div class="modal-content"/>');
		this.#toolbar = wdc('<div class="modal-toolbar"/>');
		this.#header = wdc('<div class="modal-header"/>');
		this.#footer = wdc('<div class="modal-footer"/>');
		this._body = wdc('<div class="modal-body allow-scroll"/>');

		//dialog position
		this.setPosition(this.#options.position);

		//assemble our components
		this.#content.append(this.#header);
		this.#content.append(this.#toolbar);
		this.#content.append(this._body);
		this.#content.append(this.#footer)
		this.#dialog.append(this.#content);

		//add to the document
		document.body.prepend(this);

		//now that we are part of the DOM, assemble
		this.append(this.#dialog);

		//add a cleanup trigger
		if (this.#options.cleanupOnClose) this.on('modal:hidden',() => this.cleanup() );

		//add our close buttons
		this.addClose();

		//set our options and size if passed
		if (this.#options.title) this.title(this.#options.title);
		if (this.#options.size) this.size(this.#options.size);

		//abstract event layer
		this.on('show.bs.modal',ev => this.trigger('modal:show') );
    this.on('shown.bs.modal',ev => this.trigger('modal:shown') );
    this.on('hide.bs.modal',ev => this.trigger('modal:hide') );
    this.on('hidden.bs.modal',ev => this.trigger('modal:hidden') );
    this.on('hidePrevented.bs.modal',ev => this.trigger('modal:hideprevented') );

		//
		this.#modalRef = new bootstrap.Modal(this,this.#options);

	};

	show() {
		this.#modalRef.show();
	};

	hide(force)
	{
		if (this.#options.confirmClose == true && !force)
		{
			this.confirmClose();
		}
		else
		{
			this.#modalRef.hide();
		}
	};

  confirmClose()
  {
  	const m = new WDModal({title:'Confirm Close'});

  	const msg = (this.#options.confirmCloseMessage) ? this.#options.confirmCloseMessage : 'Are you sure you want to close this window?  All changes will be lost';
    m.body().append( wdc('<p class="activity-message"/>').append(msg) );

    const btn = m.addButton("Okay").theme('danger');
    btn.on("click",(evt) => this.hide() );

    m.show();
  };


  invisible(mode)
  {     	
    if (mode == true)
    {   	
      document.body.classList.remove('modal-open');
      this.hide();
    }   	
    else	
    {   	
      document.body.classList.add('modal-open');
      this.show();
    }		
  };

	addClose()
	{
		if (this.#options.allowClose==false) return;
		this.closeButton();
	};
	
	closeButton()
	{
		if (!this.#closeButton)
		{
			this.#closeButton = new WDButton({icon:'fa-solid fa-times'});
			this.#closeButton.classList.add('modal-close-btn');
			this.#closeButton.on('click',() => this.hide() );

			this.#header.append(this.#closeButton);
		}
		
		return this.#closeButton;
		
	};
		
	title(str)
	{
		if (!this.#title)
		{
			this.#title = wdc('<h4 class="modal-title"></h4>');
			this.#header.prepend(this.#title);
		}

		this.#title.append(str);

		if (this.#options.icon)
		{
			let icon = wdc('<span/>');
			icon.classList.add('modal-icon',this.#options.icon);
			this.#title.prepend(icon);
		}

		return this.#title;
	};

	size(size)
	{
		this.#dialog.classList.add(size);
	};

	//default,primary,success,info,warning,danger,link
	addButton(cfg)
	{
		let btn = new WDButton(cfg);
		this.#footer.append(btn);

		//store a reference to the button by name
		this.#btns.push(btn);

		return btn;
	};

	buttons()
	{
		return this.#btns;
	};

	setPosition(position)
	{
		if (position == 'center') this.#dialog.classList.add('modal-dialog-centered');
		else this.#dialog.classList.remove('modal-dialog-centered');
	};

	cleanup()
	{
		this.remove();
		WDModalIndex--;
	};

  hideFooter()
  {
    this.footer().style.display = 'none';
  };
  
  showFooter()
  {
    this.footer().style.display = 'flex';
  };

	/*
	show.bs.modal	This event fires immediately when the show instance method is called. If caused by a click, the clicked element is available as the relatedTarget property of the event.
	shown.bs.modal	This event is fired when the modal has been made visible to the user (will wait for CSS transitions to complete). If caused by a click, the clicked element is available as the relatedTarget property of the event.	
	hide.bs.modal	This event is fired immediately when the hide instance method has been called.
	hidden.bs.modal	This event is fired when the modal has finished being hidden from the user (will wait for CSS transitions to complete).
	loaded.bs.modal
	*/

}

customElements.define('wd-modal',WDModal);
