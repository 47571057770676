

/***********************************************************************
	Local Storage Functions
***********************************************************************/

Storage.prototype.setObject = function(key, value) {

    this.setItem(key, JSON.stringify(value));
};
 
Storage.prototype.getObject = function(key) {

    return JSON.parse(this.getItem(key));

};

//setup setString for consistency sake (setObject & setString)
Storage.prototype.setString = function(key, value) {
    this.setItem(key, value);
};
 
Storage.prototype.getString = function(key) {
    return this.getItem(key);
};


/** for interoperability with wdStorage.  assumes all values are objects **/

Storage.prototype.get = function(key)
{
  if ( typeof(this.getItem(key)) == 'string') return JSON.parse(this.getItem(key));
  else return null;
};

Storage.prototype.set = function(key,val)
{
  this.setItem(key,JSON.stringify(val));
};

