
import { WDFormBasic } from './wd-form-basic';
import WDChecklist from '../dropdown/wd-checklist';

export class WDFormChecklist extends WDFormBasic {

  #checkList;

  constructor(cfg)
  {
    cfg.type = 'checklist';

    if (!cfg.icons)
    {
      cfg.icons = {
        unchecked: 'fa-regular fa-square',
        checked: 'fa-regular fa-square-check'
      };
    }

    //
    const checkList = new WDChecklist(cfg);
    super(checkList,cfg).classList.add('wd-checklist');

    this.#checkList = checkList;
    this.#checkList.on('change',() => this.trigger('change') );
    //this.#checkList.on('value:set',() => this.trigger('value:set') );

		//disable the button if readonly
		if (this.config().readonly) this.readonly(this.config().readonly);
	};

	options() { return this.#checkList.options(); };	
	val(val) { return this.#checkList.val(val); };
  setValFromLabels(lbls) { return this.#checkList.setValFromLabels(lbls); };

};

customElements.define('wd-form-checklist',WDFormChecklist);
