
import WDPanes from '../gui/pane/wd-panes';
import WDHistory from './wd-history';
import WDRes from '../core/fetch/wd-res';
import WDActivity from '../gui/modal/wd-activity';

/**
	*	@class - WDSiteController
	*	@desc - evalulates the current or passed uri and loads the associate pane
	*					also handles the browser back button 
	*
	* @events
			-	site:panes:active	- a new pane has been made active
			- site:panes:ready - all plugins have been initialized
			- site:auth:authorized - user has been authorized by the api
			- site:auth:logout	- user logged out from the api
	*/
export default class WDSiteController extends WDPanes {

	#plugins = {};
	#routes;
	#loadedCSS = new Set();
	#history = null;		//our browser state history manager
	#handlerQueue = [];
	#handy;

	#config = { 	
		menus: [],
		plugins: []
	};								//stores options passed when a uri is pushed

	constructor()
	{
		super();

		//store for global access - WDController is kinda traditionally what I used,
		//but may change this.  OR wd-site-controller may become wd-launcher...
		globalThis.WDController = this;
		
		this.#config = {
			plugins: WDConfig.plugins || [],
		};

		this.#history = new WDHistory();

		//makes the back button work
		window.addEventListener('popstate',(evt) => this.handleBrowserBack(evt) );

		this.on('site:panes:active',(evt) => {
			document.body.dataset.wdPlugin = evt.detail.plugin;
			this.dataset.plugin = evt.detail.plugin;
		});

		this.on('site:panes:ready',() => {

			const auth = document.querySelector('wd-site-auth');
			if (auth)
			{
				auth.on('auth:authorized',() => this.load() );
				auth.on('auth:logout',() => this.resetLauncher() );
			}
			//no auth system, just load the controller
			else
			{
				this.load();
			}			
		
		});

		this.initPlugins();				
	};


	globalTrigger(evtName,evtData)
	{
		const event = new CustomEvent(evtName,{detail:evtData});
		window.dispatchEvent(event);
	};
	
	config() { return this.#config; };
	handler() { return this.#handy; };
	history() { return this.#history; };
	active() { return this.#handy; };

	load()
	{
		const uri = (this.uriPath() == '/') ? WDConfig.defaultURI : this.uriPath();
		this.root(uri,true);
	};

	resetLauncher()
	{
		this.reset();
		this.trigger('site:panes:reset');
		this.initPlugins();
	};

	//accessor for the plugin config so we can get other info about our setup
	pluginConfig()
	{
		return JSON.parse(sessionStorage.WDPluginCache);
	};
	
	initPlugins()
	{
		//plugins have already been loaded and stored in a session, arse and use
		if ( sessionStorage.WDPluginCache !== undefined )
		{
			//off the main loop so callers can catch the event
			setTimeout( () => {
				this.#plugins = JSON.parse(sessionStorage.WDPluginCache);
				this.mapRoutes();
				
				this.trigger('site:panes:ready');
			}, 1);
		}
		else
		{
			//fetch the plugin configs
			this.initPluginsFromJSON().then( () => {
				this.mapRoutes();
				this.trigger('site:panes:ready');			
			});
		}
	};

	
	initPluginsFromJSON()
	{
		return new Promise( (resolve,reject) => {

			const cache = {};
				
			//load all the config files for the passed plugins
			let inittedPlugins = 0;

			//loop through all the configured plugins and load their config.json file		
			this.#config.plugins.forEach( pluginName => {
		
				const uri = `/assets/plugins/${pluginName}/config.json`;

				WDRes.getJSON(uri).then( cfg => {

					//cache the config in session storage
					this.#plugins[pluginName] = cfg;
					inittedPlugins++;

					if (inittedPlugins == this.#config.plugins.length) 
					{
						sessionStorage.WDPluginCache = JSON.stringify(this.#plugins);
						resolve(true);
					}
				})
				.catch(err => {

					console.log('Could not load plugin "' + pluginName + '" with config file at ' + uri);
					console.log(err);

					reject();
				});
	
			});	

		});

	};

	/**
		*/
	mapRoutes()
	{
		this.#routes = new Map();
		
		Object.entries( this.#plugins ).forEach( ([pluginName, pluginCfg]) => {

			pluginCfg.routes.forEach( route => {
			
				const uris = Array.isArray(route.uri) ? route.uri : [route.uri];
				uris.forEach( uri => {

					this.#routes.set(uri, {
						handler: route.handler,
						plugin: pluginCfg
					});

				});
			});
		});
	};

  getURIHandlers(uri)
  {
    const handlers = [];
    const arr = uri.substr(1).split('/');   //drop the first "/" before splitting
    let worker = '';

    arr.forEach( item => {
    
      //the uri to load
      worker += '/' + decodeURIComponent(item);

      const res = this.getURIHandler(worker);
        
      if (res != null)
      {
        handlers.push(res);
        return true;
      }

    });

    return handlers;
  };


	getURIHandler(curURI)
	{
		let retData = null;
		
		for (const [routeURI, routeConfig] of this.#routes.entries()) 
		{
			//we have a match
			const matchData = typeof(URLPattern) != 'undefined' ? this.matchRoute(routeURI,curURI) : this.matchSafariRoute(routeURI,curURI);

			if (matchData)
			{
				retData = {
					route: routeURI,
					uri: curURI,
					handler: routeConfig.handler,
					data: matchData,
					plugin: routeConfig.plugin,
				};
				
				break;
			}
			
    }

    return retData;
	};

	/**
		* handles route patterns with names, returning the values in an object
		* like /builder/:builderId/floorplans/:floorplanId returns
		* { builderId: 1, floorplanId:2 }
		*/	
	matchRoute(routeURI,matchURI)
	{
		const pattern = new URLPattern(routeURI,window.location.origin);
		const match = pattern.exec(window.location.origin + matchURI);

		return match ? match.pathname.groups : null;
	};

	/**
		* I'm looking at you, safari
		*/
	matchSafariRoute(routeURI,matchURI)
	{
    // Convert :param to a regex named capture group (?<param>[^/]+)
    const regexPattern = routeURI.replace(/:([a-zA-Z_][a-zA-Z0-9_]*)/g, (_, paramName) => `(?<${paramName}>[^/]+)`);
 
    const patternStr = '^' + regexPattern + '$';
    const regex = new RegExp(patternStr);
        
		// Test the URL against the generated regex pattern
		const match = matchURI.match(regex);

		if (match)
		{
			//.groups is only there if there's a named pattern
			return match?.groups || match;
		}
		else
		{
			return null;
		}
	};
	

	/**
		*	@method root
		*	@desc - called when our class is first loaded.	It loops through each member of the current uri
		*					and loads each matching pane in succession.	Only the last matching pane is loaded with animation
		*/
	root(uri,skipPush)
	{
		//we have a pane, then dissolve out first, otherwise just load the page
		if (this.currentPane())
		{
			//hide the current module.	At the end of the hide, run our handler for this URI
			this.once('transitionend',() => this.loadRoot(uri,skipPush) ); 
			this.classList.add('hiding');
		}
		else
		{
			this.loadRoot(uri,skipPush);
		}
	};

	loadRoot(uri,skipPush)
	{
		this.reset();
				
		//get configs for all registered uris up the tree
		const handlers = this.getURIHandlers(uri);
		if (handlers.length == '0') return false;

		handlers.some( (handler,idx) => {

			//direct mode since we are loading root uri
			handler.transition = null;
			
			//we are on the last one
			if (idx == handlers.length - 1)
			{
				if (skipPush) handler.transition = 'replace';
				else handler.transition = 'push';
			}

			this.#handlerQueue.push(handler);

			//may need to tweak more later, but keeps us from running over and over again
			//for docmgr-style urls
			if (handler.plugin?.load_order == 'last') return true;
			
		});

		this.processHandlerQueue();
	};

	/**
		*	@method replace
		*	@desc - for directly setting a uri.	This means we replace the current pane with a new one
		*					and we replace the current history entry instead of pushing a new one on
		*	@param {string} uri - uri to set
		*	@param {object} storage - data to store in window.history.state with this uri
		*/
	replace(uri,storage)
	{
		//get configs for all registered uris up the tree
		const handlers = this.getURIHandlers(uri);
		if (handlers.length == '0') return false;

		const handler = handlers[handlers.length-1];
		
		handler.transition = 'replace';
		handler.storage = storage;

		this.#handlerQueue.push(handler);
		this.processHandlerQueue();
	};

	/**
		*	@method push
		*	@desc - for pushing a new uri onto our stack.	A new history entry is created
		*					and a new pane is pushed onto the stack after the current one
		*	@param {string} uri - uri to set
		*	@param {object} storage - data to store in window.history.state with this uri
		*	@param {object} options - additional options for the page load (mode)
		*/
	push(uri,storage)
	{
		//get configs for all registered uris up the tree
		const handler = this.getURIHandler(uri);
		if (!handler) 
		{
			throw new Error('Could not find handler for ' + uri);
		}

		//store how we got here
		handler.transition = 'push';

 		if (handler.route && this.#history.get('route') == handler.route && handler.route.indexOf('.*') == -1)
 		{
 			handler.route_match = true;		//tag that this page is on the same level
 			handler.transition = 'replace';
 		}

		this.#handlerQueue.push(handler);
		this.processHandlerQueue();
	};

	/**
		like push, but "stacks" the next pane on top of the previous without any kind of uri check
		*/
	stack(uri,storage,options)
	{
		//get configs for all registered uris up the tree
		const handlers = this.getURIHandlers(uri);
		if (handlers.length == '0') return false;

		this.#handlerQueue.push(handler);
		this.processHandlerQueue();

	};

	/**
		*	@method handleBrowserBack
		*	@desc - does the legwork of handling a back button push or pop
		*/
	handleBrowserBack(evt)
	{
		const state = this.#history.state();
		const curConfig = this.currentPane().appConfig(); //this.#history.state();

		if (typeof(curConfig) != 'object' || typeof(state) != 'object') return;

		let loaded = false;

		//if our current page was pushed, then just pop it off and change the state
		if (curConfig.transition == 'push') 
		{
			//figure out what pane is now responsible for the current uri
			const pane = this.paneAtURI(state.uri);
			if (pane)
			{
				this.popTo(pane,true);
				loaded = true;
			}
		}

		 //we couldn't find the pane in our stack of currently loaded ones,
		 //so jumnp directly to it
		if (!loaded) this.root(state.uri,true);
	};

	/**
		*	@method uri
		*	@desc - returns our current uri for the page
		*/
	uri()
	{
		return this.uriPath() + this.uriQuery();
	};

	uriPath()
	{
		return decodeURI(window.location.pathname);
	};
	
	uriQuery()
	{
		return window.location.search;
	};

	/**
		*	@method open
		*	@desc - figures out how we'll open the pane.	If direct, then we do a full-window animation from the current pane to the new one.
		*					otherwise we do a push animation onto the stack, unless we are staying at the same pane level
		*	@param {object} params - parameters for the uri we are loading as configured by this.setup()
		*/
	async processHandlerQueue()
	{
		for (const idx in this.#handlerQueue)
		{
			if (String(idx).isNumeric())
			{
				await this.processHandler(this.#handlerQueue[idx]);
			}
		}
		
		this.#handlerQueue = [];
	};

	async processHandler(params)
	{
		if (!params?.handler) return false;
		
		const animated = (params.transition == 'push') ? true : false;

		const handlerName = params.handler;
		const jsFile = `/assets/plugins/${params.plugin.name}/dist/${params.plugin.name}.js`;
		
		if (params.plugin?.css)
		{
			if ( Array.isArray( params.plugin.css ))
			{
				params.plugin.css.forEach( cssURL => {
					
					if (!this.#loadedCSS.has(cssURL))
					{
						WDRes.loadCSS(cssURL);
						this.#loadedCSS.add(cssURL);
					}					
				
				});
			}
			else
			{
				const cssFile = `/assets/plugins/${params.plugin.name}/dist/${params.plugin.name}.css`;

				if ( !this.#loadedCSS.has(cssFile) )
				{
					WDRes.loadCSS([cssFile]);
					this.#loadedCSS.add(cssFile);
				}
			}
		}

		//import the javascript file and load it	
		return import(jsFile).then( module => {
		
			this.#handy = new module[handlerName](params.data);
			
			if (!this.checkHandyPerms()) throw new Error('Permission Denied');

			this.#handy.appConfig({
				uri: params.uri,
				transition: params.transition,
				route: params.route,
			});
			
			//push the pane onto the stack at the current level
			//if (params.route_match || this.isAddHere(params.uri)) 
			if (this.currentPane()?.appConfig()?.route == params.route)
			{
				this.addHere(this.#handy,false);
			}
			else 
			{
				this.add(this.#handy,animated);
			}
				
			this.trigger('site:panes:active',{plugin:params.plugin.name});
		});
		
	};

	checkHandyPerms()
	{
		const perms = this.#handy.permissions();
		let ret = true;

		//permissions requirements are set for this pane		
		if (perms)
		{
			//if not logged in, then show the login screen
			if (!WDAuth.can('Login'))
			{
				WDAuth.modal(true);
				ret = false;
			}
			//they are logged in but don't have access to this permission
			else if (!WDAuth.can(perms))
			{
				new WDActivity().showError('You do not have permissions to access this pane');
				ret = false;
			}
			
		}

		return ret;
	};


	isAddHere(newURI)
	{
		if (!this.currentPane() || !this.currentPane().uri()) return false;

		const curURI = this.currentPane().uri();

		return (newURI.indexOf(curURI) != -1 && newURI.length > curURI.length) ? false : true;
	};

}

customElements.define('wd-site-controller',WDSiteController);
