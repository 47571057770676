
import { WDListItem } from './wd-list-item'
import WDFormProxy from '../form/wd-form-proxy'

export default class WDListFormItem extends WDListItem {

	#form = null;
		
	constructor()
	{
		super().classList.add('wd-list-form-item');

		//remove the standard click event
		this.off('click');

		this.#form = new WDFormProxy();
		
		return this;
	};

	setProxy(proxy)
	{
		this.#form.setProxy(proxy);
	};

	add(formCfg)
	{
		//just passed a regular element, add that instead
		//create a cell that we can add our form to
		const cell = super.add();

		return new Promise( (resolve,reject) => {
		
			if (formCfg instanceof HTMLElement) 
			{
				cell.append(formCfg);
				resolve(cell);
			}
			else
			{
				cell.classList.add(formCfg.name);
				delete(formCfg.label);
				
				this.#form.addForm(formCfg).then( formObj => {

					cell.append(formObj);
					resolve(formObj);

				});
			}
			
		});
				
	};

	getForm(formName)
	{
		return this.#form.get(formName);
	};

	serialize()
	{
		return this.#form.serialize();
	};
	
	populate(val)
	{
		return this.#form.populate(val);
	};

	form() { return this.#form; };
	proxy() { return this.#form.proxy(); };
  req() { return this.#form.proxy().req(); };
  post() { return this.#form.proxy().post(); };
  del() { return this.#form.proxy().del(); };

};

customElements.define('wd-list-form-item',WDListFormItem);
