
import { WDFormInput } from './wd-form-input';
//
export class WDFormRange extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'range';
    super(cfg);

    this.input().classList.remove('form-control');
    this.input().classList.add('form-range');
    
    this.updateVal();
    this.on('input',() => this.updateVal() );
  };

  updateVal()
  {
    let val = this.val();

    if (this.config().display) val = this.config().display(val);
    this.suffix().innerText = val;
  };

};

customElements.define('wd-form-range',WDFormRange);

