
import WDElement from '../wd-element';
import { WDFormBasic } from './wd-form-basic';
import WDUtil from '../../core/wd-util';
import { WDProxyArray } from '../../core/wd-proxy';
import WDActivity from '../modal/wd-activity';
import WDButton from '../button/wd-button';

export class WDFormTextlist extends WDFormBasic {

  #textList;
  #modeSelect;
  
  constructor(cfg)
  {
    const tl = new WDTextlist(cfg);

    cfg.type = 'textlist';
    super(tl,cfg).classList.add('wd-form-textlist');
   
    this.#textList = tl;
    this.#textList.on('change',() => { this.trigger('change'); } );

    //make sure we have at least one entry
    this.addValue('');

		//disable the button if readonly
		if (this.config().readonly) this.readonly(this.config().readonly);
	};

	addValue(val) 
	{ 
	  this.#textList.addValue(val); 
  };

	getValueLabel()
	{
	  return this.#textList.getValueLabel();
  };

	val(val,evt) 
	{ 
	  return this.#textList.val(val,evt);
  };
  
  readonly(val)
  {
    this.#textList.readonly(val);
  };

};

customElements.define('wd-form-textlist',WDFormTextlist);

class WDTextlist extends WDElement {

  #items = [];
  #values = [];
    
  /**
    * default config
    */
	#config = { 
	  separator:', ' 
  };
    
  constructor(cfg)
  {
    super().classList.add('wd-textlist');

    if (cfg !== undefined) WDUtil.merge(this.#config,cfg);

    this.#values = new WDProxyArray([]);

    this.#values.on('set', evtData => this.handleSet(evtData) );
    this.#values.on('delete', evtData => this.handleDelete(evtData) );

    //				
		if ( this.#config.value !== undefined ) this.val(this.#config.value);
	};

  items() { return this.#items; };

	removeItemAt(idx)
	{
		this.#items[idx].remove();
		this.#items.splice(idx,1);
	};

  replaceItemAt(item,idx)
  {     	
    if (idx < this.#items.length)
    {   	
      const r = this.#items[idx];
        	
      item.insertBefore(r);
      r.remove();

      this.#items[idx] = item;
    }   	
    else	
    {   	
      this.append(item);
      this.#items.push(item);
    }   	
  		
    return item;
  };    	


  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const data = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if data is undefined, the item is being removed
    if ( data === undefined )
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else if ( this.#items[idx] !== undefined )
    {
      this.#items[idx].val(data);
    }
    else
    {
      //create the new item
			const item = new WDTextlistItem(this,idx);
			this.replaceItemAt(item,idx);
			
			if (data) item.val(data);

			item.on('item:delete',() => this.deleteValueAtIndex(idx) );
			item.on('item:add',() => this.addValueAtIndex(idx) );
			item.on('item:update',() => this.updateValueAtIndex(idx,item.val()) );

			item.on('item:update item:delete',() => this.trigger('change') );
    }
  };  

  deleteValueAtIndex(idx)
  {
    if (this.#values.length == 1)
    {
      new WDActivity().showError('You cannot delete the last item');
    }
    else
    {
      this.#values.splice(idx,1);
    }
  };

  addValueAtIndex(idx)
  {
    this.#values.insert(idx+1,'');
  };
  
  updateValueAtIndex(idx,val)
  {
    this.#values[idx] = val;
  };

  addValue(val)
  {
    this.#values.push(val);
  };

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    this.removeItemAt(idx);
  };

	val(val,evt)
	{
	  if ( val === undefined )
	  {
	    return this.#values.toArray().filter( v => String(v).trim().length > 0 );
	  }
	  else
	  {
	    if ( !Array.isArray(val) ) val = [val];
	   
	    this.#values.replace(val);
	    this.trigger('value:set');
	    
	    if (evt) this.trigger('change');
	    return this;
    }
	};
    
  /**
    * generates a label based on all the selected values in the list
    */
  getValueLabel()
  {
    return this.#values.join( this.#config.separator );
  };
  
};

customElements.define('wd-textlist',WDTextlist);

class WDTextlistItem extends WDElement {

  #textbox;
  #delBtn;
  #addBtn;
  
  constructor()
  {
    super().classList.add('wd-textlist-item');

    this.#textbox = wdc('<input type="text" class="form-control form-control-sm"/>');
    this.#textbox.addEventListener('change',() => this.trigger('item:update') );
  
    this.#delBtn = new WDButton({fasIcon:'trash', size:'sm'});
    this.#delBtn.on('click',() => this.trigger('item:delete') );

    this.#addBtn = new WDButton({fasIcon:'plus', size:'sm'});
    this.#addBtn.on('click',() => this.trigger('item:add') );      

    this.append(this.#textbox);
    this.append(this.#delBtn);
    this.append(this.#addBtn);
  };
  

  val(val)
  {
    if ( val === undefined )
    {
      return this.#textbox.value;
    }
    else
    {
      this.#textbox.value = val;
      return this;
    }
  };

};

customElements.define('wd-textlist-item',WDTextlistItem);
