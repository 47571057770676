
import WDNavbarMenu from './wd-navbar-menu';

export default class WDNavbarMenuPager extends WDNavbarMenu
{
	#page = 0;
	#count = 0;
	#max = -1;
	#pages = [];
	#previous = null;
	#next = null;
	#first = null;
	#last = null;
	#start = null;
	#end = null;

	constructor(cfg) {
	
		super(cfg).classList.add('wd-navbar-pager');
		return this;		
	};
	
	//opts {total,limit,max#pages}
	load(opts) 
	{
		//this.empty();
		this.#pages = [];

		//figure out how many pages we have
		this.#count = parseFloat(opts.count) / parseFloat(opts.limit);
    if (parseInt(this.#count) != this.#count) this.#count = parseInt(this.#count) + 1;

		if (opts.max_pages) this.#max = opts.max_pages;

		this.draw();
	};

	draw()
	{
		this.empty();
		this.setupEndpoints();

		this.#first = this.add().fasIcon('fast-backward');
		this.#first.on('click',(evt) => this.firstPage(evt) );
		this.#first.disabled = true;

		this.#previous = this.add().fasIcon('backward');
		this.#previous.on('click',(evt) => this.previousPage(evt) );
		this.#previous.disabled = true;

		for (let i=this.#start; i<this.#end; i++)
		{
			const btn = this.add( String( parseInt(i) + 1 ) );
			btn.setAttribute('data-page',i);
			btn.on('click',() => this.select(i) );
			this.#pages.push(btn);
		}

		this.#next = this.add().fasIcon('forward');
		this.#next.on('click',(evt) => this.nextPage(evt) );
		this.#next.disabled = true;
		
		this.#last = this.add().fasIcon('fast-forward');
		this.#last.on('click',(evt) => this.lastPage(evt) );
		this.#last.disabled = true;
		
		//hide first/last if not needed
		if (this.#max == -1)
		{
			this.#first.hide();
			this.#last.hide();
		}

		if (this.#pages.length > 0) this.updateActive();

	};

	setupEndpoints()
	{
		//no limit on how many pages we can show
		if (this.#max==-1 || this.#max >= this.#count)
		{
			this.#start = 0;
			this.#end = this.#count;
		}
		//we have a limit on how manage pages we can show
		else
		{
			//407 399 408
			if (this.#page > 0 && this.#page >= this.#end && this.#page < this.#count)
			{
				this.#start = this.#page - this.#max + 1;
				this.#end = this.#page + 1;
			}
			else if (this.#page <= this.#start && this.#page >= 0) 
			{
				this.#start = this.#page;
				this.#end = this.#page + this.#max - 1;
			}
			else if (!this.#start || !this.#end)
			{
				this.#start = 0;
				this.#end = Math.min(this.#max,this.#count);
			}
		}
	};

	page(idx)
	{
		if (typeof(idx) == 'undefined') return this.#page;
		else
		{
			this.#page = 0;
			this.updateActive();
		}
	};

	select(idx) 
	{
		this.#page = idx;

		//if switching to a page outside our range, redraw
		if (this.#page >= this.#end || this.#page <= this.#start) this.draw();

		//otherwise just change the page
		else this.updateActive();

		this.trigger('page:selected',{page:idx});
	};

	updateActive()
	{
		//not initialized yet
		if (!this.#next || !this.#previous || !this.#first || !this.#last) return;
		
		//update the selected page
		const activePage = this.querySelector('.current-page');
		if (activePage) activePage.classList.remove('current-page');
		
		this.querySelector(`[data-page="${this.#page}"]`).classList.add('current-page');

		//disable buttons we don't need based on current page
		if (this.#page==0) 
		{
			this.#previous.disabled = true;
			this.#first.disabled = true;
		}
		else
		{
			this.#previous.disabled = false;
			this.#first.disabled = false;
		}

		if (this.#page==this.#count-1) 
		{
			this.#next.disabled = true;
			this.#last.disabled = true;
		}
		else
		{
			this.#next.disabled = false;
			this.#last.disabled = false;
		}

	};

	nextPage(evt)
	{
		evt.preventDefault();

		if (this.#page == this.#count-1) return;
		this.select(this.#page + 1);
	};

	previousPage(evt)
	{
		evt.preventDefault();

		if (this.#page == '0') return;
		this.select(this.#page - 1);
	};

	firstPage(evt)
	{
		evt.preventDefault();
		
		if (this.#page == '0') return;
		this.select('0');
	};

	lastPage(evt)
	{
		evt.preventDefault();

		if (this.#page == this.#count-1) return;
		this.select(this.#count-1);
	};		

};

customElements.define('wd-navbar-menu-pager',WDNavbarMenuPager);
