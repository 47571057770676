
import WDElement from '../wd-element'
import { WDProxyArray } from '../../core/wd-proxy'
import WDUtil from '../../core/wd-util'

export default class WDChecklist extends WDElement {

  #options = [];
  #items = [];
  #values = [];
  #valueLabels = [];
  
  /**
    * default config
    */
	#config = { 
	  options: [], 
	  multiple: false,
	  allow_clear: true,
	  separator: ', ',
	  templates: {
	  	label: '${label}',
	  	value: '${value}'
		},
	  icons: {
	    unchecked: 'fa-solid',
	    checked: 'fa-solid fa-check',
	    //unchecked: 'fa-regular fa-square',
	    //checked: 'fa-regular fa-square-check'
    }
  };
    
  constructor(cfg)
  {
    super('<ul/>').classList.add('wd-checklist');

    if (cfg !== undefined) WDUtil.merge(this.#config,cfg);

    //convert legacy map config setting to templates
    if (cfg?.map?.label !== undefined) this.#config.templates.label = '${' + cfg.map.label + '}';
    if (cfg?.map?.value !== undefined) this.#config.templates.value = '${' + cfg.map.value + '}';

    //setup our base proxy for our option storage
    this.#options = new WDProxyArray([]);
    this.#values = new WDProxyArray([]);

    //setup an event watcher for proxy set if we have a content handler
    this.#options.on('set', evtData => this.handleSet(evtData) );
    this.#options.on('delete',evtData => this.handleDelete(evtData) );

    this.#values.on('set', evtData => this.handleValueSet(evtData) );
    this.#values.on('delete', evtData => this.handleValueDelete(evtData) );

    //passed some options, add to our proxy array
    if ( this.#config.options.length > 0)
    {
    	this.remap(this.#config.options);
    	this.#options.replace(this.#config.options);
		}

		//if passed a fetch
		if (this.#config.fetch) this.fetchOptions();
		else setTimeout( () => this.trigger('options:set'), 1);
		
    //				
		if ( this.#config.value !== undefined ) this.val(this.#config.value);
	};

	options() { return this.#options; };	
  items() { return this.#items; };
  values() { return this.#values; };
  
	fetch(f) 
	{ 
	  if ( f === undefined) return this.#config?.fetch; 
	  else
	  {
	    this.#config.fetch = f;
	    return this;
    }
  };
  	
	fetchOptions()
	{
	  //make sure we have a request uri set
	  if ( this.#config.fetch.getURI().includes('${') )
	  {
	    return new Promise( (resolve,reject) => {

				this.setOptions([]);
        resolve(true);
	    });

	  }
	  else
	  {
  		return this.#config.fetch.req().then( results => {
        if (results instanceof Array) this.setOptions(results);
      });
    }
  };

  remap(options)
  {
  	options.forEach( item => {
  	
  		if (item?.value === undefined)
  		{
				const stringVal = this.#config.templates.value.interpolate(item);
		 		const numVal = parseFloat(stringVal);
		    item.value = !isNaN(numVal) && isFinite(stringVal) ? numVal : stringVal;
			}
			
			if (item?.label === undefined)
			{
				item.label = this.#config.templates.label.interpolate(item);
			}
		});
	};

  setOptions(newOptions)
  {
  	this.remap(newOptions);
    this.#options.replace(newOptions);
    this.trigger('options:set');
  };

	removeItemAt(idx)
	{
		this.#items[idx].remove();
		this.#items.splice(idx,1);
	};

  replaceItemAt(item,idx)
  {     	
    if (idx < this.#items.length)
    {   	
      const r = this.#items[idx];
        	
      item.insertBefore(r);
      r.remove();

      this.#items[idx] = item;
    }   	
    else	
    {   	
      this.append(item);
      this.#items.push(item);
    }   	
  		
    return item;
  };    	

  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const data = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if data is undefined, the item is being removed
    if ( data === undefined )
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else
    {
      //create the new item
			const item = new WDChecklistItem(data,{
				icons: this.#config.icons, 
			});

			this.replaceItemAt(item,idx);

			//check it if set
			if (this.#values.indexOf(data.value) != -1) 
			{
			  item.checked(true);
      }
      
			//watch for changes
			item.on('click',(evt) => {
			  this.handleItemClick(item,evt);
			});
    }
  };  

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    this.removeItemAt(idx);
  };


	handleValueSet(evtData)
	{
	  if (evtData.property == 'length') return;

	  this.checkValueRows();
    this.trigger('value:set');
  };

  handleValueDelete(evtData)
  {
    this.checkValueRows();
    this.trigger('value:set');
  };

  checkValueRows()
  {
	  const values = this.#values.toArray();

    this.#items.forEach( item => {
    
      let isSet = false;
      
      values.some( v => {
        if (v == item.value())
        {
          isSet = true;
          return true;
        }
      });

      item.checked( isSet );
    });
  };

  handleItemClick(item,evt)
  {
    const itemVal = item.value();

    if (this.#config.multiple == true)
    {
      const idx = this.#values.indexOf(itemVal);

      if (idx == -1) this.#values.push(itemVal);
      else this.#values.splice(idx,1);
    }
    else
    {
      let newVal = [];
      
      //if allow clear is set, allow clearing the value or updating
      if (this.#config.allow_clear == true)
      {
        const idx = this.#values.indexOf(itemVal);
        newVal = (idx == -1) ? [itemVal] : [];
      }
      else
      {
        newVal = [itemVal];
      }

      this.#values.replace(newVal);
    }

    if (evt)
    {
      this.trigger('change');
      this.trigger('input');				//not sure we need this one
    }
    
  };

	val(val)
	{
	  if ( val === undefined )
	  {
      return this.getVal();	  
	  }
	  else
	  {
	    this.setVal(val);
	    return this;
    }
	};
    
	getVal()
	{
	  if (this.#config.multiple == true)
    {
      return this.#values;
    }
    else
    {
      return (this.#values.length > 0) ? this.#values[0] : null;
    }
	};

	setVal(val,triggerChange)
	{
	  if (val === null) val = [];
	  else if ( !(val instanceof Array) ) val = [val];

    this.#values.replace(val);
    this.trigger('value:set');
	};

	setValuesFromLabels(lbls)
	{
		const vals = [];
	
		lbls.forEach( lbl => {
			const opt = this.#options.find( x => x.label == lbl);

			if (opt) vals.push(opt.value);
		});

		if (vals.length > 0) this.setVal(vals);
	};
	
	setValFromLabels(lbls)
	{
		if (!Array.isArray(lbls)) lbls = [lbls];

		return new Promise( (resolve,reject) => {
		
			if (this.#options.length > 0)
			{
				this.setValuesFromLabels(lbls);
				resolve(true);
			}
			else 
			{
				this.one('options:set',() => {
					this.setValuesFromLabels(lbls);
					resolve(true);
				});
			}
		
		});

	};

	/**
		* generates a label based on all the selected values in the list
		*/
	getValueLabel()
	{
		const labels = [];

		this.#values.forEach( v => {
		
			this.#options.some( opt => {

				if ( String(opt.value) == String(v)) 
				{
					labels.push(opt.label);
					return true;
				}
			});
		
		});

		//if our labels are an object, then we used a dom object as a label.	Just add them to a container
		if (labels.length > 0)
		{
			if (typeof(labels[0]) == 'object')
			{
				const container = wdc('<div/>');
				labels.forEach( label => container.append( label.clone() ) );
				return container;
			}
			else
			{
				return labels.join( this.#config.separator );
			}
		}
		else
		{
			return null;
		}
	};
	
};

customElements.define('wd-checklist',WDChecklist);

class WDChecklistItem extends WDElement {

	#icon;
	#data;
	#icons;
	#checked = false;
	
	constructor(data,cfg)
	{
		super('<li/>').classList.add('wd-checklist-item');

		this.#icon = wdc('<span/>');
		
		
		if (cfg?.icons?.unchecked) 
		{
			const icons = Array.isArray(cfg.icons.unchecked) ? cfg.icons.unchecked : cfg.icons.unchecked.split(' ');
			this.#icon.classList.add(...icons);
		}

		this.#data = data;
		this.#icons = cfg?.icons;

		this.append( this.#icon );
		this.append( this.label() );
	};

	value() { return this.#data?.value; };
	label() { return this.#data?.label; };

	checked(val)
	{
		if (val === undefined)
		{
			return this.#checked;
		}
		else
		{
			this.#checked = val;
			const icons = this.#icons;
			
			if (val === true) 
			{
				this.#icon.classList.remove( ...icons.unchecked.split(' ') );
				this.#icon.classList.add( ...icons.checked.split(' ') );
			}
			else 
			{
				this.#icon.classList.remove( ...icons.checked.split(' ') );
				this.#icon.classList.add( ...icons.unchecked.split(' ') );
			}
			
			return this;
		}	
	};

};

customElements.define('wd-checklist-item',WDChecklistItem);
