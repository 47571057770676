
import WDElement from '../wd-element'

export class WDFormBasic extends WDElement {

	/** convenience methods **/
	#config = {};
	#label = null;
	#input = null;
	#prefix = null;
	#suffix = null;
	#inputGroup = null;

	constructor(input,config)
	{
		super();
		if (config) Object.assign(this.#config,config);

		this.#input = input;
		this.append(input);
						
		/** now setup the attributes and properties we can handle at init time **/
		this.addAttributes();
		this.addProperties();

		/** add some passed event handlers if in the config **/
		for (let key in this.#config) 
		{
			if (key.indexOf('on') == '0') 
			{
				let evt = key.replace('on','');
				this.#input.addEventListener(evt,this.#config[key]);
			}
		}

    //add validation handler if passed
    if (this.#config.required) this.feedback();

		return this;
	};
		
	//
	config(val)
	{
		if (typeof(val) == 'undefined') return this.#config;
		else
		{
			this.#config = val;
			return this;
		}
	};

	input() { return this.#input; };
	name() { return this.config().name; };
	type() { return this.config().name; };
	form() { return this.closest('.wd-form'); };
	
	label()
	{
		if (!this.#label)
		{
			this.#label = wdc(`<label class="form-label" for="${this.name()}"/>`);
			this.prepend(this.#label);
		}				

		return this.#label;
	};

	prefix() 
	{
		if (!this.#prefix)
		{
			this.#prefix = wdc('<span class="input-group-text wd-input-group-prefix"/>');
			this.inputGroup().prepend(this.#prefix);
		}				
		return this.#prefix;
	};

	suffix() 
	{
		if (!this.#suffix)
		{	
			this.#suffix = wdc('<span class="input-group-text wd-input-group-suffix"/>');
			this.inputGroup().append(this.#suffix);
		}
		
		return this.#suffix;

	};

	inputGroup()
	{
		if (!this.#inputGroup)
		{
			//create a group and put our form in it
			this.#inputGroup = wdc('<div class="input-group"/>');
			this.#inputGroup.append( this.#input );
			
			if (this.#label)
			{
				this.#label.after(this.#inputGroup);
			}
			else
			{
				this.prepend(this.#inputGroup);
			}
		}
	
		return this.#inputGroup;
	};

	attr(key,val)
	{
		if (val === undefined)
		{
			return this.input().getAttribute(key);
		}
		else
		{
			this.input().setAttribute(key,val);
			return this;
		}
	};	

	prop(key,val)
	{
		if (val === undefined)
		{
			return this.input()[key];
		}
		else
		{
			this.input()[key] = val;
			return this;
		}
	};	

	addProperties()
	{
		let keys = ['disabled','checked','required'];

		keys.forEach( key => {
			if (this.#config[key] !== undefined) this.prop(key,this.#config[key]);
		});
		
	};

	addAttributes()
	{
		let keys = ['id','name','readonly','placeholder','value','maxlength','autocomplete','autocorrect','autocapitalize','spellcheck','min','max','step','list'];

		keys.forEach( key => {
			if (this.#config[key]) this.attr(key,this.#config[key]);
		});
	
	};

	readonly(val) { return this.attr('readonly',val); };

  /**  
    adds feedback messages to the form if passed
    */
  feedback()
  { 
    this.inputGroup();

    if (this.#config.valid_msg)   
    {
      let vf = wdc('<div class="valid-feedback"/>').text(this.#config.valid_msg);
      this.inputGroup().append(vf);
    }

    if (this.#config.invalid_msg)
    {
      let ivf = wdc('<div class="invalid-feedback"/>').text(this.#config.invalid_msg);
      this.inputGroup().append(ivf);
    }
  };

	reset() { this.val(''); };

	val(data,triggerChange)
	{
		if (typeof(data) == 'undefined')
		{
			return this.input().value;
		}
		else
		{
			this.input().value = data;
			this.trigger('value:set');

			if (triggerChange) this.trigger('change');

			return this;
		}
	};

  markCleanup()
  {
    this.classList.remove('is-valid','is-invalid','has-feedback');
    if (this.#suffix) 
    {
      this.#suffix.remove()
      this.#suffix = null;
    }
  };

  markError()
  {
    const icon = wdc('<span/>').classList.add('fa-solid','fa-triangle-exclamation');

    this.markCleanup();
    this.classList.add('is-invalid');

    this.suffix().classList.add('form-control-feedback');
    this.suffix().append(icon);
  };
  
  markSuccess()
  {
    const icon = wdc('<span/>').classList.add('fa-solid','fa-check');

    this.markCleanup();
    this.classList.add('is-valid');

    this.suffix().classList.add('form-control-feedback');
    this.suffix().append(icon);
  };

};

customElements.define('wd-form-basic',WDFormBasic);
