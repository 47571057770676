
import WDPaneList from './wd-pane-list';

export default class WDPaneListCursor extends WDPaneList {

  #cursorbar;

	constructor(cfg)
	{
		super(cfg).classList.add('has-pager has-cursor');
	};

	runFetch()
	{
	  return super.runFetch().then( () => {
	    this.showCursor();
	  });
	
	};


  cursorbar()
  {	
    if (!this.#cursorbar)
    {   
      this.#cursorbar = new WDNavbar();
      this.footer().append(this.#cursorbar);

      const center = this.#cursorbar.center();
      center.add('Prev').icon('fa-solid fa-chevron-left').on('click',() => this.cursorPrev() );
      center.add('Next').suffix('fa-solid fa-chevron-right').on('click',() => this.cursorNext() );
    }   

    return this.#cursorbar;
  };	

  resultPage()
  {	
     return (this.results().pagination) ? this.results().pagination[0] : {has_prev_page:false, has_next_page:false};
  };	

  showCursor()
  {   

    const prevBtn = this.cursorbar().q('.navbar-center .nav-item:first-child button');
    const nextBtn = this.cursorbar().q('.navbar-center .nav-item:last-child button');

    //use passed pagination info if we have any
    const page = this.resultPage();

    const prevDisabled = (page.has_prev_page == '1') ? false: true;
    const nextDisabled = (page.has_next_page == '1') ? false : true;

    prevBtn.disabled = prevDisabled;
    nextBtn.disabled = nextDisabled;
  };	

  cursorPrev()
  {   
    delete(this.fetch().cursor_next);
    
    this.fetch().cursor_prev = this.resultPage().cursor_prev;
    this.runFetch();
  };    
      
  cursorNext()
  {   
    delete(this.fetch().cursor_prev);
    
    this.fetch().cursor_next = this.resultPage().cursor_next;
    this.runFetch();
  };    

};

customElements.define('wd-pane-list-cursor',WDPaneListCursor);
