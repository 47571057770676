
import { WDFormDropdown } from './wd-form-dropdown';
import WDDropdownMultiForm from '../dropdown/wd-dropdown-multi-form';

export class WDFormDropdownmulti extends WDFormDropdown {

	constructor(opts)
	{
	  opts.dropdown_type = 'multi';
	  super(opts);
	};

  /**
    */
  validate()
  {
    let ret = true;

    //if set to not allow nulls   
    if (this.config().required == true && this.val().length == 0)
    {
      ret = false;
      this.markError();
    }
    else 
    {
      ret = true;
      this.markSuccess();
    }
    return ret;
  };

};

customElements.define('wd-form-dropdown-multi',WDFormDropdownmulti);
