/**
  WDRes - for loading resources from the client-side api
  */

export default class WDRes {

  #uri = null;
      
  constructor(uri)
  {
    this.#uri = uri;
  };

  static getJSON(uri)
  {
		//run the fetch
		const ftch = fetch(uri).then( response => {

			if (response.ok)
			{
				return response.json();
			}
			else
			{
				throw new WDError(response.message, {code:response.status, name:'Error'});
			}
		})

	  return ftch; //this.request('application/json');
  };

  static isInternalURL(url)
  {
  	let ret = false;

  	if (url.length > 0)
  	{
	  	const firstChar = url.substr(0,1);
	  	const secChar = (url.length > 1) ? url.substr(1,1) : null;

			//just verifies we have a '/x' setup where x isn't a second slash	  	
	  	if (firstChar == '/' && secChar != '/') ret = true;
		}
		
		return ret;
  };

  static loadJS(files)
  {
  	const jsFiles = [...files];

  	return new Promise( (resolve,reject) => {
  		
  		const loadNextFile = () => {
  		
  			//resolve the promise if nothing left to do
	  		if (jsFiles.length == 0) 
	  		{
	  			resolve(true);
	  			return true;
				}
				
				//get the next file to load from the beginning of the array				
  			const jsFile = jsFiles.shift();
  			
  			//load the file
  			WDRes.loadJSFile(jsFile).then( () => loadNextFile() );
  		};

  		loadNextFile();

		});
		
  };

  static loadJSFile(jsFile)
  {
    return new Promise( (resolve,reject) => {

			//add the version so we can cache-bust    
			//const jsURL = (WDRes.isInternalURL(jsFile)) ? jsFile + '?=' + WDConfig.version : jsFile;

			//
			const jsScript = document.createElement('script');
			document.querySelector('head').appendChild(jsScript);
			jsScript.setAttribute('src',jsFile);
	
			jsScript.onload = () => {
				resolve(true);
			};
			
    });
  
  };

  static loadCSS(cssFiles,addVersion)
  {
  	const urls = ( Array.isArray(cssFiles) ) ? cssFiles : [cssFiles];
  	urls.forEach( url => {

    	//add the version so we can cache bust
    	//const cssURL = ( WDRes.isInternalURL(url) ) ? (url + '?=' + WDConfig.version) : url;

    	//build the link
    	const link = wdc(`<link rel="stylesheet" type="text/css" href="${url}"/>`);
    	document.head.append(link);

		});
  
  };
};

