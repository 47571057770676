
import { WDFormText } from './wd-form-text';

export class WDFormTextpicker extends WDFormText {

  constructor(opts)
  {
  	super(opts);
  	
  	if (!opts.readonly && opts.handler)
  	{
	  	const editBtn = new WDButton().fasIcon('edit').size('sm');
			editBtn.on('click',() => opts.handler() );

			this.suffix().append(editBtn);
		}
	};
	  
};

customElements.define('wd-form-textpicker',WDFormTextpicker);
