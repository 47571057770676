
import { WDFormBasic } from './wd-form-basic';

export class WDFormInput extends WDFormBasic {

  constructor(cfg)
  {
    if (!cfg.type) cfg.type = 'text';

    let elem = wdc(`<input type="${cfg.type}" class="form-control"/>`);
    
  	//sane defaults
  	if (cfg.autodisabled) 
  	{
		  cfg.autocomplete = 'off';
		  cfg.autocorrect = 'off';
		  cfg.autocapitalize = 'off';
		  cfg.spellcheck = 'off';
    }

    super(elem,cfg);

		if (cfg?.value !== undefined) this.val(cfg.value);
	};

};

customElements.define('wd-form-input',WDFormInput);
