
import { WDFormBasic } from './wd-form-basic';

export class WDFormDaterange extends WDFormBasic {

	#beginCfg = null;
	#endCfg = null;
	#beginForm = null;
	#endForm = null;

  constructor(cfg) {

    super('<div/>',cfg).classList.add('d-flex','flex-row');

  	this.#beginCfg = Object.assign({},cfg);
  	this.#endCfg = Object.assign({},cfg);

		this.#beginCfg.name += '[begin]';
		this.#endCfg.name += '[end]';

		//let's see about this
		if (this.#beginCfg.value_begin) this.#beginCfg.value = this.#beginCfg.value_begin;
		if (this.#endCfg.value_end) this.#endCfg.value = this.#endCfg.value_end;

		//build the forms	
		this.#beginCfg.type = 'date';
		this.#endCfg.type = 'date';
		
		this.#beginForm = new WDFormDate(this.#beginCfg);
		this.#endForm = new WDFormDate(this.#endCfg);

		//
		this.append(this.#beginForm);
		this.append(this.#endForm);
	};

	/**
		*/
	val(valobj)
	{
		if (typeof(valobj) != 'undefined')
		{
			if (valobj.begin) this.#beginForm.val(valobj.begin);
			if (valobj.end) this.#endForm.val(valobj.end);
		}
		else
		{
		  let beginVal = this.#beginForm.val();
		  let endVal = this.#endForm.val();
      let ret = null;
      		  
		  if (beginVal || endVal)
		  {
		    ret = {};
		    if (beginVal) ret.begin = beginVal;
		    if (endVal) ret.end = endVal;
      }

      return ret;
		}
	};

};

customElements.define('wd-form-daterange',WDFormDaterange);

