
import { WDFormBasic } from './wd-form-basic';
import WDDropdownForm from '../dropdown/wd-dropdown-form';
import WDDropdownMultiForm from '../dropdown/wd-dropdown-multi-form';
import WDDropdownFormObject from '../dropdown/wd-dropdown-object-form';
import WDDropdownFormObjectMulti from '../dropdown/wd-dropdown-object-multi-form';

export class WDFormDropdown extends WDFormBasic {

	_dropdown = null;
				
	constructor(opts)
	{
	  let dd;

    //adapt to the dropdown type if passed	  
	  if (opts?.dropdown_type == 'multi') dd = new WDDropdownMultiForm(opts);
	  else if (opts?.dropdown_type == 'object') dd = new WDDropdownFormObject(opts);
	  else if (opts?.dropdown_type == 'multiobject') dd = new WDDropdownFormObjectMulti(opts);
	  else dd = new WDDropdownForm(opts);

		super(dd,opts);

		this._dropdown = dd;

    this._dropdown.on('change',(evt) => this.trigger('change',evt) );
    this._dropdown.on('input',(evt) => this.trigger('input',evt) );
    this._dropdown.on('value:set',(evt) => this.trigger('value:set',evt) );
    this._dropdown.on('options:set',(evt) => this.trigger('options:set',evt) );
    this._dropdown.on('options:updated',(evt) => this.trigger('options:updated',evt) );
	};

	options() { return this._dropdown.options(); };
	fetch() { return this._dropdown.fetch(); };
  setFetchURI(uri) { return this._dropdown.setFetchURI(uri); };
  fetchOptions() { return this._dropdown.fetchOptions(); };
  setOptions(newOptions) { return this._dropdown.setOptions(newOptions); };
  	
	val(val)
	{
		return this._dropdown.val(val);
	};

  setValFromLabels(lbls)
  {
    return this._dropdown.setValFromLabels(lbls);
  };

	readonly(val)
	{
		return this._dropdown.readonly(val);
	};

  /**
    */
  validate()
  {
    let ret = true;

    //if set to not allow nulls   
    if (this.config().required == true && this.val() == null)
    {
      ret = false;
      this.markError();
    }
    else 
    {
      ret = true;
      this.markSuccess();
    }
    return ret;
  };

};

customElements.define('wd-form-dropdown',WDFormDropdown);
