
import { WDFormButton } from './wd-form-button';

export class WDFormButtonmulti extends WDFormButton {

  #values = [];
  
	val(data)
	{
	  if (typeof(data) == 'undefined')
	  {
	    return this.#values;
    }
    else
    {
			this.#values = data;
			
			//setup our button text
			if (this.#values.length > 0)
			{
				//get our display separator
				const separator = ( typeof(this.config().separator) != 'undefined') ? this.config().separator : ',';

				let lbls = [];
				data.forEach( obj => {
					lbls.push( this.config().templates.label.interpolate(obj) );
				});

				this.button().title( lbls.join(separtor) );
			}
			else
			{
				this.button().title('Not Selected');
			}

			this.trigger('change');
		}

	};

};

customElements.define('wd-form-button-multi',WDFormButtonmulti);
