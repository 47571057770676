
import WDElement from '../wd-element';

export default class WDUploader extends WDElement {

	//setup our options
	#options = {		
		fetch: null,										//fetch object we use to upload to
		dnd: null,											//element to monitor for drag and drop file events
		container: document.body,					//container to display file upload message.	defaults to page body
		maxSize: null,									//max size of uploads allowed (bytes)
		multiple: true,									//allows multiple files to be selected for upload
		putFileName: true
	};
						
	#fetch = null;
	#disable = false;
	#input = null;
	#files = [];
	#uploaded = 0;
	#progressWrapper = null;
	#progressBar = null;
	#progressStatus = null;
	#progressText = null;

	constructor(opts)
	{
		super().classList.add('wd-uploader-container');

		Object.assign(this.#options,opts);
		this.#fetch = this.#options.fetch;

		this.#fetch.setProgressHandler( (evt) => this.handleProgress(evt) );
		
		if (this.#options.dnd) this.dndUpload();
	};

	fetch() { return this.#fetch; };
	files() { return this.#files; };
	disable(disable) { this.#disable = disable; };

	/**
		this creates a file input and "clicks" it so the file selector
		prompt is shown
		*/
	upload(formData)
	{
		if (formData) this.#options.formData = formData;
		
		//the actual input form
		this.#input = wdc('<input type="file" style="visibility:hidden;height:0px;float:left;"/>');
		if (this.#options.multiple) this.#input.multiple = true;

		//setup to import images from the camera if the browser supports it
		if (this.#options.camera) 
		{
			this.#input.setAttribute('accept','image/*');
			this.#input.setAttribute('capture','camera');
		}

		this.#options.container.append(this.#input);		
		this.#input.addEventListener('change',(evt) => this.processUploadEvent(evt) );
		this.#input.click();
	};

	dndUpload()
	{	
		this.#options.dnd.addEventListener('dragover',(evt) => this.dragOver(evt) );
		this.#options.dnd.addEventListener('dragleave',(evt) => this.dragOver(evt) );	
		this.#options.dnd.addEventListener('drop',(evt) => this.processDropEvent(evt) );
	};

	destroy()
	{
		if (this.#options.dnd)
		{
			this.#options.dnd.removeEventListener('dragover');
			this.#options.dnd.removeEventListener('dragleave');
			this.#options.dnd.removeEventListener('drop');
		}
	};

	dragOver(e)
	{
		if (this.#disable) return;
		
		e.stopPropagation();
		e.preventDefault();
	};

	reset()
	{
		this.#files = [];
		this.#uploaded = 0;
	};

	/**
		* event handlers - collection files and/or directories selected for upload
		*/
	processUploadEvent(evt)
	{
		this.layout();

		this.reset();
		this.#files = Object.values(this.#input.files);
		
		this.#files.forEach( file => this.sendFile(file) );
	};

	processDropEvent(evt)
	{
		if (this.#disable) return;

		evt.preventDefault();
		evt.stopPropagation();

		this.reset();
		this.layout();


		//passed a folder
		if (evt.dataTransfer.items)
		{
			Object.values( evt.dataTransfer.items ).forEach( rawItem => {
				let item = rawItem.webkitGetAsEntry();
				if (item) this.traverseTree(item,null);
			});
			
		}
		//passed files
		else if (evt.dataTransfer.files)
		{
			Object.values( evt.dataTransfer.files) .forEach( file => {
				this.#files.push(file);
				this.sendFile(file);
			});
		}	

	};

	traverseTree = function (item,path)
	{
		path = path || "";

		if (item.isFile) 
		{
			//send the file for uploading
			item.file( file => {
				this.#files.push(file);
				this.sendFile(file);
			});
		} 
		else if (item.isDirectory) 
		{
			// Get folder contents
			const dirReader = item.createReader();

			//create a new folder on our destination then get the files beneathe it
			this.#fetch.mkcol( item.fullPath ).then( () => {
				dirReader.readEntries( (entries) => {
					entries.forEach( (entry) => this.traverseTree(entry,path + item.name + "/") );
				});
			});

		}

	};

	/**
		* this uploads everything and the destination has to figure out the file name
		* from the content.  what happens with directory uploads?
		*/
	sendFile(file)
	{
		//push the file using the filename as the uri
		this.#fetch.file = file;

		const uri = (this.#options.putFileName === true) ? '/' + file.name : null;

		this.#fetch.put(uri).then( result => {
		
			this.#uploaded++;
			
			if (this.#uploaded == this.#files.length)
			{
				this.handleComplete(result);
			}
		}).
		catch( err => {
			this.handleError(err);
		});

	};

	/**
		*
		*/
	handleProgress(evt)
	{
		//loop through all the files and average out our progress.  Use that as the indicator value
		let totalLoaded = 0.0;
		let totalSize = 0.0;

		if (!evt.event.lengthComputable) return;

		this.#files.forEach( file => {
			totalLoaded += evt.event.loaded;
			totalSize += file.size;
		});

		const percentDone = parseFloat(totalLoaded) / parseFloat(totalSize);

		const tlFormatted = String(totalLoaded).formatSize();
		const tsFormatted = String(totalSize).formatSize();
		
		this.#progressText.innerText = `Uploaded ${tlFormatted} of ${tsFormatted}`;
		this.#progressStatus.style.width = Math.round(percentDone * 100) + "%";
	};
	
	handleComplete(result)
	{
		this.trigger('upload:complete',result);

		this.empty();
		this.remove();
		this.#options.container.style.position = '';
	};

	handleError(err)
	{
		this.trigger('upload:error',err?.response);

		if (err?.response?.message) new WDActivity().showError(err.response.message);

		this.empty();
		this.remove();
		this.#options.container.style.position = '';
	};

	layout()
	{
		this.#progressWrapper = wdc('<div class="wd-uploader-wrapper"/>');

		this.#progressBar = wdc('<div class="wd-uploader-bar"/>');
		this.#progressStatus = wdc('<div class="wd-uploader-status"/>');
		this.#progressBar.append(this.#progressStatus);

		this.#progressText = wdc('<div class="wd-uploader-text"/>');
		this.#progressText.innerText = "Uploading ";

		this.#progressWrapper.append(this.#progressBar);
		this.#progressWrapper.append(this.#progressText);
		this.append(this.#progressWrapper);

		this.#options.container.append(this);
		this.#options.container.style.position = 'relative';
	};

};

customElements.define('wd-uploader',WDUploader);
