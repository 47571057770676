
import { WDSiteNavbarMenu } from './wd-site-navigation';
import WDApi from '../core/fetch/wd-api.js';

export default class WDPluginMenu extends WDSiteNavbarMenu {

  #rendered = {};
  
	connectedCallback()
	{
    WDAuth.on('auth:authorized',() => this.buildMenu() );
    WDAuth.on('auth:logout',() => this.destroyMenu() );
  };
  
  buildMenu()
  {
    const menus = [];
    
    Object.values( WDController.pluginConfig() ).forEach( plugin => {
      if (plugin?.menu) menus.push(...plugin.menu);
    });

    menus.sort((a, b) => a.sort_order - b.sort_order);

    this.addMenus(this,menus);
  };

  destroyMenu()
  {
    this.replaceChildren();
    this.#rendered = {};
  };

  addMenus(menu,menuItems)
  {
    menuItems.forEach( menuItem => {
      this.addMenuItem(menu,menuItem)
    });
  };

  addMenuItem(menu,menuCfg)
  {
    if (menuCfg?.permissions)
    {
      //this pane has permissions set and we arent allowed to access - bail
      if (!WDAuth || !WDAuth.authorized() || (WDAuth.authorized() && !WDAuth.can(menuCfg.permissions)) ) return false;
    }

    if (menuCfg?.name && this.#rendered[menuCfg.name] !== undefined)
    {
      //top level menu with this name already exists, add as a submenu
      const parentItem = this.#rendered[menuCfg.name];
      const menuItem = this.createMenuItem(parentItem.submenu(),menuCfg);
    }
    else
    {
      const menuItem = this.createMenuItem(menu,menuCfg);
      this.#rendered[menuCfg.name] = menuItem;
    }
  };

  createMenuItem(menu,cfg)
  {
    const menuItem = menu.add();

    menuItem.label(cfg.label);
    menuItem.suffix().append( wdc('<span class="fa-solid"/>') );
    if (cfg.icon) menuItem.icon(cfg.icon);

    menuItem.setAttribute('data-uri',(cfg.value || 'notset'));
    menuItem.setAttribute('data-menu-name',cfg.name);

    //
    if (cfg.value || cfg.url) 
    {
      if (cfg.url) 
      {
        menuItem.on('click', () => location.href = cfg.url );
      }
      else 
      {
        menuItem.on('click', (evt) => { 
          evt.stopPropagation();
          WDController.root(cfg.value);
//          this.style.display = 'none';
        });
      }
    }

    //if there are items under this one, add as a submenu
    if (cfg.items && cfg.items.length > 0)
    {
      this.addMenus(menuItem.submenu(),cfg.items);
    }

    return menuItem;
  };  

};

customElements.define('wd-plugin-menu',WDPluginMenu);
