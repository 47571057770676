
import { WDFormBasic } from './wd-form-basic';

export class WDFormHtml extends WDFormBasic {

	#editor = null;
	#timeout = null;
	
	constructor(cfg)
	{
		let elem = wdc('<textarea class="form-control"/>');
		cfg.type = 'html';
		
		super(elem,cfg);

		if (this.config().value) this.val(this.config().value);

		this.on('editor:ready',() => {
      //we have to do this at a delay because it triggers too early
      setTimeout(() => this.#editor.on('change',() => this.handleChange() ) ,1000);
		});

		this.editor();
	};

	handleChange()
	{
		if (this.#timeout) clearTimeout(this.#timeout);
		this.#timeout = setTimeout( () => this.trigger('change'), 500);
	};

	val(val)
  {
    if (typeof(val) == 'undefined') 
    {
      //sync the textarea before we pull the value
    	if (this.#editor) this.#editor.updateElement();
      return super.val(val);
    }
    else
    {
      //update the textarea and the editor itself
      super.val(val);
      if (this.#editor) this.#editor.setData(val);
      
      this.trigger('value:set');
      return this;
    }
  };

	editor()
	{
	  if (!this.#editor)
	  {
	    const tb = (this.config().toolbar) ? this.config().toolbar : 'imagefetch';
	    const fetch = (this.config().fetch) ? this.config().fetch : null;
	    
	    const removePlugins = ['resize','flash'];
	    const addPlugins = ['autogrow'];

	    const editorCfg = {	toolbar: tb,
	    										allowedContent: true,
	    										extraPlugins: 'autogrow,imagefetch',
	    										removePlugins: 'resize,flash',										
	    										autoGrow_minHeight : 250,
	    										autoGrow_onStartup : true,
	    										enterMode : CKEDITOR.ENTER_BR,
	    										fullPage : true,
												};
	    										
			//merge in passed config options
			if (this.config().editor_config) Object.assign(editorCfg, this.config().editor_config);

  	  this.#editor = CKEDITOR.replace(this.element(), editorCfg);
			this.#editor.on('instanceReady',() => this.trigger('editor:ready') );

			//cant pass classes to the editor config, or they get serialized out of existence.  
			//so we set the fetch object directly
			this.#editor.imageFetch = (this.config().imageFetch) ? this.config().imageFetch : null;
			this.#editor.uploadFetch = (this.config().uploadFetch) ? this.config().uploadFetch : null;
    }
    
    return this.#editor;
	};

	
	fixHeight()
	{
		this.style.marginBottom = '0px';

		const formHeight = this.form().clientHeight;
		let usedHeight = 0;

		this.form().queryAll('.wd-form-wrapper').forEach( wrapper => {
			const w = wdc(wrapper);
			if (!w.classList.contains('wd-form-content')) usedHeight += w.clientHeight;
		});

		this.#editor.resize('100%',(formHeight - usedHeight - 30) );
	};
	
};

customElements.define('wd-form-html',WDFormHtml);
