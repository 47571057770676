
import WDForm from '../gui/form/wd-form';
import WDButton from '../gui/button/wd-button';

export default class WDUserPass extends WDForm {

  #enterBtn = null;
  #body = null;
  #buttons = null;
  
  constructor(opts)
  {
    super({wrapper_class:'col-12'}).classList.add('wd-userpass-form');

    this.addForms([ 
      {name:'login', type:'text', autocapitalize:'none', autocomplete:'username', placeholder:'Login', onkeyup:() => this.handleKeyUp() },
      {name:'password', type:'password', autocomplete:'current-password', placeholder:'Password', onkeyup:() => this.handleKeyUp() }
    ]);

    this.#body = wdc('<div class="wd-login-body"/>');
    this.append(this.#body);

    this.#buttons = wdc('<div class="wd-login-buttons"/>');
    this.append(this.#buttons);
    
    let btnCol = 'col-12';
    
    if (WDConfig.passwordReset.enable == true)
    {
      btnCol = 'col-6';
      
      let resetBtn = new WDButton('Forgot Your Password?');
      resetBtn.on('click',() => this.resetPassword() );
      resetBtn.classList.add('reset-password');
      this.#buttons.append(resetBtn);
    }

    this.#enterBtn = new WDButton({label:'Login',fasIcon:'sign-in-alt',theme:'success'});
    this.#enterBtn.classList.add('wd-userpass-submit');
    this.#enterBtn.on('click',(evt) => this.trigger('form:submitted') );
    this.#buttons.append(this.#enterBtn);
    
    return this;
  };

  //accessor
  body() { return this.#body; };
  enterBtn() { return this.#enterBtn; };
  
  //
  handleKeyUp(evt)
  {
   if (!evt) evt = window.event;

    if (evt.which=="13") 
    {
      this.trigger('form:submitted');
      return false;
    }
  };

  handleSubmit()
  {
    this.trigger('form:submitted');
  };

  clearForm()
  {
    this.get('login').val('');
    this.get('password').val('');
    this.#body.replaceChildren();
  };

  resetPassword()
  {
    const m = new WDModalPasswordRequest();
    m.show();
  };

};

customElements.define('wd-user-pass',WDUserPass);
