
import WDCard,{ WDCardHeader } from './wd-card';
import WDButton from '../button/wd-button';

export default class WDAccordionCard extends WDCard {

	#options;
	#wrapper = null;
	#header = null;
	#button = null;
	#icon = null;
	#collapsed;
	#inTransition = false;
					
	constructor(opts)
	{
		const cardOptions = { collapsed: false};
	  Object.assign(cardOptions,opts);

	  super(cardOptions).classList.add('wd-accordion-card');
	  
	  this.#options = cardOptions;
	  this.#collapsed = this.#options.collapsed;

		this.header();
		this.setupWrapper();

		if (!this.#collapsed) this.show();
	};

	header()
  {
    if (!this.#header) 
    {
      this.#header = new WDAccordionCardHeader(this);
      this.body().before(this.#header);
    }
    return this.#header;
  };

	setupWrapper()
	{
		this.#wrapper = wdc('<div class="wd-accordion-card-wrapper"/>');

		this.#wrapper.addEventListener('transitionstart',(evt) => {

			this.#inTransition = true;
			const evtName = this.classList.contains('is-open') ? 'accordion:show' : 'accordion:hide';
			this.trigger(evtName);

		});
		
		this.#wrapper.addEventListener('transitionend',(evt) => {

			this.#inTransition = false;

			const evtName = this.classList.contains('is-open') ? 'accordion:shown' : 'accordion:hidden';
			this.classList.remove('is-transitioning');
			this.trigger(evtName);
		});

		//put the wrapper before the body, then move the body inside the wrapper
		this.body().before(this.#wrapper);

		const overflow = wdc('<div class="wd-accordion-card-overflow"/>');
		overflow.append( this.body() );
		
		this.#wrapper.append(overflow);
	};

	/** card visibility **/
	show() { 
		this.classList.add('is-open','is-transitioning'); 
		this.checkTransition();
	};
	
	hide() { 
		this.classList.remove('is-open');
		this.classList.add('is-transitioning'); 
		this.checkTransition();
	};

	checkTransition()
	{
		setTimeout( () => {
			if (!this.#inTransition) this.classList.remove('is-transitioning');
		},0);
	};
		
	toggle() 
	{ 
		if ( this.isOpen() ) this.hide();
		else this.show();
	};

	isOpen() { return this.classList.contains('is-open'); };

};

export class WDAccordionCardHeader extends WDCardHeader {

	#title = null;
	#buttons = null;
	#control = null;

	/**
		* a control for toggling visibility
		*/	
	control()
	{
	 	if (!this.#control)
	 	{
	 		let wrapper = wdc('<div class="wd-card-header-title"/>');
	 		this.#control = new WDButton().fasIcon('chevron-right');
	 		this.#control.on('click',(evt) => this.closest('.wd-card').toggle(evt) );
				
	 		wrapper.append(this.#control);
	 		this.prepend(wrapper);
		}
			
		return this.#control;
	};

	//legacy support, use "label()" instead
	title()
	{
	 	return this.control().label();
	};

	label()
	{
		return this.control().label();
	};

};

customElements.define('wd-accordion-card',WDAccordionCard);
customElements.define('wd-accordion-card-header',WDAccordionCardHeader);
