
import { WDFormInput } from './wd-form-input';

export class WDFormHidden extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'hidden';
    super(cfg);
  };
};

customElements.define('wd-form-hidden',WDFormHidden);

