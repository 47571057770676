
import WDCard from './wd-card';
import WDFormProxy from '../form/wd-form-proxy';

export default class WDCardForm extends WDCard {

  #options = {};
  #form = null;

  constructor(opts)
  {
    super(opts).classList.add('wd-card-form');

    if (opts) Object.assign(this.#options,opts);

    //build the form  
    this.#form = new WDFormProxy(this.#options);
    this.body().append(this.#form);
  };
    
  //
  form() { return this.#form; };
  proxy() { return this.#form.proxy(); };
  
  //form method shortcuts
  addForms(data) { return this.#form.addForms(data); };
	addForm(data) { return this.#form.addForm(data); };
	addInfo(lbl,value,name) { return this.#form.addInfo(lbl,value,name); };
	addElement(data) { return this.#form.addElement(data); };
	val(data) { return this.#form.val(data); };
	items(data) { return this.#form.items(data); };
	serialize(data) { return this.#form.serialize(data); };
	populate(data) { return this.#form.populate(data); };
	dirty(data) { return this.#form.dirty(data); };
	get(data) { return this.#form.get(data); };
	getInput(data) { return this.#form.getInput(data); };	
	validate(data) { return this.#form.validate(data); };	
	readonly(data) { return this.#form.readonly(data); };

	//
	reset()
	{
	  this.resetNavbar();
	  this.resetToolbar();

    this.#form.remove();
    this.#form = new WDFormProxy(this.#options);
    this.body().append(this.#form);

    this.setup();
	};

  req() { return this.proxy().req(); };
  post() { return this.proxy().post(); };
  del() { return this.proxy().del(); };

};

customElements.define('wd-card-form',WDCardForm);
