
import WDForm from './wd-form';
import { WDProxy } from '../../core/wd-proxy';

/**
	*	@class WDFormProxy
	*/
export default class WDFormProxy extends WDForm {

	#proxy;
	#fetch;
					
	constructor(cfg)
	{
		super(cfg);

		this.setProxy( cfg?.proxy || new WDProxy({}) );

    //if user changes form value, update our proxy object with the new value
    this.on('form:change',(evt) => this.handleFormChange(evt) );
  };

	proxy() { return this.#proxy; };

	setProxy(proxy)
	{
		this.#proxy = proxy;
		this.#proxy.setRecursive(true);
		
    //setup an event watcher for proxy set if we have a content handler
    this.#proxy.on('set', evtData => this.handleSet(evtData) );
    this.#proxy.on('delete',evtData => this.handleDelete(evtData) );
	};

	/**
	  * updates the proxy values when the form values change
	  */
  handleFormChange(evt)
  {
      const f = evt.detail;
      const k = f.name();
      const v = f.val();

      if (f.name().indexOf('[') != -1)
      {
        const obj = {};
        obj[ k ] = v;

        const res = this.toObjectNotation(obj);
        this.#proxy.merge(res);
      }
      else
      {
        this.#proxy[ k ] = v;
      }
	};

	/**
	  *
	  */
  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const key = evtData.property;
    const value = evtData.value; 

    //handle values for full objects passed
    if (value !== null && typeof(value) == 'object')
    {
      const res = this.toFormNotation(value,key);

      for (let k in res)
      {
        const f = this.get(k);
        if (f && f.val() != res[k] ) f.val( res[k] );
      }
    }
    //updating an individual member of a nested object
    else if (key.indexOf('.') != -1 && key.indexOf('_') != 0)
    {
      //convert key path dot notation to form bracket notation
      const kb = key.replace(/\.(.+?)(?=\.|$)/g, (m, s) => `[${s}]` );

      const f = this.get(kb);
      if ( f && f.val() != value ) f.val( value );
    }
    //handle top-level values
    else
    {
      const f = this.get(key);
      if ( f && f.val() != value ) f.val( value );
    }
    
  };	

  handleDelete(evtData)
  {
    const key = evtData.property;

    //updating an individual member of a nested object
    if (key.indexOf('.') != -1 && key.indexOf('_') != 0)
    {
      //convert key path dot notation to form bracket notation
      const kb = key.replace(/\.(.+?)(?=\.|$)/g, (m, s) => `[${s}]` );

      const f = this.get(kb);
      if (f) f.val(null);
    }
    //handle top-level values
    else
    {
      const f = this.get(key);
      if (f) f.val(null);
    }
    
    //now check for any bracket-notation forms, basically forms
    //that exist for this object but below its level
    for ( let k in this.items() )
    {
      if (k.indexOf( key + '[') != -1)
      {
        const item = this.get(k);
        item.val(null);
      }      
    }
  };


  /**
    * if the proxy is already populated, this allows us to pass the value to the form 
    * when the form is created
    */
  addForm(cfg)
  {
    if (cfg.name)
    {
      let proxyValue = null;
      
      if (cfg.name.indexOf('[') !== -1)
      {
        proxyValue = this.getValueFromBracketNotation(cfg.name);
      }
      else if ( this.#proxy[cfg.name] !== undefined )
      {
        proxyValue = this.#proxy[cfg.name];
      }
      
      //
      if (proxyValue !== undefined) cfg.value = proxyValue;
    }

    //add the form
    return super.addForm(cfg).then( formItem => {
      if ( cfg?.fetch && cfg.fetch.getURI().includes('${') ) this.handleURITemplate(formItem);
      return formItem;
    });

  };
 
  isValidFetched(uri)
  {
    return ( !uri.includes('${') && !uri.includes('/undefined') && !uri.includes('/null') );
  }; 
  
  /**
    * handle a form uri that's a template
    */
  handleURITemplate(formItem)
  {
    const cfg = formItem.config();
    const uriTemplate = cfg.fetch.getURI();

    const watchForms = uriTemplate.getTemplateKeys();
    const newURI = uriTemplate.interpolate(this.#proxy.toObject());
  
    //if we could resolve the template, update the fetch uri
    if ( this.isValidFetched(newURI) ) formItem.setFetchURI(newURI);
    
    //our form uri has a template, setup to watch the parent forms for changes
    watchForms.forEach( watchName => {

      //look for dot notation in the uri. If there is our form name will use bracket notation
      const wn = watchName.replace(/\.([^.]+)/g, (_, p1) => `[${p1}]`);
      const watchForm = this.get(wn);

      //found a parent form        
      if (watchForm)
      {
        watchForm.on('change',() => {

          const newURI = uriTemplate.interpolate( this.#proxy.toObject() );
            
          //if we could resolve the template, update the fetch uri
          if ( this.isValidFetched(newURI) ) 
          {
            formItem.setFetchURI(newURI);
          }
          else 
          {
            //reset the form, the parent form has been set to something invalid
            formItem.options().replace([]);
            formItem.val(null);
          }

        });
      }
        
    });    

  };

  getValueFromDotNotation(path)
  {
    let retVal = this.#proxy;

    path.split('.').some( k => {

      retVal = retVal[k];
      
      //if the value for this key is undefined, return undefined
      if (retVal === undefined) return true;
    
    });

    return retVal;
  
  };

  getValueFromBracketNotation(path)
  {
    const dotPath = path.replaceAll('[','.').replaceAll(']','');
    return this.getValueFromDotNotation(dotPath);  
  };

  req() { return this.#proxy.req(); };
  post() { return this.#proxy.post(); };
  del() { return this.#proxy.del(); };

};

customElements.define('wd-form-proxy',WDFormProxy);
