
import WDCardList from './wd-card-list';
import WDListSort from '../lists/wd-list-sort';

export default class WDCardListSort extends WDCardList {

	#list;
	
	/**
		* sets up the underlying list for the pane
		*/
	list(cfg) { 
	
		if (typeof(cfg) == 'undefined')
		{
			return this.#list;
		}
		else
		{
			this.#list = new WDListSort(cfg).classList.add('scrollable');
			this.body().append(this.#list);

			//I can't decide on naming yet
			if (cfg.filters) this.filters(cfg.filters);
			
			return this.#list;
		}
	};

};

customElements.define('wd-card-list-sort',WDCardListSort);
