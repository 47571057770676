

import { WDFormDropdownmulti } from './wd-form-dropdown-multi';

/**
	Note:
	This form can store objects as values, but it uses the labels to search.  This
	way it matches what a user would type.  what they see is the value expected
	*/
export class WDFormDropdownobjectmulti extends WDFormDropdownmulti {

	constructor(opts)
	{
		opts.dropdown_type = 'objectmulti';
		super(opts);
	};
	
};

customElements.define('wd-form-dropdown-object-multi',WDFormDropdownobjectmulti);