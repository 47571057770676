
import WDElement from '../wd-element';
import WDCard from './wd-card';

/**
  not sure we even need a class, but it keeps the logic organized and allows
  for expandability later
  */
export default class WDCards extends WDElement {

  //mode can be "group", "deck" or "column", or "none" if we just want a vertically stacked
  //card set with a single row
  constructor(mode)
  {
    let cn = (mode != null) ? 'wd-card-' + mode : 'wd-card-group';
    super().classList.add('wd-cards',cn);
    
    return this;
  };
  
  add(optsOrCard,wrapperClass)
  {

    const wrapper = wdc('<div class="wd-card-wrapper"/>');
    if (wrapperClass) 
    {
      const classNames = Array.isArray(wrapperClass) ? wrapperClass : wrapperClass.split(' ');
      wrapper.classList.add(...classNames);
    }
    
    const card = ( optsOrCard?.classList.contains('wd-card') ) ? optsOrCard : new WDCard(optsOrCard);
    
    wrapper.append(card);
    this.append(wrapper);
    
    return card;
  };

};

customElements.define('wd-cards',WDCards);
