
import WDElement from '../wd-element';
import WDNavbarMenu from './wd-navbar-menu';
import WDNavbarMenuPager from './wd-navbar-menu-pager';
import WDNavbarFilter from '../filter/wd-navbar-filter';

export default class WDNavbar extends WDElement {

	#options = {toggler_icon:'fa-solid fa-bars'};
	#prefix = null;
	#suffix = null;
	#left = null;
	#center = null;
	#right = null;

	constructor(opts)
	{
		super().setAttribute('role','navigation');
		this.classList.add('wd-navbar');
		
		if (opts) this.#options = Object.assign({},this.#options,opts);

		return this;
	};

	/** adds a new menu to the navbar **/
	add(cfg)
	{
		const menu = new WDNavbarMenu(cfg);
		this.append(menu);
		
		return menu;
	};

	prefix(cfg)
	{
		if (!this.#prefix) 
		{
			this.#prefix = this.add(cfg);
			this.#prefix.classList.add('wd-navbar-menu-prefix');
		}
		return this.#prefix;
	};

	suffix(cfg)
	{
		if (!this.#suffix) 
		{
			this.#suffix = this.add(cfg);
			this.#suffix.classList.add('wd-navbar-menu-suffix');
		}
		return this.#suffix;
	};

	left(cfg)
	{
		if (!this.#left) 
		{
			this.#left = this.add(cfg);
			this.#left.classList.add('wd-navbar-menu-left');
		}
		return this.#left;
	};

	center(cfg)
	{
		if (!this.#center) 
		{
			this.#center = this.add(cfg);
			this.#center.classList.add('wd-navbar-menu-center');
		}
		return this.#center;
	};

	right(cfg)
	{
		if (!this.#right) 
		{
			this.#right = this.add(cfg);
			this.#right.classList.add('wd-navbar-menu-right');
		}
		return this.#right;
	};

  addBreadcrumbs()
  {
  	const crumbs = new WDNavbarMenu();
  	crumbs.classList.add('wd-navbar-breadcrumbs');

    this.append(crumbs);
    return crumbs;
  };

  addPager()
  {
    const pager = new WDNavbarMenuPager();
    this.append(pager);
    return pager;
  };

	addFilters(cfg)
	{
		const f = new WDNavbarFilter(cfg);
		this.append(f);

		return f;
	};  

};

customElements.define('wd-navbar',WDNavbar);
