
import WDButton from './wd-button'

export default class WDToggleButton extends WDButton {

  constructor(initText)
  {
    super(initText).classList.add('wd-toggle-button');
    
    this.icon('fa-regular fa-square');    
    this.on('click',() => this.toggle() );
  };
  
  toggle()
  {
    if (this.icon().classList.contains('fa-check-square'))
    {
      this.icon('fa-regular fa-square');
    }
    else
    {
      this.icon('fa-regular fa-check-square');
    }
  };
  
  checked(check)
  {
    if (typeof(check) == 'undefined')
    {
      return this.icon().classList.contains('fa-check-square');
    }
    else
    {
      if (check === true) this.icon('fa-regular fa-check-square');
      else this.icon('fa-regular fa-square');
    }
    
  };
  
};

customElements.define('wd-toggle-button',WDToggleButton);
