
import { WDFormDropdown } from './wd-form-dropdown';

export class WDFormBoolean extends WDFormDropdown {

  constructor(cfg)
  {
    if (!cfg.type) cfg.type = 'boolean';
    if (cfg?.allow_clear === undefined) cfg.allow_clear = false;

    cfg.options = [
      {label:'Yes',value:'1'},
      {label:'No',value:'0'}
    ];

    super(cfg);
  };
};

customElements.define('wd-form-boolean',WDFormBoolean);
