
import WDModal from './wd-modal'
import WDPanes from '../pane/wd-panes'

export default class WDModalPanes extends WDModal {

  #panes = null;

  constructor(opts)
  {
    super(opts);

    this.on('modal:show',() => {
      this.hideFooter();
      this.panes();
    });
  };
  
  panes()
  {
    if (!this.#panes)
    {
      this.#panes = new WDPanes({inModal:true});
      this.body().append(this.#panes);
    }
    
    return this.#panes;
  };

  currentPane()
  {
    return this.panes().currentPane();
  };

};

customElements.define('wd-modal-panes',WDModalPanes);