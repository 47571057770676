
/**
	* when called, check to see if we are authorized.  If not, then show the login modal.
	* my thinking is if the site doesn't need auth, then we don't put the tag in.
	*/

import { WDSiteNavbarMenu } from './wd-site-navigation';

export default class WDAuthMenu extends WDSiteNavbarMenu {

	#rootMenu;

	connectedCallback()
	{
		WDAuth.on('auth:authorized',() => this.buildMenu() );
		WDAuth.on('auth:logout',() => this.destroyMenu() );
	};

	destroyMenu()
	{
		if (this.#rootMenu)
		{
			this.#rootMenu.remove();
			this.#rootMenu = null;
		}
	};

	buildMenu()
	{
		if (this.#rootMenu) this.#rootMenu.remove();

    if (!WDAuth.authorized())
    {   	
    	this.buildLoginMenu();
    }   	
    else
    {
    	this.buildProfileMenu();
    }
	};
	
	buildLoginMenu()
	{
		this.#rootMenu = this.add({label:'Login',fasIcon:'sign-in-alt'});
		this.#rootMenu.on('click',() => this.modal(true) );
	};
	
	buildProfileMenu()
	{
		this.#rootMenu = this.add({label:WDAuth.account().login,fasIcon:'user-circle'});
		const submenu = this.#rootMenu.submenu();
			
    const editBtn = submenu.add({label:'Edit Profile',fasIcon:'user'});
    editBtn.on('click',(evt) => {
      evt.stopPropagation();
      this.editProfile(evt);
    });	

		if (WDController.config().profileMenu)
		{
			WDController.config().profileMenu.forEach( item => {
					const menuItem = submenu.add();
  		
          if (item.label) menuItem.text(item.label);
          if (item.icon) menuItem.icon(item.icon);
          if (item.uri) menuItem.on('click',(evt) => {
            evt.stopPropagation();
            this.launcher().root(item.uri);
          } );	
		
			});	
		}		
		
		const loBtn = submenu.add({label:'Logout',fasIcon:'sign-out-alt'});
    loBtn.on('click',(evt) => {
      evt.stopPropagation();
      WDAuth.logout();
    });	
	};

  editProfile()
  {		
    WDController.root('/profile');
  };		

};

customElements.define('wd-auth-menu',WDAuthMenu);
