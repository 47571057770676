
import WDElement from '../wd-element.js';
import {WDListItem,WDListHeader,WDListFooter} from './wd-list-item';

export default class WDList extends WDElement {

	#wrapper = null;
	#body = null;
	#header = null;
	#footer = null;
  #map = {label:'label', value:'value'};

	constructor() 
	{
		super().classList.add('wd-list');

		//build the base structure
		this.#wrapper = wdc('<div class="wd-list-body-wrapper"/>');
		this.#body = wdc('<div class="wd-list-body"/>');

		//assemble the wrapper and body - required
		this.append(this.#wrapper);
		this.#wrapper.append(this.#body);
		
		return this;
	};

	//public accessors	
	body() { return this.#body; };
	wrapper() { return this.#wrapper; };
	items() { return this.#body.children; };
	itemArray() { return Array.from( this.items() ); };
	
	responsive(mode)
	{
		if (mode === false) this.classList.remove('responsive');
		else this.classList.add('responsive');
	};

  //getter/setter for the map
  map(m) 
  { 
  	if (typeof(m) == 'undefined') return this.#map;
  	else
  	{
  		this.#map = m; 
  		return this;
		}
	};
	
	//
	header()
	{
		if (!this.#header)
		{
			this.#header = new WDListHeader(this);
			this.#wrapper.before(this.#header);
		}

		return this.#header;
	};

	footer()
	{
		if (!this.#footer)
		{
			this.#footer = new WDListFooter(this);
			this.#wrapper.after(this.#footer);
		}

		return this.#footer;
	};

	createItem()
	{
		const item = new WDListItem(this);
		item.on('item:selected',() => this.deselectAll() );
		return item;
	};

	add() 
	{ 
		return this.insert(null);
	};

	single() 
	{ 
		this.reset();
		return this.insert(0);
	};

	insert(idx)
	{
		const item = this.createItem();

		if (idx === 0) 
		{
			this.#body.prepend(item);
		}
		else if (idx < this.items().length)
		{
			this.items()[idx].before(item);
		}
		else
		{
			this.#body.append(item);
		}

		return item;
	};

	replaceItemAt(idx)
  { 
  	const item = this.createItem();
  	    	
    if (idx < this.items().length)
    {
    	const r = this.items()[idx];
    	
    	r.before(item);
    	r.remove();
    }
    else
    {
    	this.#body.append(item);
    }

    return item;
  };

	removeItemAt(idx)
	{
		if (typeof(this.items()[idx]) != 'undefined')
		{
			this.items()[idx].remove();
		}
	};

	removeItems(arr)
	{
		arr.forEach( item => item.remove() );
	};

	selected() 
	{ 
		let selItem = null;
		
		this.itemArray().some( item => {

			if (item.selected() == true)
			{
				selItem = item;
				return true;
			}		
		});

		return selItem;
	};
	
	/** selects a item based on the passed value **/
  selectFromVal(val)
  {
  	const item = this.itemFromVal(val);
  	if (item) 
  	{
  		item.select(true);
  		item.scrollTo();
		}
  };

  deselectAll()
  {
  	const selItem = this.selected();
  	if (selItem != null) selItem.selected(false);
  };

	/** selects a item based on the passed value **/
  itemFromVal(val)
  {
  	let ret = false;

  	this.itemArray().some( item => {
    
    	if (item.val() == val)
    	{
    		ret = item;
    		return true;
			}
		});

		return ret;
  };

	reset()
	{
		this.#body.replaceChildren();
	};

  /**
    remaps the data to use something standard.  In general this
    is intended to be a label/value map, but its not strictly enforced
    */
  remap(data)
  {
    //remap some of our data for value matching
    if (this.#map && data)
    {
      for (const key in this.#map) 
      {
        const val = this.#map[key];

        //if we have a value for this mapped key, and it's not already set
        if (typeof(data[key]) == 'undefined' && typeof(data[val]) != 'undefined') 
        {
        	data[key] = data[val];
				}
      }
    }
  };

	checked()
	{ 
		const checked = [];
		
		this.itemArray().forEach( item => {
			if (item.checked() == true) checked.push(item);
		});

		return checked;
	};

  val(vals)
  {
  	if (typeof(vals) == 'undefined')
  	{
  		const ret = [];
  		this.checked().forEach( item => ret.push( item.val() ) );
			return ret;
  	}
  	else
  	{
  		this.itemArray().forEach( item => {
  	
  			const checked = (vals.indexOf( item.val() ) != -1);	
  			item.checked( checked );
  		});
  	};
  
  };

  serialize() { return this.val(); };
  populate(data) { return this.val(data); };

  data(vals)
  {
  	if (typeof(vals) == 'undefined')
  	{
  		const ret = [];
  		this.checked().forEach( item => ret.push( item.data() ) );
			return ret;
  	}
  	else
  	{
  		this.remap(vals);

  		const setVals = [];
  		vals.forEach( v => setVals.push(v.value) );
  		
  		this.itemArray().forEach( item => {
  			const checked = (setVals.indexOf( item.val() ) != -1);	
  			item.checked( checked );
  		});
  	};
  
  };

  allData()
  {
		const ret = [];
		this.itemArray().forEach( item => ret.push( item.data() ) );
		return ret;
  
  };
  
};

customElements.define('wd-list',WDList);
