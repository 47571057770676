
import WDDropdownForm from './wd-dropdown-form';

/**
	* just like WDDropdownForm except the value is stored as an object
	*/
export default class WDDropdownObjectForm extends WDDropdownForm {


	getObjectFromValue()
	{
		let retObj = null;

		if (this.values().length > 0)
		{
			this.values().forEach( val => {
			
				this.options().some( opt => {
				
					if (opt.value == val)
					{
						retObj = opt;
						return true;
					}
				
				});
			});
		}
		
		return retObj;
		
	};

  /**
  	*
  	*/
	val(val,evt)
	{
		if (typeof(val) == 'undefined') 
		{
			return this.getObjectFromValue();
		}
		else
		{
			super.val( val.value );
			return this;
		}

	};

};

customElements.define('wd-dropdown-object-form',WDDropdownObjectForm);
