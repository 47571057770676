
import WDElement from '../wd-element'
import WDButton from '../button/wd-button.js';

export default class WDDropdown extends WDElement {

	#element = null;
	#items = [];

	constructor()
	{
		super().classList.add('dropdown-menu');
		this.setAttribute('role','menu');
		return this;
	};

	//accessors
	items() { return this.#items; };
					
	reset() 
	{ 
		this.empty(); 
		this.#items = [];
		return this;
	};

	addDivider()
	{
		const d = wdc('<div class="dropdown-divider"/>');
		this.append(d);
		return d;
	};

	addPrefix(obj)
	{
		const prefix = wdc('<div class="dropdown-item"/>');
		prefix.on('click',(evt) => evt.preventDefault() );

		this.prepend(prefix);
		
		if (obj) prefix.append(obj);
		
		return prefix;
	};

	add(title)
	{
		const item = this.insertItemAt( this.#items.length );
		if (title) item.label(title);

		return item;
	};

	createItem()
	{
		const item = new WDButton();
		item.classList.remove('btn');
		item.classList.add('dropdown-item');
		
		return item;
	};

  insertItemAt(idx)
  {
    const item = this.createItem(); //WDDropdownItem();

    if (idx == 0) 
    {
      this.prepend(item);
      this.#items.unshift(item);
    }
    else if (idx < this.#items.length)
    {
    	this.#items[idx].before(item);
      this.#items.splice(idx,0,item);
    }
    else
    {
      this.append(item);
      this.#items.push(item); 
    }

    return item;
  };

  replaceItemAt(idx)
  { 
    const item = this.createItem(); //DropdownItem();
	
    if (idx < this.#items.length)
    {
      const r = this.#items[idx];

			r.before(item);
      r.remove();

      this.#items[idx] = item;
    }
    else
    {
      this.append(item);
      this.#items.push(item); 
    }

    return item;
  };

  removeItemAt(idx)
  {
    if (typeof(this.#items[idx]) != 'undefined')
    {
      this.#items[idx].remove();
      this.#items.splice( idx, 1);
    }
  };

  removeItem(item)
  {
  	this.removeItemAt( item.getIndex() );
	};

  removeItems(arr)
  {
    arr.forEach( item => {
	  	this.removeItemAt( item.getIndex() );
		});
  };

	dropup()
	{
		this.parentElement.classList.add('dropup');
	};

	toggle()
	{
		const instance = bootstrap.Dropdown.getOrCreateInstance( this.previousElementSibling );
		if (instance) instance.toggle();
	};

	show()
	{
		const instance = bootstrap.Dropdown.getOrCreateInstance( this.previousElementSibling );
		if (instance) instance.show();
	};

	hide()
	{
		const instance = bootstrap.Dropdown.getOrCreateInstance( this.previousElementSibling );
		if (instance) instance.hide();
	};
	  
};

customElements.define('wd-dropdown',WDDropdown);

