
import { WDFormInput } from './wd-form-input';

export class WDFormDatetimelocal extends WDFormInput {

  constructor(cfg) {
    cfg.type = 'datetime-local';
    super(cfg);
  };

  val(val)
  {
    if (typeof(val) == 'undefined') 
    {
      return super.val();
    }
    else
    {
      let format = (this.attr('type') == 'date') ? 'Y-m-d' : 'Y-m-dTH:i:s';

      //if not passed a T, we were just passed a straight upd ate which would be interpretted as UTC
      let date = (val.indexOf('T') != -1) ? new Date(val) : new Date(val).localDate();
      let formatted = date.format(format);
      
      super.val(formatted);
      return this;
    }
  };
};

customElements.define('wd-form-datetime-local',WDFormDatetimelocal);

