
export default class WDFetchError extends Error {

	constructor(message,params) 
	{
		super(message);

		if (typeof(params) != 'undefined')
		{
			Object.assign(this,params);
		}
		
		if (params.response)
		{
			params.response.text().then( results => {
				console.log('Server Error',results);
			});
		};

	};

};

