
import WDElement from '../wd-element';
import WDChecklist from './wd-checklist';
import WDButton from '../button/wd-button';
import WDUtil from '../../core/wd-util';

/**
	Note:
	This form can store objects as values, but it uses the labels to search.  This
	way it matches what a user would type.  what they see is the value expected
	*/
export default class WDDropdownForm extends WDElement {

	#search = null;
	#timeout = null;
	#button = null;
	#dropdown = null;
	#optionsBtn = null;
	#checklist = null;
	
	#config = {			
		options: [],
		fetch: null,
		allow_search: false,
		allow_clear: true,
		value: null,
		templates: {
			label: '${label}',
			value: '${value}'
		},
		button: { 
			text: 'Not Selected',
			prefix: null,
			theme: '', 
			size: 'md',
			icon: null,
			update_text: true,
		},
		edit: {
			text: 'Edit Options',
			handler: null,
			icon: 'fa-solid fa-ellipsis-v'
		}
	};
				
	constructor(opts)
	{
		//init
		super().classList.add('wd-dropdown-form','btn-group');

		//merge any passed parameters in
		if (opts) WDUtil.merge(this.#config,opts);

    //setup the button and base dropdown
   	this.button();

   	//allows us to update the button text
		this.#checklist.on('value:set',() => this.handleValueSet() );
		this.#checklist.on('options:set',() => this.handleOptionsSet() );
		
		this.val( this.#config.value );
	};

	
  /** accessors **/
  config() { return this.#config; };
  options() { return this.#checklist.options(); };
  values() { return this.#checklist.values(); };
  items() { return this.#checklist.items(); };
  fetch(f) { return this.#checklist.fetch(f); };
  
  button()
  {
  	if (!this.#button)
  	{
	 		this.#button = new WDButton();
	
	 		//setup the button aesthetic
	 		if (this.#config.button.text) this.#button.label(this.#config.button.text);
	 		if (this.#config.button.title) this.#button.title(this.#config.button.title);
	 		if (this.#config.button.label) this.#button.label(this.#config.button.label);
			if (this.#config.button.icon) this.#button.icon(this.#config.button.icon);
			if (this.#config.button.theme) this.#button.theme(this.#config.button.theme);
			if (this.#config.button.size) this.#button.size(this.#config.button.size);

			if (this.#config?.readonly || this.#config?.button?.disabled) this.#button.disabled = true;

			this.append(this.#button);
	
			//setup the dropdown
			this.dropdown();
			this.checklist();
				
			//setup our other options
			if (this.#config?.edit?.handler) this.setupEdit();
			if (this.#config?.allow_search) this.setupSearch();
		}

		return this.#button;
	};

	readonly(mode)
	{
		if (mode == true) this.disable();
		else this.enable();
	};

	disable() { this.#button.disabled = true; };
	enable() { this.#button.disabled = false; };

  /**
  	setup dropdown for results
  	*/
	dropdown()
	{
    if (!this.#dropdown) 
    {
      this.#button.append( wdc('<span class="fa-solid fa-caret-down"/>') );
      this.#button.classList.add("dropdown-toggle");
      this.#button.setAttribute("data-bs-toggle","dropdown");
      
      const autoClose = this.#config.multiple == true ? 'outside' : 'true';
      this.#button.setAttribute("data-bs-auto-close",autoClose);
      
      this.#dropdown = wdc('<div class="dropdown-menu" role="menu"/>');
      this.append(this.#dropdown);
		}
		
		return this.#dropdown;
	};
	
	checklist()
	{
		if (!this.#checklist)
		{
			//pass relevant options to checklist
			const cfg = {
				options: this.#config.options,
				fetch: this.#config?.fetch,
				value: this.#config?.value,
				icons: this.#config?.icons,
				templates: this.#config?.templates,
				map: this.#config?.map,
				separator: this.#config?.separator,
				allow_clear: this.#config.allow_clear,
				multiple: this.#config.multiple,
			};
			
			this.#checklist = new WDChecklist(cfg);
			this.#dropdown.append(this.#checklist);

			this.#checklist.on('value:set',(evt) => this.trigger('value:set',evt) );
			this.#checklist.on('change',(evt) => this.trigger('change',evt) );
			this.#checklist.on('input',(evt) => this.trigger('input',evt) );
		}
    
    return this.#checklist;
  };

	setupSearch()
	{
		//setup the dropdown and the search
		this.#search = wdc('<input type="text" class="form-control form-control-sm" autocomplete="off" placeholder="Search" name="dropdown_search"/>');
		this.dropdown().prepend(this.#search);
		this.dropdown().classList.add('search-form-item');

		//keep the change event from propagating up and triggering an onchange event in our form
		this.#search.addEventListener('change',(evt) => { evt.stopPropagation(); } );

   	//call our handler, and pass our response fmethod to it
		this.#search.addEventListener('input',(evt) => {
			evt.stopPropagation();
			this.handleKeyUp(evt);
		} );
	};

	/**
		*
		*/	
	optionsBtn()
	{
		if (!this._optionsBtn)
		{
			this._optionsBtn = new WDButton().fasIcon('ellipsis-v').theme('clear');
			this.append(this._optionsBtn);
			
			this._optionsBtn.dropdown().classList.add('dropdown-menu-end');
			this.classList.add('dropdown-form-options-group');
		}
		
		return this._optionsBtn;
	};

	/**
		*/	
	setupEdit()
	{
		const editCfg = this.#config.edit;
		
		const editBtn = this.optionsBtn().dropdown().add(editCfg.text);
		editBtn.icon(editCfg.icon).classList.add('edit-btn');
		editBtn.on('click',() => {

			//if allowsearch, pass the contents of the search box so the handler can
			//automatically create a new option if they wish
			const passVal = (this.#config.allow_search == true) ? this.#search.val() : null;

			//whatever we spawn, if it triggers an item:updated event then refresh our list
			const ref = this.#config.edit.handler(passVal); 

			if (ref) 
			{
				ref.on('items:deleted',(evt) => {

					if (evt?.detail?.item) 
					{
						this.#checklist.options().some( (opt,idx) => {
						
							const stringVal = this.#config.templates.value.interpolate(opt);
							const numVal = parseFloat(stringVal);
							const optval = !isNaN(numVal) && isFinite(stringVal) ? numVal : stringVal;
							
							if ( optVal == evt.detail.item.value() )
							{
								if ( this.val() == evt.detail.item.value() )
								{
									this.val('0',true);
								}
							
								this.#checklist.options().remove(idx);
								return true;
							}
						});				
	
					}

					this.trigger('options:updated');

				});

				//refresh our list if we get an items:updated event
				ref.on('items:updated',(evt) => {

					//update the list then set it as a new value					
					if (this.#config.fetch)
					{
						this.#checklist.fetchOptions().then( () => {

							this.trigger('options:updated');

							if (evt?.detail?.item?.value() !== undefined)
							{
									this.val(evt.detail.item.value(),true);
							}
						});
					}
					//otherwise just push the new value onto the end of the list
					else if (evt?.detail?.item?.value !== undefined) 
					{
							this.#checklist.options().unshift( evt.detail.item );
							this.trigger('options:updated');

							setTimeout( () => {
								this.val(evt.detail.item.value(),true);
							},10);
					}
					
				});

				//select the newly created value
				ref.on('item:selected',(evt) => {
					const newItem = evt.detail.item;
					this.val( evt.detail.item, true );
				});
			}

		});

	};

	/**
		handle keup responses from text box
		*/
  handleKeyUp()
  {
  	//if passed a uri, process it
  	if (this.#timeout) clearTimeout(this.#timeout);
  	this.#timeout = setTimeout( () => this.filterOptions() ,250);
	};

  /**
  	*
  	*/
  fetchOptions() 
  { 
  	return this.#checklist.fetchOptions();
	};

	setFetchURI(uri)
	{
		this.fetch().setBaseURI(uri);
		this.fetchOptions();
	};

	setOptions(newOptions)
	{
		this.#checklist.setOptions(newOptions);
	};

  /**
  	*
  	*/
	filterOptions()
	{
		const ss = this.#search.value.toLowerCase();

		this.#checklist.items().forEach( (item,idx) => {

			if (ss.length == '0' || item.label().toLowerCase().indexOf(ss) != -1)
			{
      	item.style.display = 'flex';
			}     
			else
			{	
				item.style.display = 'none';
			}	
  		
		});	
	};
	
  /**
  	*
  	*/
	val(val)
	{
		return this.#checklist.val(val);
	};

	setValFromLabels(lbls)
	{
		return this.#checklist.setValFromLabels(lbls);
	};
	
  /**
  	*
  	*/
	handleValueSet()
	{
		this.updateButtonText();
	};

	handleOptionsSet()
	{
		this.trigger('options:set');
		this.updateButtonText();
	};
	
  /**
  	*
  	*/
	updateButtonText()
	{
		const btnCfg = this.#config.button;

		//update the button text
		if ( btnCfg?.update_text === true)
		{
			const valLabel = this.#checklist.getValueLabel();
			const lbl = valLabel || btnCfg.text;
			const btnLabel = this.button().label();
			btnLabel.replaceChildren();

			if (this.#config?.button.prefix) 
			{
				const lblPrefix = wdc('<span/>');
				lblPrefix.append(this.#config.button.prefix);
				lblPrefix.classList.add('label-prefix');

				btnLabel.append(lblPrefix);
				btnLabel.append(lbl);
			}
			else
			{				
				btnLabel.append(lbl);
			}

		}
		//use the function to udpate the button text if given one
		else if (typeof(btnCfg.update_text) == 'function')
		{
			this.button().label( btnCfg.update_text( this.val() ) );
		}
	};

};

customElements.define('wd-dropdown-form',WDDropdownForm);
