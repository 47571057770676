
import WDElement from '../wd-element';
import WDNavbarMenu from '../navbar/wd-navbar-menu';
import WDUtil from '../../core/wd-util';
import WDApi from '../../core/fetch/wd-api';
import WDButton from '../button/wd-button';
import { WDFormSelect } from '../form/wd-form-select';
import { WDFormHidden } from '../form/wd-form-hidden';

import './wd-filter-matches';

	/**
		*	@param cfg {Object} 
		*	{
		*		filters: [
		*			{	label:'Search String', type:'text', name:'search_string', static: true },												static: always shows, no match value or display
		*			{	label:'Item List', type:'list', name:'item_list', options:this.getOptions(), simple:true },			simple: no match field, always "equals"
		*			{	label:'Adv List', type:'list', name:'advanced_list', options:this.getOptions() },								
		*			{ label:'Sale Date', type:'date', name:'sale_date' },
		*		],
		*		value: [ 
		*			{ field:'area_acres', match: 'greater_than_equals', value:'50'} 
		*		]
		* }
		*/

export default class WDNavbarFilter extends WDNavbarMenu {

	#controlBtn;
	#filters = [];

	#config = { 
		filters:[], 
		allow_minimize: true
	};

	constructor(cfg) {

		super(cfg).classList.add('wd-navbar-menu');

		WDUtil.merge(this.#config,cfg);

		if (cfg?.fetch || cfg?.uri)
		{
			const f = (cfg?.uri) ? new WDApi(cfg.uri) : cfg.fetch;
			
			f.req().then( results => {
				this.#config.filters = results;
				this.initFilters();
			});
		}
		else
		{
			this.initFilters();
		}
	};
	
	initFilters()
	{
		if (this.#config?.value && this.#config.value.length > 0)
		{
			this.populate(this.#config.value);
		}
		else
		{
			this.#config.filters.forEach( filterCfg => {
				this.addFilter(filterCfg);
			});
		}

		//fire the event out of the main loop so we can subscribe after init
		setTimeout( () => this.trigger('filter:ready'), 10);
	};

	addFilter(filterCfg)
	{
		if (filterCfg?.static)
		{
			return this.addFilterToMenu(filterCfg);
		}
		else
		{
			this.addFilterToControl(filterCfg);
			return null;
		}
				
	};

	controlBtn()
	{
		if (!this.#controlBtn)
		{
			this.#controlBtn = new WDButton({icon:'fa-solid fa-filter'});
			this.#controlBtn.classList.add('filters-control-btn');
			this.prepend(this.#controlBtn);
		}
		
		return this.#controlBtn;
	};

	handleFilterApply(f)
	{
		this.trigger('filter:apply');		
	};

	addFilterToControl(filterCfg)
	{
		const dd = this.controlBtn().dropdown();

		//if there is a label and it's an input form, automatically force to advanced mode
		const formTypes = ['text','number','date','datetime','email'];
		if (filterCfg.label && formTypes.includes(filterCfg.type)) filterCfg.mode = 'advanced';

		const item = dd.add(filterCfg.label);
		item.on('click',() => {
			const f = this.addFilterToMenu(filterCfg);
			if (filterCfg?.mode == 'advanced') f.dropdown().show();
		});
					
		if (filterCfg.icon) item.icon(filterCfg.icon);

		return item;
	};

	addFilterToMenu(filterCfg)
	{
		let f = null;
				
		if (!filterCfg?.mode || filterCfg?.mode == 'standard')
		{
			f = new WDNavbarFilterItem(filterCfg);
		}
		else
		{
			f = new WDNavbarFilterAdvancedItem(filterCfg,this);
		}

		f.on('filter:apply',(f) => this.handleFilterApply(f) );
		this.list().append(f);

		return f;		
	};

	/**
		* remove any currently displayed filters
		*/
	reset()
	{
		this.empty();
		this.#controlBtn = null;
	};

	/**
		* verifies a filter value is set for the filter
		*/
	filterHasValue(filter)
	{
		let ret = true;
		const val = filter.value;
		
		if (val === null || val === undefined)
		{
			ret = false;
		}
		else if ( typeof(val) == 'object' )
		{
			if ( val instanceof Array && val.length == 0) ret = false;
		}
		else if ( String(val).length == 0 )
		{
			ret = false;
		}
		
		return ret;
	};

	serialize()
	{
		const values = [];

		this.itemArray().forEach( f => {
			const filterVal = f.serialize();
			if (this.filterHasValue(filterVal)) values.push( f.serialize() );
		});

		console.log('serialize',values);

		return values;
	};

	populate(values)
	{
		this.reset();

		this.#config.filters.forEach( filterCfg => {
			this.addFilter(filterCfg);
		});

		values.forEach( v => {

			//get the filter config from the value field name
			const cfg = this.#config.filters.find( f => f.name == v.field );

			if (cfg?.static)
			{
				this.itemArray().forEach( f => {
					if (f.config().name == v.field) f.populate(v);
				});
			}
			else
			{
				this.addFilterToMenu(cfg);
			}
			/*
			//no config, try to create a custom option using the field/match/value data
			else if (v?.field && 1==2)
			{
				//if it's an array, but there's no defined filter config, all we can do
				//is treat it like a textlist
				if (v?.value instanceof Array) this.addCustomListFilter(v,false);
				else this.addCustomFilter(v,false);
			}
			*/
						
		});
	};

};

class WDNavbarFilterItem extends WDElement {

	#config = {};
	#clearBtn;
	#matchForm = null;
	#valueForm = null;
	#timer;

	constructor(cfg)
	{
		super().classList.add('wd-navbar-menu-item','wd-navbar-filter-item','wd-navbar-filter-type-' + cfg.type);
		if (cfg?.static == true) this.classList.add('is-static');

		this.#config = cfg;	

		if (this.#config.label) 
		{
			if (!this.#config.button) this.#config.button = {};
			this.#config.button.prefix = this.#config.label;
		}

		this.matchForm();
		this.valueForm();
		this.clearBtn();
				
		//passed a value at init
		if (cfg?.value !== null && cfg?.value !== undefined) this.populate(cfg.value);
	};
	
	config() { return this.#config; };

	serialize()
	{
		return { 	
			field: this.#config.name,
			match: this.#matchForm.val(),
			value: this.#valueForm ? this.#valueForm.val() : this.#config?.value
		};
	};

	populate(data)
	{
		if (!data?.match) data.match = this.#config.type == 'text' ? 'contains' : 'equals';
		
		this.matchForm().val( data.match );
		this.valueForm().val( data.value );

		//if (this.updateItemDisplay) this.updateItemDisplay();
	};

	getMatchOptions()
	{
		return (typeof(WDFilterMatches[ this.#config.type ]) != 'undefined') ? WDFilterMatches[ this.#config.type ] : WDFilterMatches.text;
	};
	
	matchForm()
	{
		if (!this.#matchForm)
		{
			//can't figure out how to just inherit this
			if (this.#config.mode == 'advanced')
			{
				const matchCfg = { 	options: this.getMatchOptions(), 
														allow_clear: false 
												 };

				this.#matchForm = new WDFormSelect(matchCfg);
				this.#matchForm.on('change',() => {
					if ( this.hasValue() ) this.triggerApply();
				});
			}
			else
			{
				//const matchVal = this.#config.type == 'text' ? 'contains' : 'equals';
				this.#matchForm = new WDFormHidden({name:'match',value:'equals'});
			}
			
			this.append( this.#matchForm );
		}
		
		return this.#matchForm;
	};	

	hasValue()
	{
		return String( this.#valueForm.val() ).length > 0;
	};

	tmpVal()
	{
		this.val = function() { return this.#config?.value; };
	};
	
	valueForm()
	{
		if (!this.#valueForm)
		{
	    this.#config.autocomplete = 'off';

	    const uri = './wd.js';	//`./wd-form-${this.#config.type.toLowerCase()}`;
	    const fn = `WDForm${this.#config.type.toLowerCase().replace('-','').capitalize()}`;

	    import(uri).then( module => {
	    
	    	this.#valueForm = new module[fn]( this.#config );

				if (this.#config.type == 'text')
				{
					this.#valueForm.on('input',() => this.triggerDelayedApply() );
				}
				else
				{
					this.#valueForm.on('change',() => this.triggerApply() );
				}

				if (this.#clearBtn) this.#clearBtn.before( this.#valueForm );
				else this.append( this.#valueForm );

			});

		}

		return this.#valueForm;	
		
	};

	triggerDelayedApply()
	{
		if (this.#timer) clearTimeout(this.#timer);
		this.#timer = setTimeout( () => this.triggerApply(), 500);		
	};

	triggerApply()
	{
		this.trigger('filter:apply');
	};

	clearBtn()
	{
		if (!this.#clearBtn)
		{
			this.#clearBtn = wdc('<span class="fa-solid fa-xmark"/>');
			this.#clearBtn.addEventListener('click',() => this.clear() );

			this.append( this.#clearBtn );
		};
		
		return this.#clearBtn;
	};

	clear()
	{
		//this.#button.dropdown().hide();
		this.remove();
	};

	getValueLabel()
	{
		const hasOptions = ['select','dropdown','dropdownmulti','list','checklist'];
		let labelVal = this.#valueForm.val();
		
		//dropdown - has a method for generating the label
		if (this.#valueForm?.getValueLabel !== undefined)
		{
			labelVal = this.#valueForm.getValueLabel();
		}
		//it's a type with options, handle multiple/single selection option
		else if (hasOptions.indexOf( this.#config.type ) != -1)
		{
			const isMultiple = this.#valueForm.config().multiple == true;

			labelVal = (isMultiple) ? this.getMultipleLabel() : this.getSingleLabel();
		}

		return labelVal;				
	};

	getSingleLabel()
	{
		let labelVal = this.#valueForm.val();

		this.#valueForm.options().some( opt => {

			if (opt.value == labelVal)
			{
				labelVal = opt.label;
				return true;
			}
		});

		return (labelVal !== null) ? labelVal : 'Not Selected';
	};

	getMultipleLabel()
	{
		const labelVal = this.#valueForm.val();
		const labels = [];

		this.#valueForm.options().some( opt => {

			if (labelVal.indexOf( opt.value ) != -1)
			{
				labels.push(opt.label);
			}
		});

		return (labels.length > 0) ? labels.join(', ') : 'Not Selected';
	};

};



/**
	advanced filter 
	*/
class WDNavbarFilterAdvancedItem extends WDNavbarFilterItem {

	#button;
	#matchForm = null;
	#applyBtn = null;
	#hideBtn = null;
						
	constructor(cfg)
	{
    //try to create a label from the name if we don't have one
    super(cfg).classList.add('wd-navbar-filter-item-advanced');

    this.#button = new WDButton(cfg.label);
    if (cfg.icon) this.#button.icon(cfg.icon);
    this.prepend(this.#button);
    
		//may be a better way to do this, but this works for now
		if (cfg.type == 'boolean')
		{
			cfg.type = 'select';
			cfg.simple = true;
			cfg.options = [{label:'Not Selected',value:'-1'},{label:'Yes',value:'1'},{label:'No',value:'0'}];
		}

		this.buildDropdown();

		this.valueForm().on('options:set',() => this.updateItemDisplay() );
		this.on('filter:apply',() => this.updateItemDisplay() );
	};

	buildDropdown()
	{
		this.#button.setAttribute('data-bs-auto-close','false'); //false);

		this.#button.dropdown().append( wdc('<div class="item-match"/>').append( this.matchForm() ));
		this.#button.dropdown().append( wdc('<div class="item-value"/>').append( this.valueForm() ));
		
		const tb = wdc('<div class="item-toolbar"/>');
		//tb.append( this.applyBtn() );
		tb.append( this.hideBtn() );
		this.#button.dropdown().append(tb);
		
		if (this.config().menu_position) this.#button.dropdown().parentElement.classList.add(this.config().menu_position);

		this.#button.addEventListener('shown.bs.dropdown',() => this.valueForm().focus() );

		if (this.config().simple) this.#button.dropdown().classList.add('is-simple');
	};

	dropdown() { return this.#button.dropdown(); };

	apply()
	{
		this.#button.dropdown().hide();
		this.triggerApply();
	}
	
	hideBtn()
	{
		if (!this.#hideBtn)
		{
			this.#hideBtn = new WDButton('Close Menu').size('sm').fasIcon('times').theme('primary');
			this.#hideBtn.on('click',() => this.#button.dropdown().hide() );
		};
		
		return this.#hideBtn;
	};

	/**
		* once a value is set, update the button text to reflect
		* what's going on 
		*/
	updateItemDisplay()
	{
		const name = this.config().label;
		const match = this.getMatchLabel();				
		const value = this.getValueLabel();

		const str = `${name} ${match} ${value}`;
		this.#button.label(str);
	};	

	getMatchLabel()
	{
		const options = this.getMatchOptions();
		const val = this.matchForm().val();
		let label = null;
		
		options.some( opt => {
			if (opt.value == val)
			{
				label = (opt.short_label || opt.label);
				return true;
			}
		});
		
		return label;
	};

};

customElements.define('wd-navbar-filter',WDNavbarFilter);
customElements.define('wd-navbar-filter-item',WDNavbarFilterItem);
customElements.define('wd-navbar-filter-advanced-item',WDNavbarFilterAdvancedItem);

