
import WDPanes from './wd-panes';
import WDPane from './wd-pane';

export default class WDPaneStack extends WDPane {

  #stack;
  
  constructor(cfg)
  {
    super(cfg).classList.add('wd-pane-stack');

    //setup new panes in modal mode (so always 100% width)
    this.#stack = new WDPanes({ inModal:true} );
  };

  connectedCallback()
  {
    super.connectedCallback();
    this.body().append( this.#stack );
  };
  
  disconnectedCallback()
  {
    super.disconnectedCallback();
    this.#stack = null;
  };

  stack() { return this.#stack; };

  pop(animated)
  {
    if (animated === undefined) animated = true;
  
    if (this.#stack.items().length == 1)
    {
      super.pop(animated);
    }
    else
    {
      this.#stack.pop(animated);
    }
  
  };
  

 /*  
  push(animated) { return this.#stack.push(animated); };
  pushAt(idx,animated) { return this.#stack.pushAt(idx,animated); };
  add(pane,animated) { return this.#stack.add(pane,animated); };
  insertAt(pane,idx) { return this.#stack.insertAt(pane,idx); };
  addAt(pane,idx,animated) { return this.#stack.addAt(pane,idx,animated); };
  */
};

customElements.define('wd-pane-stack',WDPaneStack);
