
export default class WDFormat {

  static boolean(val)
  {
    return Number(val).formatBoolean();
  };

  static date(val,str)
  {
    if (!str) str = 'm/d/Y';

    const dateVal = (typeof(val) == 'object') ? val : new Date(val);

    //utc fix, if not passed any time, add one day
    if ( typeof(val) == 'string' && val.indexOf(':') == -1 ) dateVal.addDays(1);

    return dateVal.format(str);
  };

  static dateTime(val)
  {
    const dateVal = (typeof(val) == 'object') ? val : new Date(val);
    return dateVal.format('m/d/Y h:i a');
  };

  static dateTime24(val)
  {
    const dateVal = (typeof(val) == 'object') ? val : new Date(val);
    return dateVal.format('m/d/Y H:i:s');
  };

  static size(val)
  {
    return Number(val).formatSize();
  };

  static currency(val)
  {
    if (typeof(val) == 'string') 
    {
      val = val.replace(/[^0-9.\-()]/g,'');
    }
    return Number(val).formatCurrency(0);
  };    

  static currencyDecimals(val)
  {
    if (typeof(val) == 'string') 
    {
      val = val.replace(/[^0-9.\-()]/g,'');
    }
    return Number(val).formatCurrency(2);
  };    

  static percent(val,precision)
  {
    if (!precision) precision = '0';
    return String(val).formatPercent(precision);
  };

};

