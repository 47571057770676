/**
	WDForm
		
		Form Container Events:
		form:change -> user changed a form value
		form:input -> user clicked on or typed a form value
		value:set -> a value was set (manual or programmatically) for a form

		dirty:updated -> when a form's dirty status has changed - possibly not needed anymore
	*/

import WDElement from '../wd-element';
import WDApi from '../../core/fetch/wd-api';
import WDButton from '../button/wd-button';
import WDUtil from '../../core/wd-util';
import { WDFormInput } from './wd-form-input';

export default class WDForm extends WDElement {

	#form;
	#dirty = false;
	#saveTimeout = null;
	
	#formConfig = {	
		wrapper_class: 'col-12 col-sm-6',		//default to full width for mobile, half width above
		form_class: 'col-12',								//default to side-by-side with label on mobile, label over column aobve
		label_class: 'col-12',	 						//counterpart to form_class
	};

	//
	constructor(opts) 
	{
		super();

		this.#form = wdc('<form novalidate needs-validation class="wd-form"/>');
		this.append(this.#form);

		if (opts) Object.assign(this.#formConfig,opts);
		this.on('submit',(evt) => { evt.preventDefault(); return false; } );

		//passed a uri for the form configuration
		if (opts?.uri) this.addFormsFromURI(opts.uri);

		return this;
	};

	//allow adding of a single form config or an array of them
	async addForms(items)
	{
		for (const idx in items)
		{
			if (!String(idx).isNumeric()) continue;
			
			const item = items[idx];

			//allow appending of regular html elements
			if (item instanceof HTMLElement)
			{
				//may want to put this in a wrapper
				this.#form.append(item);
			}
			else
			{
				await this.addForm(item);
			}
		}
	};

	getFormDefs(uri)
	{
    return new Promise( (resolve,reject) => {

      const p = new WDApi(uri);
      p.req().then( formDefs => {

      	formDefs.forEach( def => {

      		if (def?.uri)
      		{
            def.fetch = new WDApi(def.uri);
            if (def?.uri_options) def.fetch.add(def.uri_options);
					}
				});
				
				resolve(formDefs);
			})
		});
	};

  addFormsFromURI(uri)
  {
    return new Promise( (resolve,reject) => {

    	this.getFormDefs(uri).then( formDefs => {
    	
      	formDefs.forEach( def => this.addForm(def) );
				resolve(true);

			})
		});
	};

	add(cfg) { return this.addForm(cfg); };

	//
	addForm(cfg)
	{
		//allow overrides from the config
		if (!cfg.wrapper_class) cfg.wrapper_class = this.#formConfig.wrapper_class;
		if (!cfg.form_class) cfg.form_class = this.#formConfig.form_class;
		if (!cfg.label_class) cfg.label_class = this.#formConfig.label_class;
		if (!cfg.type) cfg.type = 'text';
		
		//add a form name if one isn't specified
		//if (!cfg.name) cfg.name = cfg.type + '-' + WDUtil.uuid();

		//use a placeholder to hold the spot for this form while we load the module
		const placeholder = wdc('<div/>');
		this.#form.append(placeholder);

		return this.buildFormObject(cfg).then( f => {

			if (cfg.label) this.addFormLabel(f,cfg);
			if (cfg.prefix && f.prefix) f.prefix(cfg.prefix);
			if (cfg.suffix && f.suffix) f.suffix(cfg.suffix);

			f.classList.add('wd-form-item',...cfg.wrapper_class.split(' '),`wd-form-${cfg.name}`);
			f.dataset.formName = cfg.name;

			placeholder.before(f);
			placeholder.remove();
			
			
			
			return f;
		});
		
	};

	/**
		*/
	addFormLabel(f,cfg)
	{
			f.classList.add('align-items-center');
			f.inputGroup().classList.add(cfg.form_class);

			let lbl = wdc('<label/>');
			lbl.classList.add('form-label',cfg.label_class);
			lbl.append(cfg.label);
			f.prepend(lbl);

			if (cfg.label_info)
			{
				let infoBtn = new WDButton().icon('fa-solid fa-circle-info').classList.add('btn-sm','btn-clear');
				infoBtn.on('click',() => new WDActivity().showMessage(cfg.label_info) );
				lbl.append(infoBtn);
			}
	};

	async loadModule(uri,className) {
		const module = await import(uri);
		return module[className];
  };

	/**
		need to be able to handle multiple forms - regardless of input, select, whatever.
		- checkboxes solution may not cover it?
		- how do we handle layered data ?	addresses[0][id] - I want to be able to do that
		*/
	buildFormObject(cfg)
	{
		//may need to figure some way to configure which file we are loading from?  WDConfig variable or something
		const uri = cfg?.importURI ? cfg.importURI : './wd.js';
		const fn = `WDForm${cfg.type.toLowerCase().replace('-','').capitalize()}`;

		return new Promise( (resolve,reject) => {
		
			import(uri).then( module => {

				try {
					
					const f = new module[fn](cfg);
					f.on('change',(evt) => this.trigger('form:change',f) );
					f.on('input',(evt) => this.trigger('form:input',f) );
					f.on('value:set',(evt) => this.trigger('value:set',f) );

					//append to the dom and store a ref for later
					this.append(f);			//we have to do this here even though repositioned by View function later
					let formKey = f.name();
				
					resolve(f);
				}
				catch (err)
				{
					console.log('Error initializing form',fn,err);
					reject(false);
				}
			});

		});
	};

	/**
		- when passed data - need to set all the forms values
		- also when populating forms one at a time
		- need to serialize data in structure that matches form structure
	*/

	data(key,val)
	{
		if (typeof(val) == 'undefined')
		{
			return this.get(key).val();
		}
		else
		{
			this.get(key).val(val);
			return this;
		}
	};

	dirty(d)
	{
		if (typeof(d) != 'undefined')
		{
			const update = (d != this.#dirty);
			this.#dirty = d;
			
			if (update == true) this.trigger('dirty:updated');
			return this;
		}
		else
		{
			return this.#dirty;
		}
	};

	get(key)
	{
		return this.querySelector(`[data-form-name="${key}"]`);
	};

	getInput(key)
	{
		return this.get(key).input();
	};

	toFormNotation(obj, prefix, result) {

		result = result || {};

		// iterate over all properties
		for (let prop in obj) 
		{
				if (obj.hasOwnProperty(prop)) 
				{
						let value = obj[prop];

						// build the property name for the result object
						// first level is without square brackets
						let name = prefix ? prefix + '[' + prop + ']' : prop;
		
						let fm = this.get(name);
						let stopDescent = false;
						
						if (fm && typeof(fm) != 'function')
						{
							//these form types expect objects, so don't descent any further down
							let objForms = ['button','picker','textpicker'];
							stopDescent = objForms.indexOf(fm.type() != -1);
						}
						
						if (typeof value !== 'object' || stopDescent) 
						{
								// not an object, add value to final result
								result[name] = value;
						}
						else 
						{
								// object, go deeper
								this.toFormNotation(value, name, result);
						}
				}
		}

		return result;
	}

	toObjectNotation(formData)
	{
		Object.keys(formData).forEach(k => {

			const path = k.replace(/\[/g, '.').replace(/\]/g, '').split('.');
			const last = path.pop();
			const isNumber = parseInt(last) == last;		//"last" is the key.  If it's a number, we make an array instead of object

			if (path.length) 
			{
				path.reduce((o, p) => o[p] = o[p] || ( isNumber ? [] : {} ) , formData)[last] = formData[k];
				delete formData[k];
			}
		});

		return formData;
	};

	/**
		data getter/setter.	If passed values, sets the
		data object and updates all the forms
		*/
	val(values) {

		//get the data
		if (typeof(values) == 'undefined')
		{
			let data = {};

			const elemTypes = ['element','divider','header'];

			//loop through our items and see if they exist in the loaded object
			this.items().forEach( item => {
				if (elemTypes.indexOf( item.type() ) == -1) data[ item.name() ] = item.val();
			});
		
			return this.toObjectNotation(data);
		}
		else
		{
			//convert values to form notation
			const formData = this.toFormNotation(values);

			//populate the fields
			Object.keys(formData).forEach( key => {

				const value = formData[key];

				//this would happen if working w/ a WDProto object
				if (typeof(value) != 'function')
				{
					this.get(key)?.val(value);
				}
				
			});
			
			return this;
		}
		
	};

	readonly(ro)
	{
		this.items().forEach( item => {
			if (item?.readonly) item.readonly(ro);
		});
	};

	validate()
	{
		let customValid = true;
		
		//loop through all forms.  Run any custom validation methods (for non-standard html elements)
		this.items().forEach( item => {
		
			if ( typeof(item.validate) != 'undefined') 
			{
				const v = item.validate();
				if (v === false) customValid = false;
			}
		
		});		
	
		const valid = this.#form.checkValidity();
		this.classList.add('was-validated');

		return (valid === true && customValid === true);
	};

	reset()
	{
		this.#dirty = false;
		this.empty();
	};

	populate(data) { return this.val(data); };
	serialize() { return this.val(); };
	items() { return this.querySelectorAll('.wd-form-item'); };
	
};

customElements.define('wd-form',WDForm);
