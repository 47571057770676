
/**
  creates an observable object from the passed one, or
  creates a new observable object if one isn't passed.
  
  allows for adding data formatters to automatically
  return a formatted value for a specific property
  
  also works with arrays passed to the constructor
  */

import WDUtil from './wd-util';

export class WDProxy {

  __proxy;																						//proxies dont work with private vars
  __subscribers = { get:{}, set:{}, delete:{} };			//can monitor get or set, but "get" is currently disabled
  __isRecursive = true;
  __maxNestLevel = 10;
  __nestLevel = 0;
     
  constructor(obj)
  {
    if (typeof(obj) == 'undefined') obj = {};
    
    this.__proxy = new Proxy(obj,this);
    return this.__proxy;
  };  

  /**
    * If set, automatically converts objects and arrays below this one into proxies too.
    * Ususally used for forms because of nested objects.
    */
  setRecursive(val) { 
    this.__proxy.__isRecursive = val; 
  };

  isRecursive()
  {
    return this.__isRecursive;
  };

  isProxy()
  {
    return true;
  }

  nestProxy(target,prop)
  {
    const objClass = target[prop].constructor.name;

    //convert nested objects to proxies so we can watch for changes and bubble the events to the main proxy
    if (objClass == 'Object' || objClass == 'Array')
    {
        target[prop] = target[prop] instanceof Array ? new WDProxyArray(target[prop]) : new WDProxy(target[prop]);

        //bubble events to the top
        target[prop].on('set', (evt) => {
        
          const childKey = prop + '.' + evt.property;
          const childVal = evt.value;
          
          this._notify('set', childKey, { property:childKey, value:childVal });
        });

        //bubble events to the top
        target[prop].on('delete', (evt) => {
          const childKey = prop + '.' + evt.property;
          this._notify('delete', childKey, { property:childKey });
        });
    }
  
  };

  get(target,prop,receiver)
  {
    if ( typeof(target[prop]) != 'undefined' )
    {
      //we have an object that's not a proxy.  turn it into one
      if (this.isRecursive() && target[prop] !== null && typeof(target[prop]) == 'object' && !target[prop]?.isProxy)
      {
        this.nestProxy(target,prop);
      }
      
      return Reflect.get(target, prop, receiver);
      
      //notify our watchers - let's disable this for now for speed, not sure it will ever be needed
      //this._notify('get',prop, { property:prop, value:target[prop] });
      //return retVal;
    }
    //own class reference?
    else if ( typeof(this[prop]) != 'undefined')
    {
      return this[prop];
    }
    else
    {
      return undefined;
    }
  };
  
  set(target,prop,receiver)
  {
    //don't set the previous value now, I feel like it's going to add overhead
    //we could always inherit proxy with WDProxyHistory here all it does is append the history
    //same with the formatting stuff
    
    //const prevVal = target[prop];
    const retVal = Reflect.set(target, prop, receiver);

    this._notify('set',prop, { property:prop, value:target[prop] }); //, prev_value:prevVal });
    
    return retVal;
  };

  deleteProperty(target, prop) 
  { 
    // to intercept property deletion
    if (prop.startsWith('__')) 
    {
      throw new Error("Access denied");
    } 
    else 
    {
      delete target[prop];
      this._notify('delete',prop, { property:prop }); //, prev_value:prevVal });

      return true;
    }
  };
  
  ownKeys(target) { // to intercept property list
    return Object.keys(target).filter(key => !key.startsWith('__'));
  }

  /**
    internal methods for the object
    */
  on(mode,handler)
  {
    this.onProp(mode,'__all',handler);
  };

  onProp(mode,prop,handler)
  {
    const subscribers = this.__subscribers[mode];
    
	  //make sure we have a subscriber element for this property
	  if (typeof(subscribers[prop]) == 'undefined') subscribers[prop] = [];

    //add the subscriber to the watcher for this property	  
    subscribers[prop].push(handler);
  };

  off(mode,handler)
  {
    this.offProp(mode,'__all',handler);
  };
  
  offProp(mode,prop,handler)
  {
    const subscribers = this.__subscribers[mode];

    if (typeof(handler) == 'undefined') 
    {
      delete( subscribers[prop] );
    }
    else
    {
      const idx = subscribers[prop].indexOf(handler);
      subscribers[prop].splice(idx,1);
    }
  };

  toObject()
  {
    const ret = {};
    
    Object.keys( this.__proxy ).forEach( key => {
    
      const val = this.__proxy[key];
      
      if (val === null)
      {
        ret[key] = null;
      }
      else if (typeof(val) == 'object')
      {
        if (val?.isProxy)
        {
          ret[key] = val instanceof Array ? val.toArray() : val.toObject();
        }
        else
        {
          ret[key] = (val instanceof Array) ? Object.assign([],val) : Object.assign({},val);
        }
      }
      else
      {
        ret[key] = val;
      }

    });

	  return ret;

  };

  assign(objData)
  {
    Object.assign(this,objData);
    return this;
  };

  merge(objData)
  {
    WDUtil.merge(this,objData);
    return this;
  };

  replace(objData)
  {
    const keepKeys = Object.keys( objData );

    //remove any keys not in the object we are replacing with
    Object.keys( this.__proxy ).forEach( key => {
      if (keepKeys.indexOf(key) == -1) delete( this.__proxy[key] );
    });

    //copy in the new data
    Object.assign(this,objData);
  };

  empty()
  {
    this.replace({});
  };

  _notify(mode,prop,evtData)
  {
    this._notifyProp(mode,prop,evtData);
    this._notifyProp(mode,'__all',evtData);
  };
  
  _notifyProp(mode,prop,evtData)
  {
    const subscribers = this.__subscribers[mode];

    if (typeof(subscribers[prop]?.forEach) != 'undefined')
    {
      //evtData.proxy = this;	//this is probably a bad idea - makes it possible to create infinite loops
      subscribers[prop].forEach( handler => {
        if (typeof(handler) == 'function') handler(evtData);
      });
    }
  };

};


/**
  creates an observable object from the passed one, or
  creates a new observable object if one isn't passed.
  
  allows for adding data formatters to automatically
  return a formatted value for a specific property
  
  also works with arrays passed to the constructor
  */

export class WDProxyArray extends WDProxy {

  constructor(arr)
  {
    if (!arr) arr = [];
    return super(arr);
  };  

  /**
    replace all members of this array with new one
    */
  replace(newArr)
  {
    while (this.length > 0) this.pop();

    newArr.forEach( val => {
      this.push(val);
    });
    
    //this.length = 0;				//clear this array    
    //this.push(...newArr);		//push new array in (are there limits to size here?)
  };

  ownKeys(target){

    return Reflect.ownKeys(target).filter(key => {
      const numKey = +key;
      return Number.isNaN(numKey) || numKey >= 0;
    });
    
  };
  
  toArray()
  {
    const output = [];
    
    this.forEach( val => {

      if (typeof(val) == 'object')
      {
        if (val?.isProxy)
        {
          const newVal = val instanceof Array ? val.toArray() : val.toObject();
          output.push(newVal);
        }
        else
        {
          const newVal = Object.assign({},val);
          delete(newVal.__isRecursive);						//bugfix, only happens when creating a proxyObj from a proxy array
          
          output.push( newVal );
        }
      }
      else
      {
        output.push(val);
      }
    
    });

    return output;  
  };

  /**
    * because this really an object in disguise, we have to convert to an array
    * and reorder, then replace.
    */
  reorder(from,to)
  {
    const arr = this.toArray();
    arr.splice(to, 0, arr.splice(from, 1)[0]);
   
    this.replace(arr);
  };

  remove(idx)
  {
    this.splice(idx,1);
  };

  insert(idx,item)
  {
    this.splice(idx,0,item);
  };
};

