/**
	*	stores the results of a WDFetch in a proxy and allows for direct updating
	* with the remote uri
	*
	*	@class WDFetchObject
	*/

import WDFetch from './wd-fetch';

export default class WDFetchObject extends WDFetch {

	req(method,uri)
	{
		return super.req(method,uri).then( results => {

							//merge our results with our local object
							this.assign(results);

							//update our base uri with the one in the result
							if (results.uri) 
							{
								this.__config.baseURI = results.uri;
							}
							else 
							{
								//this is really only a problem if we're saving a new item and expect the real
								//uri back from the api
								console.log('Warning!  No uri returned for WDFetchObject.  Using original uri');
							}
						});
	};

};

