
import WDModal from './wd-modal';

export default class WDActivity extends WDModal {

	constructor(opts)
	{
		if (!opts) opts = {};
		if (!opts.title) opts.title = 'Activity Window';
		
		super(opts);

		this.classList.remove('fade');
		this.body().classList.remove('allow-scroll');
		this.body().classList.add('activity-modal');
		this.hideFooter();

		return this;
	};

	showMessage(msg)
	{
		this.body().replaceChildren();

		let cont = wdc('<p class="activity-message"/>').append(msg);
		this.body().append(cont);
		this.showFooter();
		this.show();

		return this;
	};

	showButtons(msg,buttons)
	{
		this.body().replaceChildren();

		let cont = wdc('<p class="activity-message"/>').append(msg);
		this.body().append(cont);
		this.showFooter();

		this.footer().empty();

		buttons.forEach(btn => {
			btn.on('click',() => this.hide() );
			this.footer().append(btn);
		});
		
		this.show();

		return this;
	};

	showConfirm(msg,handler)
	{
		this.body().replaceChildren();

		let cont = wdc('<p class="activity-message"/>');
		cont.append(msg);
		this.body().append(cont);

		let btn = this.addButton("Okay").theme('danger')
		btn.on("click",(evt) => {

			if (handler) handler();
			this.hide();

		});

		this.showFooter();
		this.show();

		return this;
	};

	showDelete(handler)
	{
    let msg = 'Are you sure you want to delete this item?';
    new WDActivity().showConfirm(msg,handler);
  };

	showSuccess(msg)
	{
		this.body().replaceChildren();

		let cont = wdc('<p class="activity-message activity-success"/>').append(msg);
		this.body().append(cont);

		this.show();
		this.showFooter();

		return this;
	};

	//this shows an error message and allows the activity window to be hidden 
	//at the user's command
	showError(err)
	{
		this.body().replaceChildren();

		let cont = wdc('<p class="activity-message activity-error"/>').append(err);
		this.body().append(cont);

		this.showFooter();
		this.show();

		return this;
	};

	showErrorCode(err)
	{
		this.body().replaceChildren();

		let cont = wdc('<pre class="activity-error-code"/>').append(err);
		this.body().append(cont);

		this.show();

		return this;
	};

	//this shows an error message and allows the activity window to be hidden 
	//at the user's command
	showJSONError(err)
	{
		this.body().replaceChildren();

		//build the base error message
		let cont = wdc('<p class="activity-message activity-error"/>').append(err.message || err.error);
		this.body().append(cont);

		//admins, show more information
		if (WDAuth.can('Admin'))
		{
			if (err.line && err.file)
			{
				let msg = wdc('<p class="activity-error activity-message"/>').text("Error on Line " + err.line + " in " + err.file);
				this.body().append(msg);
			}
			
			if (err.backtrace)
			{
				let detail = wdc('<div class="activity-error-detail"/>');
				let label = wdc('<label/>').text('Backtrace');
				let msg = wdc('<pre/>').text(err.backtrace); //UTIL.dump(err.backtrace));
				detail.append(label).append(msg);

				this.body().append(detail);
			}
			
		}

		this.show();

		return this;
	};

};

customElements.define('wd-activity',WDActivity);