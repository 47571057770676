
export default class WDError extends Error {

	#debugObj;
	
	/**
		params can contain
		- code
		- details
		- name (override error name)
		*/
	constructor(message,params) 
	{
		super(message);

		if (typeof(params) != 'undefined')
		{
			Object.assign(this,params);
		}
		
		//if we have a code, map the name to known codes 
		if (typeof(this.code) != 'undefined') this.mapCodeName();

	};

	mapCodeName()
	{
		const map = { '401': 'AuthError',
									'404': 'NotFound'
								};

		if (typeof(map[this.code]) != 'undefined') this.name = map[this.code];
	};
	
};

