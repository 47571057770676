
Number.prototype.formatDecimals = function(decimals)
{
	return this.toFixed(decimals);
}

Number.prototype.formatGroups = function(decimals)
{
	return this.format({decimals:decimals,group:','});
}

Number.prototype.formatPercent = function(decimals)
{
	return String(this).formatPercent(decimals);
}

Number.prototype.formatCurrency = function(decimals)
{
	return String(this).formatCurrency(decimals);
}

Number.prototype.formatSeconds = function(showSeconds)
{
	return String(this).formatSeconds(showSeconds);
}

Number.prototype.formatSize = function()
{
  const bytes = parseInt(this);

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (bytes === 0)
  {
    return '0 Bytes';
  }
  else if (i === 0) 
  {
    return bytes + ' ' + sizes[i];
  }

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

Number.prototype.formatBoolean = function()
{
	return (this == true || this == '1') ? 'Yes' : 'No';
};

