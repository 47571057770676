/**
	WDFetch
	*/

import { WDProxy } from '../wd-proxy'
import WDUtil from '../wd-util';
import WDError from '../wd-error';
import WDFetchError from './wd-fetch-error'

export default class WDFetchUpload extends WDProxy {

	#progressHandler = null;
		
	__config = { 	contentType: 'application/json',
								auth: {},
								baseURL: null,
								baseURI: null,
								baseObject: null,
								allowMultipleRequests: false,
								headers: {},
							}; 
			
	constructor(config, values)
	{
		//if we are passed a base object to work with, use it to init underlying proxy.  otherise start empty
		const initObj = (typeof(config) == 'object' && typeof(config.baseObject) == 'object') ? config.baseObject : {};
		super(initObj);
		
		if (typeof(config) == 'string') 
		{
			this.__config.baseURI = config;
		}
		else if (typeof(config) == 'object')
		{
			WDUtil.merge(this.__config,config);

			//passed a base object with a uri to link to			
			if (!this.__config.baseURI && this.__config.baseObject && this.__config.baseObject.uri)
			{
				this.__config.baseURI = this.__config.baseObject.uri;
			}
		}

		if (values != undefined) this.add(values);
	};

	setProgressHandler(val) { this.#progressHandler = val; };

  responseContentType(response)
  {
    const ct = response.headers.get("content-type");
    const pos = ct.indexOf(';');
    
    return (pos !== -1) ? ct.substr(0,pos) : ct;
  };

	//backwards compatiblity - for now
	add(obj)
	{
		return this.assign(obj);
	};

	getURI(addURI)
	{
		let uri = '';
		
		//given a base uri to work with
		if (this.__config.baseURI) uri += this.__config.baseURI;

		//given a supplemental uri to the base url and base uri
		if (addURI) uri += addURI;

		return uri;
	};

  setBaseURI(uri)
  {
    this.__config.baseURI = uri;
  };

	buildURL(uri)
	{
		let url = '';
		if (this.__config.baseURL) url += this.__config.baseURL;

		const addURI = this.getURI(uri);
		if (addURI) url += addURI;

		return url;
	};

	buildAuth(request)
	{
		if (this.__config.auth)
		{
			const auth = this.__config.auth;
			request.withCredentials = true;
			
			if (auth.mode == 'basic')
			{
				const authStr = 'Basic ' + btoa(auth.login + ':' + auth.password);
				request.setRequestHeader ('Authorization', authStr);
			}
			else if (auth.mode == 'digest')
			{
			}
		}
	};

	getFormData()
	{
		const formData = new FormData();
		formData.append('file',this.file);

		const formObj = this.toObject();
		
		for (let key in formObj)
		{
			if ( typeof(formObj[key]) != 'object' ) formData.append( key, formObj[key] );
		}

		return formData;
	};

	/**
		*
		*/
	buildRequest(method,uri)
	{
		const url = this.buildURL(uri);

		const request = new XMLHttpRequest();
		request.open(method,url);
		request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		for (let key in this.__config.headers)
		{
			request.setRequestHeader(key,this.__config.headers[key]);
		}

		//add authorization		
    this.buildAuth(request);
				
		// upload progress event
    if (method == 'PUT' && this.#progressHandler)
    {
    	request.upload.addEventListener('progress',(evt) => {

    		//build a progress object
    		this.file.loaded = ( this.file.loaded) ? this.file.loaded += evt.loaded : evt.loaded;
    		this.file.percent_loaded = ( parseFloat(this.file.loaded) / parseFloat(this.file.size) ) * 100;
    		
    		this.#progressHandler( {file:this.file, event:evt });
			});
		}
		
		return request;
			
	};

	put(uri)
	{
		return new Promise( (resolve,reject) => {

			//build the request
			const request = this.buildRequest('PUT',uri);
		
    	// upload completed, resolve the promise
    	request.addEventListener('load', (evt) => {

    		this.file.loaded = this.file.size;
    		this.file.percent_loaded = 100;

				const respData = this.parseResponse(request);

				//if there is a message, then the server threw an error
				if (respData?.message)
				{
	    		reject({
	    			response: respData,
	    			file: this.file,
	    			event:evt, 
					});
				}
				else
				{
	    		resolve({
	    			response: respData,
	    			file: this.file,
	    			event:evt, 
					});
				}
			});

	    //error handling
  	  request.addEventListener('error', (evt) => {

    		reject({
    			response: this.parseResponse(request),
    			file: this.file,
    			event:evt, 
				});

			});

			//send the request			
			request.send( this.getFormData() );

		});			
	};

	parseResponse(request)
	{
		let resp = null;
		
		try {
			resp = JSON.parse(request.response);
		}
		catch(err) {
			resp = request.response;
		}

		return resp;
	};

	mkcol(uri)
	{
		return new Promise( (resolve,reject) => {

			//build the request
			const request = this.buildRequest('MKCOL',uri);
		
    	// upload completed, resolve the promise
    	request.addEventListener('load', (evt) => {
    		resolve( this.parseResponse(request) );
			});

	    //error handling
  	  request.addEventListener('error', (evt) => {
    		reject( this.parseResponse(request) );
			});

			//send the request			
			request.send();
		});			
	};


	req(method,uri)
	{
		//cancel any current request
		//if (this.#controller) this.abort();

		//build the request
		const request = this.buildRequest(method,uri);

		//for some error handling later
		let cloneResp = null;

		//run the fetch
		const ftch = fetch(request).then( response => {

			//clone the response for error handling
			cloneResp = response.clone();
			const contentType = this.responseContentType(response);

			if (response.ok)
			{
				//everything came back okay, and it was the right response type
				if (contentType == this.__config.contentType)
				{
					return (contentType == 'application/json') ? response.json() : response.text();
				}
				//usually some sort of server side parse error
				else
				{
					throw new WDFetchError(response.message, {code:response.status, response:response});
				}
			}
			//this is usually a caught error, formatted properly
			else
			{
				throw new WDError(response.message, 
										{	code:response.status, 
											name:'FetchError', 
											contentType: contentType,
											response: cloneResp
										});
			}
		})
		.catch( err => {

			//do nothing on abort errors
			if (err.name == 'AbortError') 
			{
				throw new Error('AbortError');
			}
			//auth errors get special treatment
			else if (err.code == 401)
			{
				throw new Error('Unauthorized',{ code:err.code});
			}
			else if (err.name == 'FetchError')
			{
				const wdClone = cloneResp.clone();

				//once for the console				
				cloneResp.text().then( str => {
					console.log(err.name,str);
				});

				//throw another copy of the error to a catch later in the chain
				throw new WDError(err.message, 
										{	code:err.status, 
											name:err.name,
											contentType: err.contentType,
											response: wdClone
										});

			}
			else if (err.name == 'SyntaxError')
			{
				cloneResp.text().then( str => {
					console.log(err.name,str);
				});
			}
			//flush out the response for more detailed info on what happened
			else
			{
				//throw another copy of the error to a catch later in the chain
				throw new WDError(err.message, 
										{	code:err.status, 
											name:err.name,
											contentType: err.contentType,
											response: err.response
										});
			}
	
		})
		.finally( data => {
			//this.#controller = null;
		});

		return ftch;
		
	};

};

