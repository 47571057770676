
import WDElement from '../wd-element';
import WDUtil from '../../core/wd-util';
import WDButton from '../button/wd-button';
import WDDropdownForm from '../dropdown/wd-dropdown-form';
import { WDFormText } from '../form/wd-form-text';

import './wd-filter-matches';

export default class WDFilter extends WDElement {

	#config = {
		allow_groups:true, 
		fields:[] 
	};
	#join = null;
	#parent = null;

	constructor(config,parent)
	{
		super().classList.add('wd-filter');

		Object.assign(this.#config,config);
		
		if (parent) this.#parent = parent;
		
		this.join();
	};

	add(initData)
	{
		let item = new WDFilterItem(initData,this.#config.fields);
		item.on('filter:removed',(evt) => this.handleFilterRemoved(evt) );
		item.on('filter:updated',(evt) => this.handleFilterUpdated(evt) );
				
		this.append(item);

		return item;
	};

	handleFilterRemoved(evt)
	{
		this.trigger('filters:updated',{filter:this});		
	};

	handleFilterUpdated(evt)
	{
		this.trigger('filters:updated',{filter:this});
	};

	/**
		adds a subfilter group to the main filter
		*/	
	addFilter()
	{
		//this is a subfilter, so change to "or"
		this.#config.join = "or";
		
		let f = new WDFilter(this.#config,this);
		f.on('filters:updated',(evt) => this.handleFilterUpdated(evt) );
		f.on('filters:removed',(evt) => this.handleFilterRemoved(evt) );

		let cont = wdc('<li class="wd-filter-item form-row"/>');
		cont.append(f);
		
		this.append(cont);

		return f;
	};

	val(val)
	{
		if (typeof(val) == 'undefined')
		{
			return this.getVal();
		}
		else
		{
			//make sure we are empty and starting over
			this.setVal(val);
			return this;
		}
	};

	getVal()
	{
		//init our return object w/ the join value and a filters placeholder
		let obj = {
			join:this.#join.val(), 
			filters:[]
		};

		this.children.forEach( item => {
			obj.filters.push(item.val())
		});

		return obj;		
	
	};

	setVal(data)
	{
		//top level of data should have a filters and an join key
		if (data.join) this.join().val(data.join);

		data.filters.forEach( filter => {

			//if filter has "filters" and "joins" keys, then it's a subfilter
			if (typeof(filter.filters) != 'undefined' && typeof(filter.join) != 'undefined')
			{
				let f = this.addFilter();
				f.val(filter);
			}
			//otherwise it's a regular filter item
			else
			{
				this.add(filter);
			}
		
		});
	
	};

	/**
		this is the control toolbar for the filter group
		*/
	join()
	{
		if (!this.#join)
		{
			const row = wdc('<li class="wd-filter-item"/>');

			row.append(wdc('<div/>').text('Match'));
			this.append(row);

			//get our join
			const opData = (typeof(this.#config.join) != 'undefined') ? this.#config.join : 'and';

			const cfg = { allow_clear: false, name:'join', options:[{label:'All',value:'and'},{label:'Any',value:'or'}], value:opData };
			this.#join = new WDDropdownForm(cfg);
			row.append(this.#join);
			
			//add new filter to the list
			const btn = new WDButton({label:'Add Filter',icon:'fa-solid fa-plus',theme:'primary'});
			btn.on('click',() => this.add() );
			row.append(btn);
			
			//add new filter group
			if (this.#config.allow_groups)
			{
				const btn = new WDButton({label:'Add Filter Group',theme:'primary',icon:'fa-solid fa-object-group'});
				btn.on('click',() => this.addFilter() );
				row.append(btn);
			}
			
			//remove button
			if (this.#parent)
			{
				const btn = new WDButton({theme:'clear',icon:'fa-regular fa-trash-alt'}).classList.add('ms-auto');
				btn.on('click',() => {
					this.remove();
					this.trigger('filters:removed'); 
				});

				row.append(btn);
			}
		}
		
		return this.#join;
		
	};

	handleFilterGroupRemoved(evt,obj)
	{
	};

	reset()
	{
		this.empty();
		this.#join = null;

		this.join();
	};

};

class WDFilterItem extends WDElement {

	#field = null;
	#match = null;
	#value = null;
	#removeBtn = null;
	#initData = null;
	#fields = null;
	
	constructor(initData,filterFields)
	{
		super().classList.add('wd-filter-item','form-row');

		this.#initData = initData;
		this.#fields = filterFields;

		this.field();
		this.match();
		this.value();
	};
		
	removeBtn()
	{
		if (this.#removeBtn) this.#removeBtn.remove();

		//remove button
		this.#removeBtn = new WDButton({theme:'clear',icon:'fa-regular fa-trash-alt'}).classList.add('ms-auto');
		this.#removeBtn.on('click',(evt) => {

			this.trigger('filter:removed',{filter:this});

			//remove ourselves from the dom
			this.remove();		

		});

		this.append(this.#removeBtn);
	};

	val(val)
	{
		if (typeof(val) == 'undefined')
		{
			return this.getVal();
		}
		else
		{
			this.setVal(val);
			return this;
		}
	};

	getVal()
	{
		return { field:this.#field.val(), match:this.#match.val(), value:this.#value.val() };
	};
	
	setVal(data)
	{
		if (data?.field !== undefined) this.#field.val(data.field);
		if (data?.match !== undefined) this.#match.val(data.match);
		if (data?.value !== undefined) this.#value.val(data.value);
	};

	field()
	{
		if (!this.#field)
		{
			//if no fields are defined, used a basic text field
			this.#field = (this.#fields.length > 0) ? this.buildDropdownField() : this.buildTextField();

			//reset the value field when this is changed
			this.#field.on('change', (evt) => {
				this.match(true);
				this.value(true); 
			});
	
			this.append(this.#field);
		}
		
		return this.#field;
	};

	buildTextField()
	{
		//gotta figure out customData fields to add above, set "some" value when loading
		const cfg = { name:'field' };
	
		//value to start with
		cfg.value = (typeof(this.#initData) != 'undefined' && typeof(this.#initData.field) != 'undefined') ? this.#initData.field : '';

		//build the field dropdown
		return new WDFormText(cfg);
	};
	
	buildDropdownField()
	{
		//some of our other field sources get messy due to unrelated info.	This cleans it up
		const fields = [];

		this.#fields.forEach( (field) => {
			//allow field.name also, just because this is so close to form definitions
			fields.push({ label:field.label, value:field.name });
		});
	
		//gotta figure out customData fields to add above, set "some" value when loading
		const cfg = { options:fields };
	
		//value to start with
		cfg.value = (typeof(this.#initData) != 'undefined' && typeof(this.#initData.field) != 'undefined') ? this.#initData.field : fields[0].value;

		//build the field dropdown
		return new WDDropdownForm(cfg);
	};

	/**
		*/
	match(reset)
	{
		if (!this.#match || reset)
		{
			if (this.#match) this.#match.remove();

			//get our config based on our selected field			
			const formConfig = this.getFormConfig();

			//if passed match options, use them, otherwise fetch our basic ones based on the form type
			const matchOpts = (typeof(formConfig.matches) != 'undefined') ? formConfig.matches : WDFilterMatches[formConfig.type];
			const matches = Object.assign([],matchOpts);

			//build the config
			const cfg = { options:matches, allow_clear: false };

			//value to start with
			cfg.value = (typeof(this.#initData) != 'undefined' && typeof(this.#initData.match) != 'undefined') ? this.#initData.match : matches[0].value;

			this.#match = new WDDropdownForm(cfg);
			this.#match.on('change',(evt) => {
					this.trigger('filter:updated',{filter:this}); 
				});

			this.append(this.#match);
		}
		
		return this.#match;
	};

	/**
		*/	
	value(reset)
	{
		if (!this.#value || reset)
		{
			if (this.#value) this.#value.remove();
	
			//get the selected item for field so we can get the form field handler
			const formConfig = this.getFormConfig();

			//value to start with
			formConfig.value = (typeof(this.#initData) != 'undefined' && typeof(this.#initData.value) != 'undefined') ? this.#initData.value : null;

			//figure out which form we will use based on passed type			
			const method = 'WDForm' + String(formConfig.type).capitalize();

			const uri = `../form/wd-form-${cfg.type.toLowercase()}`;
			const fn = `WDForm${cfg.type.toLowerCase().replace('-','').capitalize()}`;

			import(uri).then( module => {
			
				this.#value = new module[fn](formConfig);
				this.append(this.#value);
	
				//do we want to watch for change events here?
				this.#value.on('change',(evt) => {
					this.trigger('filter:updated',{filter:this}); 
				});

			});
			this.removeBtn();
		}
		
		return this.#value;

	};

	/**
		gets full config for a form based on it's name, usually retrieved from the field dropdown current value
		*/
	getFormConfig()
	{
		const formName = this.#field.val();
		let formConfig = { type:'text' };

		if (this.#fields.length > 0)
		{		
			this.#fields.forEach( (field) => {

				if (field.name == formName)
				{
					formConfig = field;
					return false;
				}
			});
		}

		return WDUtil.merge({},formConfig);
	};

};

customElements.define('wd-filter',WDFilter, { extends:'ul' });
customElements.define('wd-filter-item',WDFilterItem, { extends:'li' } );
