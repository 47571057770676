
import { WDFormInput } from './wd-form-input';

export class WDFormNumber extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'number';
    super(cfg);
  };
};

customElements.define('wd-form-number',WDFormNumber);

