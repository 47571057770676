
import { WDSiteNavbarMenu } from './wd-site-navigation';
import WDApi from '../core/fetch/wd-api.js';

export default class WDSiteMenu extends WDSiteNavbarMenu {

	#sitesURI = null;
	#sites = [];
	#site = null;
	#siteMenu;
	#topItem;

	constructor()
	{
		super();
		globalThis.WDSites = this;

		this.#sitesURI = WDConfig?.siteSwitcher?.uri || '/sites';
		
		this.once('site:ready',() => {
			this.on('site:changed',() => {
				location.href = location.href;
			});
		});
	};
	
	connectedCallback()
	{
		const auth = document.querySelector('wd-site-auth');
		if (auth)
		{
	    auth.on('auth:authorized',() => this.loadSites() );
	    auth.on('auth:logout',() => this.reset() );
		}
	};
	
	loadSites()
	{
		if (sessionStorage.sites) 
		{
			this.#sites = JSON.parse(sessionStorage.sites);
			setTimeout( () => this.init(), 1);
		}
		else
		{
			const f = new WDApi(this.#sitesURI);
			f.req().then( data => {
				this.#sites = data;
				sessionStorage.sites = JSON.stringify(data);
				this.init();				
			});
		}
	};

	init()
	{
		this.setSiteById( this.getDefaultSiteId() );
		this.buildMenu();
		this.trigger('site:ready');
	};
	
	buildMenu()
	{
		if (this.#topItem) this.#topItem.remove();
		
		this.#topItem = this.add();
		this.updateTopItem();
    
		this.#sites.forEach( site => {
			const locItem = this.#topItem.submenu().add({label:site.label});
			locItem.addEventListener('click',() => this.setSiteById(site.value) );
		});

	};

	updateTopItem()
	{
		if (!this.#site) return;
		
		const icon = this.#site?.icon ? this.#site.icon : 'fa-solid fa-sitemap';
		this.#topItem.icon(icon).label( this.#site.label );
	};
	
	setSiteById(siteId)
	{
		this.#site = this.#sites.find( s => s.id == siteId);

		if (this.#topItem) this.updateTopItem();

		sessionStorage.siteId = siteId;
		
		this.trigger('site:changed',{site:this.#site});
		
		return this.#site;
	};
	
	getDefaultSiteId()
	{
		let siteId = -1;

		if (sessionStorage.siteId && sessionStorage.siteId != '-1') 
		{
			siteId = sessionStorage.siteId;
		}
		else if (typeof(WDAuth.account()) != 'undefined' && WDAuth.account().default_site_id) 
		{
			siteId = WDAuth.account().default_site_id;
		}
		else 
		{
			siteId = this.options()[0].id;		
		}

		//make sure the user has access to this site.  If not, just use the first site available
		const sid = this.#sites.find( s => s.id == siteId);
		if (!sid && this.#sites.length > 0) siteId = this.#sites[0].id;

		return siteId;
	};

	//not sure we need this method
	reset()
	{
		this.remove();
		return;
		
		this.#sites = [];
		this.#site = null;
		sessionStorage.sites = null;
		sessionStorage.siteId = null;

		//once the sites are reloaded and we set a value, rebuild the menu
		this.one('site:changed',() => this.buildMenu() );		

		this.loadSites();
	};

	site() { return this.#site; };
	get() { return this.#site; };
	getId() { return this.#site.id; };
	uri() { return this.#site.uri; };
	sites() { return this.#sites; };
	getAll() { return this.#sites; };
	set(siteId) { return this.setSiteById(site); };

};

customElements.define('wd-site-menu',WDSiteMenu);
