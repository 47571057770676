/**
	WDFetchArray
	- what would this do?  Create a fetch and just store all the results?  What are we accessing,
		the results or the fetch object itself?  wouldn't be able to subclass this either.  May just
		be easier to assign the results of WDFetch into a proxy array
		
		other possibilities
		- can always access the fetch from within this, teh results are part of the core object
		- the results are an array of WDFetchObjects that can be worked with
		- parameters are the same as WDFetch, but we create a fetch object that can be accessed
		- directly as needed.  
	*/

import { WDProxyArray } from '../wd-proxy';

export default class WDFetchArray extends WDProxyArray {

	#fetch;

	constructor(config)
	{
		super();

		this.#fetch = new WDFetch(config);
	};

	fetch()
	{
		return this.#fetch;
	};

	req(method)
	{
		if (!method) mode = 'GET';
		
		return this.#fetch.req(method).then( results => {
			if (results instanceof Array) this.replace(results);
		});
	};

};

