
import WDPane from '../pane/wd-pane';

export default class WDPaneCards extends WDPane {

	#options = {
		navigation_mode:'tabs',
		tab_position: 'right',
		collapsible:true,
		breakpoint:'md'
	};
	#cards = [];

	constructor(opts)
	{
		super(opts).classList.add('wd-pane-cards');
		
		if (opts) Object.assign(this.#options,opts);

		if (this.#options.collapsible) 
		{
			this.navbar()[this.#options.tab_position]().collapsible({mode:'menu',breakpoint:this.#options.breakpoint});
      this.navbar()[this.#options.tab_position]().toggleButton().fasIcon('ellipsis-v');
		}
	};
	
	reset()
	{
		//empty the body and the menubar
		this.body().empty();

		const menuFunc = this.#options.tab_position;
    const menu = this.navbar()[menuFunc]();
    menu.empty();

		this.#cards = [];
	};
		
	addCard(card,labelName,icon)
	{
		this.body().append(card);
		card.hide();
		
		let shortcode = 'wd-card-' + labelName.toLowerCase().replace(' ','');
		card.classList.add(shortcode);

		//build our storage object
		let obj = { card: card, shortcode: shortcode, label: labelName, icon:icon };
		this.#cards.push(obj);

		this.addCardNav(obj);

		//if this is the first card, show it
		if (this.#cards.length == 1) this.showCard(shortcode);
	};

	showCard(shortcode)
	{
		this.updateCardNav(shortcode);

		this.#cards.forEach( cardObj => {
		
			if (cardObj.shortcode == shortcode) 
			{
				cardObj.card.trigger('card:show');
				cardObj.card.show('flex');
				cardObj.card.trigger('card:shown');
								
				if (this.#options.collapsible == true)
				{
					this.navbar()[this.#options.tab_position]().toggleButton().text(cardObj.label);
					if (cardObj.icon) this.navbar()[this.#options.tab_position]().toggleButton().icon(cardObj.icon);
				}
			}
			else 
			{
				cardObj.card.trigger('card:hide');
				cardObj.card.hide();
				cardObj.card.trigger('card:hidden');
			}
			
		});

	};

	addCardNav(item)
	{
		const menuFunc = this.#options.tab_position;
		const menu = this.navbar()[menuFunc]();

		const btn = menu.add(item.label).attr('data-label',item.shortcode);
		btn.on('click',(evt) => this.showCard(item.shortcode) );		

		if (item.icon) btn.icon(item.icon);
	};
	
	updateCardNav(shortcode)
	{
		const menuFunc = this.#options.tab_position;
		const menu = this.navbar()[menuFunc]();
		
		const selBtn = menu.first('.is-active');
		const scBtn = menu.first('[data-label="' + shortcode + '"]');
		
		if (selBtn != null) selBtn.classList.remove('is-active');
		scBtn.classList.add('is-active');

		//this.navbar().hideMenu();
	};

};

customElements.define('wd-pane-cards',WDPaneCards);
