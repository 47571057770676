

/**
	string prototypes
	*/

String.prototype.ltrim = function() {
	return this.replace(/^\s+/,"");
};

String.prototype.rtrim = function() {
	return this.replace(/\s+$/,"");
};

String.prototype.reverse = function()
{	
	return this.split("").reverse().join("");
};

String.prototype.pad = function(len,padstr,mode)
{	

	if (typeof(mode)=="undefined") mode = "back";
	if (typeof(padstr)=="undefined") padstr = " ";

	var str = String.from(this);

	if (mode=="front") {

		var diff = parseInt(len) - str.length;
		for (var i=0;i<diff;i++) str = padstr + str;

	} else {

		var diff = parseInt(len) - str.length;
		for (var i=0;i<diff;i++) str += padstr;
	}

	return str;

};

String.prototype.isNumeric = function()
{
	const number = parseFloat(this);
	return !isNaN(number) && isFinite(number);
};

String.prototype.isDate = function()
{
	//return ( ( this.indexOf('/') != -1 || this.indexOf('-') != -1 )  && !isNaN( Date.parse(this) ) );	
	//console.log('isDate', this, Date.parse(this) );
	
	return String(this).length >= 8 && !isNaN( Date.parse(this) );
};


String.prototype.formatNumeric = function(decimals)
{
	if (this.trim().length==0 || this == 'null') return "";

	if (!decimals) decimals = 2;

	var num = parseFloat(this);
	
	if (num) return num.toFixed(decimals);
	else return 0;

};

String.prototype.formatDuration = function()
{
	var seconds = parseInt(this);
	var ret = "";

	var sec = seconds % 60;
	var min = parseInt((seconds / 60)) % 60;
	var hours = parseInt(seconds / 3600);

	if (String(min).length < 2) min = "0" + min;
	if (String(sec).length < 2) sec = "0" + sec;

	if (hours < 1) ret = min + ":" + sec;
	else ret = hours + ":" + min + ":" + sec;

	return ret;

};

String.prototype.formatCurrency = function(decimals)
{
	return this.formatPrice(decimals);
}

String.prototype.formatPrice = function(decimals)
{
	if (this.trim().length==0 || this == 'null') return "$0.00";

	var num = parseFloat(this);

	if (num) 
	{
		var data = num.toFixed(decimals);
		var abv = Math.abs(data);

		if (data < 0) return "($" + abv + ")";
		else return "$" + abv;
	}
	else
	{
		return "$0.00";
	}
	
};

String.prototype.formatPercent = function(decimals)
{
	if (this.trim().length==0 || this=="infinity" || this == 'null') return "0%";

	if (!decimals) decimals = "0";

	var num = parseFloat(this);

	if (num)
	{
		var data = parseFloat(this).toFixed(decimals);
		var abv = Math.abs(data);

		if (data < 0) return "(" + abv + "%)";
		else return abv + "%";
	}
	else
	{
		return "0%";
	}

};

String.prototype.formatSize = function()
{
	return Number(this).formatSize();
};

String.prototype.formatPhone = function()
{

	if (this.trim().length==0 || this == 'null') return "";

	var str = this.replace(/[^0-9]+/g,'');
	
	if (str.length==11)
	{
		str = str.substr(1);
		str = "(" + str.substr(0,3) + ") " + str.substr(3,3) + "-" + str.substr(6);
	}
	else if (str.length==12)
	{
		str = str.substr(1);
		str = "(" + str.substr(1,3) + ") " + str.substr(4,3) + "-" + str.substr(7);
	}
	else if (str.length==10)
	{
		str = "(" + str.substr(0,3) + ") " + str.substr(3,3) + "-" + str.substr(6);
	}
	else if (str.length==7)
	{
		str = str.substr(0,3) + "-" + str.substr(3);
	}

	return str;

};

String.prototype.processPhone = function()
{
	var str = this.replace(/[^0-9]+/g,'');
	
	if (str.length == '10') str = '+1' + str;
	else if (str.length == '11') str = '+' + str;
	
	return str;
};

String.prototype.parseDate = function()
{
	//if we are passed a date string only, we need to adjust for time zones
	if (this.indexOf(' ')==-1 && this.indexOf('T')==-1) var d = new Date(this).localDate();
	else var d = new Date(this);

	return d;
};

String.prototype.formatDate = function()
{
	if (this.trim().length==0 || this == 'null') return "";
	//console.log(this + ' => ' + this.parseDate());
	return new Date(this).localDate().format('m/d/Y');
};

String.prototype.formatDateTime = function(showSeconds)
{
	if (this.trim().length==0 || this == 'null') return "";
	
	//show seconds only if asked
	const formatStr = (showSeconds) ? 'm/d/Y h:i:s a' : 'm/d/Y h:i a';
	
	return new Date(this).format(formatStr);
};

String.prototype.formatTime = function(str)
{
	if (this.trim().length==0 || this == 'null') return "";
	return this.parseDate().toTimeString(str);
};

String.prototype.formatBoolean = function()
{
	return ( this == true ) ? 'Yes' : 'No';
};

String.prototype.formatLink = function(txt)
{
	if (!txt) txt = this;
	return $('<a/>').attr('href',this).text(txt);
}

String.prototype.capitalize = function()
{
	var prefix = this.substring(0,1).toUpperCase();
	var suffix = this.substring(1);
	return prefix + suffix;
};

String.prototype.parseName = function()
{
	var ret = {};

	var arr = this.trim().split(' ');

	if (arr.length == 1)
	{
		ret.last_name = arr[0].capitalize();
	}
	else if (arr.length == 2)
	{
		ret.first_name = arr[0].capitalize();
		ret.last_name = arr[1].capitalize();
	}
	else if (arr.length > 2)
	{
		ret.first_name = arr[0].capitalize();
		ret.middle_name = arr[1].capitalize();
		ret.last_name = arr[arr.length-1].capitalize();
	}

	return ret;

};

String.prototype.fileExtension = function()
{
	var pos = this.indexOf('.');
	return this.substr(pos+1).toLowerCase();
};

String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.camelCase = function() {
	return this.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

String.prototype.titleCase = function() {

	return this.replace(/(_|-)/g, ' ')
  						.trim()
  						.replace(/\w\S*/g, (str) => {
  							return str.charAt(0).toUpperCase() + str.substring(1)
							})   
							.replace(/([a-z])([A-Z])/g, '$1 $2')
							.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

//  						return this.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

};

String.prototype.parseRange = function()
{
	var output = [];
	var arr = this.split(',');

	arr.forEach( range => {
	
		if (range.indexOf('-') != -1) 
		{
			var ends = range.split('-');

			for (var i=ends[0]; i<= ends[1]; i++)
			{
				output.push( String(i) );
			}

		}
		else
		{
			output.push(range);
		}
	
	});

	return output;

};

String.prototype.formatSeconds = function(showSeconds)
{
	if (this.length == '0' || this == 'null') return null;
	if (typeof(showSeconds) == 'undefined') showSeconds = true;
	
	var strlen = (showSeconds == true) ? 8 : 5;
	
	return new Date(parseInt(this) * 1000).toISOString().substr(11, strlen)
};

String.prototype.interpolate = function(params) {

	const names = Object.keys(params);
	const values = Object.values(params);

	try {		
		return new Function(...names, `return \`${this}\`;`)(...values);
	}
	catch (err)
	{
		console.log('Interpolate Error',err,this);
		return this;
	}
};

String.prototype.getTemplateKeys = function()
{
	const regex = /\${([^}]+)}/g;

	const templateNames = [];
	let match = null;
	
	while ((match = regex.exec( this )) !== null) {
    templateNames.push(match[1]);
	}

	return templateNames;
};
