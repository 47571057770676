
/**
	@class WDPasswordChecker
	@parameters
	- pass1 - first password form
	- pass2 - 2nd password form
	- saveBtn - saveBtn for form
	
	we should really make this event driven this is dumb
	*/

export default class WDPasswordChecker {

	#saveBtn = null;
	#pass1 = null;
	#pass2 = null;
	#pass1Suffix = null;
	#pass2Suffix = null;

	constructor(pass1,pass2,saveBtn)
	{
		this.#saveBtn = saveBtn;
		this.#pass1 = pass1;
		this.#pass2 = pass2;
		this.#pass1Suffix = wdc('<span class="fa-solid fa-circle-info"/>');
		this.#pass2Suffix = wdc('<span class="fa-solid fa-circle-info"/>');

		this.#pass1.suffix().append(this.#pass1Suffix);
		this.#pass2.suffix().append(this.#pass2Suffix);
		
		this.#pass1.addEventListener('keyup',() => this.verify() );
		this.#pass2.addEventListener('keyup',() => this.verify() );

		this.#saveBtn.disabled = true;
	};

	checkStrong(pass)
	{
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		return strongRegex.test(pass);
	};

	checkMedium(pass)
	{
		let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
		return mediumRegex.test(pass);
	};

	checkPasswordStrength(formData)
	{
		let pass = formData.password1;
		let status = '';
		let cn = '';
		let ret = false;
				
		if (pass.length > 0)
		{
			if (this.checkStrong(pass)) 
			{
				status = 'Strong';
				cn = 'success';
				ret = true;
			}
			else if (this.checkMedium(pass))
			{
				status = 'Moderate';
				cn = 'warning';
				ret = true;
			}
			else
			{
				status = 'Weak';
				cn = 'danger';
			}
			
			this.#pass1Suffix.innerText = status;
			this.#pass1Suffix.classList.remove('success','warning','danger','fa-circle-info','fa-solid');
			this.#pass1Suffix.classList.add(cn);
			
		}

		return ret;

	};

	checkPasswordMatch(formData)
	{
		let status;
		let cn;	
		let ret = false;

		if (formData.password1.length > 0 && formData.password2.length > 0)
		{
		
			if (formData.password1	!= formData.password2)
			{
				status = 'Unmatched';
				cn = 'danger';
			}
			else
			{
				status = 'Matches';
				cn = 'success';
				ret = true;
			}
	
			this.#pass2Suffix.innerText = status;
			this.#pass2Suffix.classList.remove('success','warning','danger','fa-circle-info','fa-solid');
			this.#pass2Suffix.classList.add(cn);
		}
				
		return ret;
	};

	verify()
	{
		let formData = {};
		formData.password1 = this.#pass1.val();
		formData.password2 = this.#pass2.val();

		if (this.#pass1.val().length == '0') return;
		
		let ret = false;
		
		let s = this.checkPasswordStrength(formData);
		let m =	this.checkPasswordMatch(formData);

		if (s && m) ret = true;

		if (this.#saveBtn)
		{
			if (ret) this.#saveBtn.disabled = false;
			else this.#saveBtn.disabled = true;
		}
						
		return ret;
			
	};

};
