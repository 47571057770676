
import { WDFormBasic } from './wd-form-basic';
import { WDProxyArray } from '../../core/wd-proxy';

export class WDFormCheckbox extends WDFormBasic {

	#options;
	#datalist;
  #values;
  	
	#config = { 
	  type: 'checkbox',
	  options:[],
    templates: {
      value: '${value}',
      label: '${label}',
    },
	};
	
  constructor(cfg)
  {
    if (!cfg.type) cfg.type = 'checkbox';

    const elem = wdc('<div class="wd-checkbox-wrapper"/>');
    super(elem,cfg);

    this.#config = Object.assign({},cfg);

    //setup our base proxy for our option storage
    this.#options = new WDProxyArray([]);
    this.#values = new WDProxyArray([]);

    //setup an event watcher for proxy set if we have a content handler
    this.#options.on('set', evtData => this.handleSet(evtData) );
    this.#options.on('delete',evtData => this.handleDelete(evtData) );

    this.#values.on('set', evtData => this.handleValueSet(evtData) );
    this.#values.on('delete', evtData => this.handleValueDelete(evtData) );

    //passed some options, add to our proxy array
    if ( this.#config.options.length > 0)
    {
    	this.#options.replace(this.#config.options);
		}

		//if passed a fetch
    if (this.#config.fetch) this.fetchOptions();
    else setTimeout( () => this.trigger('options:set'), 1);
				
		//disable the button if readonly
		if (this.config()?.readonly) this.readonly(this.config().readonly);

		if (this.#config?.value !== undefined) this.val(this.#config.value);
	};

	options() { return this.#options; };	

  fetchOptions()
  {
    //make sure we have a request uri set
    if ( this.#config.fetch.getURI().includes('${') )
    {
      return new Promise( (resolve,reject) => {
        this.setOptions([]);
        resolve(true);
      });

    }
    else
    {
      return this.#config.fetch.req().then( results => {
        if (results instanceof Array) this.setOptions(results);
      });
    }
  };
    		
  setOptions(newOptions)
  {		
    this.#options.replace(newOptions);
    this.trigger('options:set');
  };		

	removeItemAt(idx)
	{
		this.input().children[idx].remove();
	};

  replaceItemAt(item,idx)
  {     	
  	const items = this.input().children;
  	
    if (idx < items.length)
    {   	
      const r = items[idx];

      r.before(item);
      r.remove();
    }   	
    else	
    {   	
      this.input().append(item);
    }   	
  		
    return item;
  };    	

  valIsSet(value)
  {
    return ( 
              (this.type() == 'checkbox' && this.#values.contains(valueStr) ) || 
              (this.type() == 'radio' && this.#values == valueStr)
            );
  };

  buildCheckbox(data,idx)
  {
    const valueStr = this.#config.templates.value.interpolate(data);
    const labelStr = this.#config.templates.label.interpolate(data);
    const inputIdx = this.name() + '_' + idx;
    
    const container = wdc('<div class="form-check"/>');
    const cb = wdc(`<input class="form-check-input" type="${this.type()}" value="${valueStr}" id="${inputIdx}">`);
    cb.addEventListener('click',evt => this.handleCheckboxClick(evt) );

    if ( this.valIsSet(valueStr) ) cb.checked == true;

    //handle disabled    
    if (this.#config?.readonly == true) cb.disabled = true;

    //handle switch styling
    if (this.#config?.switch == true)
    {
      container.classList.add('form-switch');
      cb.setAttribute('role','switch');
    }

    const lbl = wdc(`<label class="form-check-label" for="${inputIdx}"/>`);
    lbl.append(labelStr);

    container.append(cb);
    container.append(lbl);
    
    return container;
  };

  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const data = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if data is undefined, the item is being removed
    if (typeof(data) == 'undefined')
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else
    {
      const item = this.buildCheckbox(data,idx);
			this.replaceItemAt(item,idx);
    }
  };  

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    this.removeItemAt(idx);
  };

  readonly(val)
  {
    if (val === undefined)
    {
      return this.querySelector('input').disabled;
    }
    else
    {
      this.querySelectorAll('input').forEach( input => {
        input.disabled = val;
      });
    }
  };

  handleValueSet(evtData)
  {
    if (evtData.property == 'length') return;

    this.checkValueRows();
    this.trigger('value:set');
  };

  handleValueDelete(evtData)
  {
    this.checkValueRows();
    this.trigger('value:set');
  };

  checkValueRows()
  {
    const values = this.#values.toArray();

    this.input().children.forEach( item => {

      let isSet = false;
      const cb = item.querySelector('input');
      
      values.some( v => {
        if ( v == item.value )
        {
          isSet = true;
          return true;
        }
      });

      item.checked = isSet;
    });
  };

  handleCheckboxClick(cb,evt)
  {
    const itemVal = cb.value;

    if (this.type() == 'checkbox')
    {
      const idx = this.#values.indexOf(itemVal);

      if (idx == -1) this.#values.push(itemVal);
      else this.#values.splice(idx,1);
    }
    else
    {
      let newVal = [];

      //if allow clear is set, allow clearing the value or updating
      if (this.#config.allow_clear == true)
      {
        const idx = this.#values.indexOf(itemVal);
        newVal = (idx == -1) ? [itemVal] : [];
      }
      else
      {
        newVal = [itemVal];
      }

      this.#values.replace(newVal);
    }

    if (evt)
    {
      this.trigger('change');
      this.trigger('input');        //not sure we need this one
    }

  };

  val(val)
  {
    if ( val === undefined )
    {
      return this.getVal();   
    }
    else
    {
      this.setVal(val);
      return this;
    }
  };

  getVal()
  {
    if (this.type() == 'checkbox')
    {
      return this.#values;
    }
    else
    {
      return (this.#values.length > 0) ? this.#values[0] : null;
    }
  };

  setVal(val,triggerChange)
  {
    if (val === null) val = [];
    else if ( !(val instanceof Array) ) val = [val];

    this.#values.replace(val);
    this.trigger('value:set');
  };

};


export class WDFormRadio extends WDFormCheckbox {
  
  constructor(cfg)
  {
    cfg.type = 'radio';
    super(cfg);
  };
};

customElements.define('wd-form-checkbox',WDFormCheckbox);
customElements.define('wd-form-radio',WDFormRadio);
