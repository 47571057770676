
import { WDFormBasic } from './wd-form-basic';
import { WDProxyArray } from '../../core/wd-proxy';

export class WDFormDatalist extends WDFormBasic {

	#options;
	#datalist;
	#config = { 
	  options:[],
	  placeholder: 'Type to search...',
    templates: {
      value: '${value}'
    },
	};
	
  constructor(cfg)
  {
    cfg.type = 'datalist';
    const optionListId = `${cfg.name}Options`;

    const elem = wdc(`<input class="form-control" list="${optionListId}" name="${cfg.name}" placeholder="${cfg.placeholder}"/>`);
    super(elem,cfg);

    this.#config = Object.assign({},cfg);

    this.#datalist = wdc(`<datalist id="${optionListId}"/>`);

    //setup our base proxy for our option storage
    this.#options = new WDProxyArray([]);

    //setup an event watcher for proxy set if we have a content handler
    this.#options.on('set', evtData => this.handleSet(evtData) );
    this.#options.on('delete',evtData => this.handleDelete(evtData) );

    //passed some options, add to our proxy array
    if ( this.#config.options.length > 0)
    {
    	this.#options.replace(this.#config.options);
		}

		//if passed a fetch
		if (this.#config.fetch) this.fetchOptions();
				
		//disable the button if readonly
		if (this.config()?.readonly) this.readonly(this.config().readonly);

		if (this.#config?.value !== undefined && this.#config?.value !== null) this.val(this.#config.value);
	};

	options() { return this.#options; };	

	fetchOptions()
	{
		this.#config.fetch.req().then( results => {

      if (results instanceof Array)
      {			
		    this.#options.replace(results);
      }
      		
		});
  };

	removeItemAt(idx)
	{
		this.#datalist.children[idx].remove();
	};

  replaceItemAt(item,idx)
  {     	
  	const items = this.#datalist.children;
  	
    if (idx < items.length)
    {   	
      const r = items[idx];

      r.before(item);
      r.remove();
    }   	
    else	
    {   	
      this.#datalist.append(item);
    }   	
  		
    return item;
  };    	


  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const data = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if data is undefined, the item is being removed
    if (typeof(data) == 'undefined')
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else
    {
			const item = wdc('<option/>');
			item.setAttribute('value', this.#config.templates.value.interpolate(data) );

			this.replaceItemAt(item,idx);
    }
  };  

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    this.removeItemAt(idx);
  };

};

customElements.define('wd-form-datalist',WDFormDatalist);
