
import WDPaneList from './wd-pane-list';

export default class WDPaneListPager extends WDPaneList {

  #pager;
  #pagerCounter;
  #totalCount = 0; 	

	constructor(cfg)
	{
		super(cfg).classList.add('has-pager');
		//this.pagerbar();
	};

	runFetch()
	{
	  return super.runFetch().then( () => {

	    this.#totalCount = this.results().total_count;
	    this.showPager();
	  
	  });
	
	};

  /**
    * @method pagerbar
    */  
  pager()
  {
    if (!this.#pager)
    {
      const m = this.toolbar().prefix();
      m.classList.add('wd-pager-counter');
      this.#pagerCounter = m.addText('');
      
      this.#pager = this.toolbar().addPager();
      this.#pager.on('page:selected',(evt) => this.setOffset(evt.detail.page) );
      
      //make sure we have an offset
      if (this.fetch().limit && !this.fetch().offset) this.fetch().offset = '0';

      //resize the pager when window size changes, or when panes are added and removed
      window.addEventListener("resize",(evt) => this.showPager() );
      this.panes().on('pane:added',() => this.showPager() );
      this.panes().on('pane:removed',() => this.showPager() );
    }

    return this.#pager;
  };

  setOffset(idx)
  {
    this.fetch().offset = idx * this.fetch().limit;
    this.runFetch();
  };

  resetOffset()
  {
    this.fetch().offset = 0;
    if (this.#pager) this.#pager.page(0);
  };

  showPager()
  {
    //if (!this.footer()) return;

    let segment = 85;
    let width = this.clientWidth;
    if (width < 768) segment = 80;

    const count = parseInt(width / segment);
    this.pager().load({	
      count: this.#totalCount,
      limit: this.fetch().limit,
      max_pages: count
    });

    const start = parseInt(this.fetch().offset)+1;
    const end = (parseInt(this.fetch().limit) + parseInt(this.fetch().offset));

    const txt = start + " - " + Math.min(this.#totalCount,end) + " of " + this.#totalCount;
    this.#pagerCounter.innerText = txt;
    
    this.updateFooter(txt);
  };

  selectPage(idx)
  {
    this.setOffset(idx);
  };

  updateFooter(txt)
  {
    const f = this.list().footer();
    f.content().replaceChildren(txt);
    //f.add(txt);
  };

};

customElements.define('wd-pane-list-pager',WDPaneListPager);
