
import { WDFormInput } from './wd-form-input';

export class WDFormEmail extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'email';
    super(cfg);
  };
  
  validate()
  {
		let data = this.val();

		//address must have an @ sign, a ., and no spaces
		if (data.indexOf("@")==-1 || data.indexOf(".")==-1 || data.indexOf(' ') != -1) 
		{
			this.markError();
			return false;
		}
		else 
		{
			this.markSuccess();
			return true;
		}
	};
};

customElements.define('wd-form-email',WDFormEmail);

