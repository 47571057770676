
import { WDFormInput } from './wd-form-input';

export class WDFormDate extends WDFormInput {
  constructor(cfg) {
    cfg.type = 'date';
    super(cfg);
  };
};

customElements.define('wd-form-date',WDFormDate);
