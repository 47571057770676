
import WDElement from '../gui/wd-element';
import { WDFormText } from '../gui/form/wd-form-text';

export default class WDKeypad extends WDElement {

	#options = { 	
		placeholder:null, 
	  handler:null,
	  change:null,
	  type:'password',
	};

	#enterBtn = null;
	#title = null;
	#keyForm = null;
	#body = null;
	#keypad = null;

	constructor(opts)
	{
	  super('<div class="wd-keypad"/>');

	  if (opts) Object.assign(this.#options,opts);

	  //
	  this.#keyForm = new WDFormText({name:'keypad',placeholder:this.#options.placeholder});
	  this.#keyForm.on('change',(evt) => this.handleChange(evt) );
	  this.#keyForm.on('keyup',(evt) => this.handleKeyUp(evt) );

	  let clearBtn = wd('<span/>').classList.add('fa-solid','fa-times');
	  clearBtn.on('click',() => this.clearForm() );
	  this.#keyForm.suffix(clearBtn);
	  
	  this.append(this.#keyForm);
	  this.keypad();

	  this.#keyForm.on('focus',(evt) => this.#keypad.slideUp() );

	  //the timeout allows us to click a result w/o it sliding out of view
	  this.#keyForm.on('blur',(evt) => { setTimeout( this.#keypad.slideDown,250); });
	  
	  return this;
	};

	//accessors
	enterBtn() { return this.#enterBtn; };

	//
	handleKeyUp(evt)
	{
	  if (!evt) evt = window.event;

	  if (evt.which=="13") 
	  {
	    this.handleSubmit(evt);
	    return false;
	  }
	};

	clearForm()
	{
	  this.#keyForm.val('');
	  this.handleChange();
	};

	title(str)
	{
	  if (!this.#title)
	  {
	    this.#title = wdc('<div class="wd-keypad-title"/>');
	    if (str) this.#title.append(str);
	    
	    this.prepend(this.#title);
	  }
	  
	  return this.#title;
	}; 

	/*
	list(opts)
	{
	  if (!this.#records)
	  {
	    this.#records = new WDList(opts).addClass('wd-keypad-records');
	    this.#records.insertAfter(this.keypad());
	  }
	
	  return this.#records;
	}; 
	*/
	
	body()
	{
	  if (!this.#body)
	  {
	    this.#body = wdc('<div class="wd-keypad-body"/>');
	    this.keypad().body(this.#body);
	  }

	  return this.#body;
	};

	//
	val(value)
	{
	  if (typeof(value) == 'undefined')
	  {
	    return this.#keyForm.val();
	  }
	  else
	  {
	    this.#keyForm.val(value);
	    return this;
	  }
	};

	keypad()
	{
	  if (!this.#keypad)
	  {
	    this.#keypad = wdc('<div class="wd-keypad-pad"/>');

	    for (let i=1;i<=9;i++)
	    {
	      let btn = new WDButton(i).addClass('btn-default');
	      btn.on('click',() => this.enterNumber(String(i)) );
	      this.#keypad.append(btn);
	    }

	    const delBtn = new WDButton({label:'Delete',theme:'default'});
	    delBtn.on('click',() => this.deleteNumber() );
	    
	    const zeroBtn = new WDButton({label:'0',theme:'default'});
	    zeroBtn.on('click',() => this.enterNumber('0') );
	    
	    this.#keypad.append(delBtn);
	    this.#keypad.append(zeroBtn);

	    this.#enterBtn = new WDButton({label:'Enter',theme:'default'});
	    this.#enterBtn.on('click',(evt) => this.handleSubmit(evt) );
	    this.#keypad.append(this.#enterBtn);
	  
	    this.append(this.#keypad);
	  }
	  
	  return this.#keypad;
	};

	handleChange(evt)
	{
	  this.trigger('keypad:updated');
	};

	handleSubmit(evt)
	{
	  this.trigger('keypad:submitted');
	};

	enterNumber(num)
	{
	  this.#keyForm.val(this.#keyForm.val() + num);
	  this.handleChange();
	};
	
	deleteNumber()
	{
	  let str = this.#keyForm.val();
	  str = str.substr(0,str.length-1);
	  
	  this.#keyForm.val(str);
	  this.handleChange();
	};

	reset()
	{
	  this.#keyForm.val('');
	};

};

customElements.define('wd-keypad',WDKeypad);
