
Date.prototype.addHours = function(h){
    this.setHours(this.getHours() + parseInt(h) );
    return this;
}

Date.prototype.addMinutes = function(h){
    this.setMinutes(this.getMinutes() + parseInt(h) );
    return this;
}

Date.prototype.addSeconds = function(h){
    this.setSeconds(this.getSeconds() + parseInt(h) );
    return this;
}

Date.prototype.addMonths = function(h){
    this.setMonth(this.getMonth() + parseInt(h) );
    return this;
}

Date.prototype.addDays = function(h){
    this.setDate(this.getDate() + parseInt(h) );
    return this;
}

Date.prototype.addWeeks = function(w){
		return this.addDays(w*7);
}

Date.prototype.addYears = function(h){
    this.setFullYear(this.getFullYear() + parseInt(h) );
    return this;
}

Date.prototype.firstOfWeek = function(h){
    this.addDays(0 - this.getDay());
    return this;
}

Date.prototype.endOfWeek = function(h){
    this.addDays(6 - this.getDay());
    return this;
}

Date.prototype.firstOfMonth = function(h){
    this.setDate(1);
    return this;
}

Date.prototype.endOfMonth = function(h){
    this.setDate(-1);
    return this;
}

Date.prototype.firstOfYear = function(h){
		this.setMonth(0);
    this.setDate(1);
    return this;
}

Date.prototype.endOfYear = function(h){
		this.setMonth(11);
    this.setDate(31);
    return this;
}

Date.prototype.midnight = function(str)
{
	this.setHours(0);
	this.setMinutes(0);
	this.setSeconds(0);
	this.setMilliseconds(0);
	return this;
};

Date.prototype.endOfDay = function(str)
{
	this.setHours(23);
	this.setMinutes(59);
	this.setSeconds(59);
	this.setMilliseconds(999);
	return this;
};

Date.prototype.isToday = function()
{
	var d = new Date();

	if (this >= d.midnight() && this <= d.endOfDay()) return true;
	else return false;
}

Date.prototype.isThisWeek = function()
{
	var d = new Date();

	if (this >= d.firstOfWeek() && this <= d.endOfWeek()) return true;
	else return false;
}

Date.prototype.toHalfHour = function()
{
	if (this.getMinutes() >= 30) this.setMinutes(30);
	else this.setMinutes(0);

	this.setSeconds(0);
	this.setMilliseconds(0);

	return this;
};

Date.prototype.nextHour = function()
{
	var min = this.getMinutes();
	if (min > 0) this.addMinutes(60-min);

	this.setMinutes(0);
	this.setSeconds(0);
	this.setMilliseconds(0);

	return this;
};

Date.prototype.nextHalfHour = function()
{
	var min = this.getMinutes();
	if (min > 0 && min != 30)
	{
		if (min < 30) this.addMinutes(30-min);
		else this.addMinutes(60-min);

		this.setMinutes(0);
		this.setSeconds(0);
		this.setMilliseconds(0);

	}
	return this;
};

Date.prototype.localDate = function()
{
	return this.addMinutes(this.getTimezoneOffset());
};

Date.prototype.parts = function()
{	
	//https://www.php.net/manual/en/datetime.format.php
	let hours = this.getHours();

	let hoursTwelve = (hours > 12) ? hours - 12 : hours;
	if (hoursTwelve == '0') hoursTwelve = 12;

	const weekShort = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	const weekLong = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

	const monthShort = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	const monthLong = ['January','February','March','April','May','June','July','August','September','October','November','December'];

	const parts = {	//year
		'Y': String(this.getFullYear()).padStart(2,'0'),

		//month
		'm': String(this.getMonth() + 1).padStart(2,'0'),
		'j': String(this.getMonth() + 1),
		'M': monthShort[this.getMonth()],
		'F': monthLong[this.getMonth()],

		//days and days of the week
		'd':  String(this.getDate()).padStart(2,'0'),
		'N': this.getDay(),
		'l': weekLong[this.getDay()],
		'D': weekShort[this.getDay()],

		//12/24 hour time with leading zeros
		'H': String(this.getHours()).padStart(2,'0'),
		'h': String(hoursTwelve).padStart(2,'0'),

		//12/24 hour time without leading zeros
		'G': String(this.getHours()),
		'g': String(hoursTwelve),

		'i': String(this.getMinutes()).padStart(2,'0'),
		's': String(this.getSeconds()).padStart(2,'0'),

		'Z': 0 - (this.getTimezoneOffset()/60),

		//am/pm
		'A': ( (hours >= 12) ? 'PM' : 'AM' ),
		'a': ( (hours >= 12) ? 'pm' : 'am' ),

	};

	return parts;
};

Date.prototype.format = function(str)
{
	//break each char into it's own array we will process separately
	var arr = str.split('');
	var dref = this;
	var parts = this.parts();
		
	arr.forEach( (char,idx) => {

		if (char.trim().length > 0 && typeof(parts[char]) != 'undefined')
		{
			arr[idx] = parts[char];
		}
	});

	return arr.join('');
};

Date.prototype.formatDate = function()
{
	return this.format('m/d/Y');
};

Date.prototype.formatDateTime = function()
{
	return this.format('m/d/Y h:i A');
};


Date.prototype.toDateString = function(str)
{
  if (!str) str = 'mm/dd/yy';
  return this.format(str);
};

Date.prototype.toRawDateString = function(str)
{
	return this.format('yy-mm-dd');
};



Date.prototype.toTimeString = function(str)
{
	//get local timezone offset
	const tz = 0 - new Date().getTimezoneOffset();

  if (!str) str = 'h:mm:ss TT';

  return this.format(str);
};

Date.prototype.toRawTimeString = function(str)
{
	return this.format('HH:mm:ssZ');
};

Date.prototype.toRawDateTimeString = function(str)
{
	return this.toRawDateString() + 'T' + this.toRawTimeString();
};

Date.prototype.toDateTimeString = function()
{
	return this.toDateString() + ' ' + this.toTimeString();
};

Date.prototype.toDataString = function()
{
	if (UTIL.isMobileDevice()) 
	{
		return this.toRawDateString() + 'T' + this.toRawTimeString();
	}
	else 
	{
		return this.toDateString() + ' ' + this.toTimeString();
	}
};

Date.prototype.toDateTimeFormString = function()
{
	return this.format('yy-mm-dd') + 'T' + this.format('hh:mm:ss');
};

Date.prototype.toRawDateString = function(str)
{
  return this.toDateString('yy-mm-dd');
};

Date.prototype.toNearestHourString = function(str)
{
  if (!str) str = 'h:mm:ss TT';

	if (this.getMinutes() >= 30) this.addHours(1);

	//get local timezone offset
	var tz = 0 - new Date().getTimezoneOffset();

  return this.format(str);
};

Date.prototype.clone = function()
{
	return new Date(this);
};

Date.prototype.toTimeStamp = function()
{
	return this.getTime()/1000;
};

Date.prototype.dayDiff = function(d2)
{
	let msPerDay = 1000 * 60 * 60 * 24;

	let utc1 = Date.UTC(this.getFullYear(), this.getMonth(), this.getDate());
	let utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

