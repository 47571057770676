
import { WDFormBasic } from './wd-form-basic';

export class WDFormColor extends WDFormBasic {

	#config = {
		mode: 'hex',
		type: 'color'
	};
	#alphaForm = null;
	
	constructor(cfg) {

		const input = wdc(`<input type="color" name="${cfg.name}" class="form-control form-control-color" title="Select a color"/>`);
		super(input,cfg);

		Object.assign(this.#config,cfg);

		if (this.#config.mode == 'rgba')
		{
			const alphaName = this.#config.name + '_alpha';
			const alphaStep =  (cfg.step || .1);
			
			this.#alphaForm = wdc(`<input type="number" name="${alphaName}" type="number" min="0" max="1" step="${alphaStep}" value="1"/>`);
			this.#alphaForm.addEventListener('input',(evt) => this.trigger('change') );
			this.suffix().append(this.#alphaForm);
		}
		
		if (cfg.value) this.val(cfg.value);

	};

	/**
		*/
	val(val)
	{
		if ( typeof(val) == 'undefined' )
		{
			let color = this.input().value;
			
			if (color != null)
			{
				if (this.#config.mode == 'rgba')
				{
					color = this.hexToRgb(color);
					color.push( parseFloat( this.#alphaForm.value ) );
				}
				else if (this.#config == 'rgb')
				{
					color = this.hexToRgb(color);
				}
			}

			return color;
		}
		else
		{
			if (val == null)
			{
				this.input().value = '';
				if (this.#config.mode == 'rgba') this.#alphaForm.value = 1;
			}
			else
			{
				//color needs to be in hex for the input form
				if (this.#config.mode == 'rgba')
				{
					const color = this.rgbToHex(val[0],val[1],val[2]);

					this.input().value = color;
					this.#alphaForm.value = val[3];
				}
				else if (this.#config.mode == 'rgb')
				{
					const color = this.rgbToHex(val[0],val[1],val[2]);
					this.input().value = color;
				}
				else
				{
					this.input().value = val;
				}
			}
			
			return this;
		}
	};

	rgbToHex(r, g, b) 
	{  
		return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
	}

	hexToRgb(hex) 
	{  
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return [ 	parseInt(result[1], 16),  
							parseInt(result[2], 16), 
							parseInt(result[3], 16) 
						];
	};

};

customElements.define('wd-form-color',WDFormColor);
