
import { WDFormBasic } from './wd-form-basic';
import WDButton from '../button/wd-button';

export class WDFormButton extends WDFormBasic {

  #value = null;
  #button = null;
  
  constructor(opts)
  {
  	const cfg = { 
  		theme: 'clear',
  		icon: 'fa-solid fa-edit',
  		handler: null,
  		value: null,
  		type: 'button',
  		templates: {
  			label: '${label}',
  			value: '${value}'
			},
		};
		
		//merge in the passed configs
		Object.assign(cfg,opts);
		
		const btn = new WDButton('Not Selected').theme(cfg.theme);

    super(btn,cfg);
		this.#button = btn;
		
		if (cfg.icon) this.#button.icon(cfg.icon);
		if (cfg.handler) this.#button.on('click',cfg.handler);
		if (cfg.value && Object.keys(cfg.value).length > 0) this.val(cfg.value);
	};

	button() { return this.#button; };

	val(data)
	{
	  if (typeof(data) == 'undefined')
	  {
	    return this.#value;
    }
    else
    {
			this.#value = this.config().templates.value.interpolate(data);

			const str = (data != null) ? this.config().templates.label.interpolate(data) : 'Not Selected';
			this.#button.title(str);

			this.trigger('change');
			this.trigger('value:set');
		}

	};

};

customElements.define('wd-form-button',WDFormButton);
