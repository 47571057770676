
window.wdc = function(elemstr) 
{
  let ret = null;

  if (elemstr.substr(0,1) == '<')
  {
    const tmp = document.createElement('div');
    tmp.innerHTML = elemstr;
    ret = tmp.firstChild;
  }
  else
  {
    ret = document.querySelector(elemstr);
  }
  
  return ret;
};
