
import { WDFormBasic } from './wd-form-basic';

export class WDFormTextarea extends WDFormBasic {

  constructor(cfg)
  {
    cfg.type = 'textarea';
    const elem = wdc('<textarea class="form-control"/>');
    super(elem, cfg);
	};

};

customElements.define('wd-form-textarea',WDFormTextarea);
