
import WDDropdownMultiForm from './wd-dropdown-multi-form';

/**
	Note:
	This form can store objects as values, but it uses the labels to search.  This
	way it matches what a user would type.  what they see is the value expected
	*/
export default class WDDropdownObjectMultiForm extends WDDropdownMultiForm {

  getObjectsFromValue()
  {
    let retObj = [];

    if (this.values().length > 0)
    {
      this.values().forEach( val => {
      
      	this.options().some( opt => {
	
          if (opt.value == val)
          {
          	retObj.push( opt );
            return true;
          }
        
        });
      });
    }
    
    return retObj;
  };

	val(val)
	{
		if (typeof(val) == 'undefined') 
		{
			return this.getObjectsFromValue();
		}
		else
		{
			const vals = [];
			val.forEach( v => vals.push( v.value ) );
			
			super.val(vals);
			
			return this;
		}
	};

};

customElements.define('wd-dropdown-object-multi-form',WDDropdownObjectMultiForm);
