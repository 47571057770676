
/**
	*	@class WDListProxy
	*/
import WDList from './wd-list'
import { WDProxyArray } from '../../core/wd-proxy';
import WDFormat from '../../core/wd-format.js';

export default class WDListProxy extends WDList {

	#config = { columns: [],
							icon: false,
							checkbox: false,
							buttons: [],
							disclosure: false,
							data: [],
							fetch: null,
							resultKey: null,
							content: null,
							header: true,
							method: 'GET',
							noResults: 'No Results Found',
							showLoading: false,
							loadingIcon: 'fa-solid fa-spinner',
						};
	#proxy;
	#fetch;
	#results = null;
	#loading = null;
	#sortable = null;
					
	constructor(cfg)
	{
		super();

		Object.assign(this.#config,cfg);

		//setup our remap if asked
		if (cfg.map) this.map(cfg.map);

		//make the list responsive if set
		if (cfg.responsive != undefined) this.responsive(cfg.responsive);
		
		//if passed a proxy, use that as our model
		if (cfg.proxy !== undefined)
		{
			this.#proxy = cfg.proxy;

			//if it has a fetch method, this is a FetchArray proxy
			if (this.#proxy?.fetch !== undefined) this.#fetch = this.#proxy.fetch(); 
		}
		//make our own proxy-based model (legacy support)
		else
		{
	    //create a proxy to work with
	    this.#proxy = new WDProxyArray([]);

	    //passed a separate fetch to work with
	    if (this.#config?.fetch !== undefined) this.#fetch = this.#config.fetch;
		}

    //setup an event watcher for proxy set if we have a content handler
    this.#proxy.on('set', evtData => this.handleSet(evtData) );
    this.#proxy.on('delete',evtData => this.handleDelete(evtData) );

    //if passed data, populate the proxy
    if (this.#config.data) 
    {
      this.#proxy.replace(this.#config.data);
    }

    //setup for sorting
		if (this.config().allow_sort) this.setupSortable();

		//call the header handler
		if (this.#config.header !== false && this.#config.columns.length > 0) 
		{
			this.addListHeader();
		}

	};

	disconnectedCallback()
	{
		super.disconnectedCallback();
		
		if (this.#sortable)
		{
			this.#sortable.destroy();
			this.#sortable = null;
		}
	};

	config() { return this.#config; };	
	proxy() { return this.#proxy; };
	fetch() { return this.#fetch; };
	results() { return this.#results; };

	/**
		* updates the proxy contents with the results of the fetch
		*/	
	req()
	{
		if (!this.#fetch) return false;
		
		if (this.#config.showLoading) this.loading(true);

		//run the fetch and return it for promise functionality
		const ftch = this.#fetch.req( this.#config.method ).then( results => {

			if (this.#config.showLoading) this.loading(false);

			//setup external access to our results
			this.#results = results;
			this.trigger('results:fetched',results);
			
			//allow for a fetch key to be used in case the result is nested
			const fk = this.#config.resultKey;
			const resData = (fk != null && typeof(results[fk]) != 'undefined') ? results[fk] : results;

			this.#proxy.replace(resData || []);

			//not sure if this will work or not			
			if (resData.length == 0) this.handleNoResults();
						
		});
		
		return ftch;
	};

	handleNoResults()
	{
		
		//check for a custom noResult handler, otherwise append the string
		const str = (typeof(this.#config.noResults) == 'function') ? this.#config.noResults() : this.#config.noResults;
		if (str !== null) this.single().add(str);
	};
	
	loading(show)
	{
		if (!this.#loading)
		{
			this.#loading = wdc('<div class="wd-list-loading"/>');

			const icon = wdc('<span class="fa-spin"/>');
			icon.classList.add( ...this.#config.loadingIcon.split(' ') );

			this.#loading.append(icon);
			
			this.append( this.#loading );
		}
		
		if (show === true) this.classList.add('is-loading');
		else this.classList.remove('is-loading');
	};
		
  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const value = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if value is undefined, the item is being removed
    if (typeof(value) == 'undefined')
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else
    {
      //call the handler to insert/update the item
      const item = this.replaceItemAt(idx);

			//set the data object for the list item
			item.data(value);
      
      //call the content handler
      if (this.#config.content != null && typeof(this.#config.content) == 'function')
      {
      	this.#config.content(item,value);
			}
			else
			{
	      this.addListItem(item,value);
			}
			
    }
     	
  };	

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

  	this.removeItemAt(evtData.property);
  	
  	if (this.items().length == 0) this.handleNoResults();
  };

  getColumnValue(obj, col)
  {
  	if (col?.template)
  	{
  		return String(col.value).interpolate( obj );
  	}
  	else 
  	{
  		const path = col.value;
	    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
		}
	};

	addListItem(item,data)
	{
		//shortcut
		const cfg = this.#config;

		//add icons and checkboxes
		if (cfg.checkbox) item.checkbox(data.value);

    if (cfg.allow_sort) item.icon('fa-solid fa-sort');
    else if (cfg.icon) item.icon(cfg.icon);
		
		//button config
		if (cfg.buttons.length > 0) this.addListItemButtons(item,data);

		//disclosure indicator		
		if (cfg.disclosure) item.disclosure();

		//add each configured column to the list item
		cfg.columns.forEach( col => {

			//make sure there's a value to work wit
			let val = this.getColumnValue(data,col) || null;

		  //passed a format handler?  use it
		  if (col?.type) val = this.formatFromType(val,col.type);
		  else if (col?.format) val = col.format(val,data);
		  else if (col?.template) val = col.templates.interpolate(data);
			
		  //create our cell with the formatted value
			const cell = item.add(val);

			//use the value key as the class name
			if (col?.value !== undefined) cell.classList.add(col.value);

			//show a responsive/mobile label
			//options - show when responsive, show different label when responsive, show original label when responsive?
			if ( typeof(col.responsive) != 'undefined')
			{
				if (col.responsive === false) cell.classList.add('responsive-hide');
				else 
				{
					//"responsive" can have the label we want to use, or we just use the original label
					const useLabel = (typeof(col.responsive) == 'string') ? col.responsive : col.label;
					
					cell.classList.add('responsive-label');
					const ml = wdc('<label/>').append(useLabel);
					cell.prepend( ml );
				}
			}

		});	
		
		//list item click handlers
		if (cfg.click) item.on('click', () => cfg.click(data,item) );

		return item;
	};

	formatFromType(val,type)
	{
		if ( String(val).length > 0)
		{
			//if we have a format handler for this type, use it
			if (WDFormat?.type) val = WDFormat.type(val);
		}
		
		return val;
	};

	addListItemButtons(item,data,isHeader)
	{
		//shortcut
		const cfg = this.#config;

		cfg.buttons.forEach( bCfg => {
		
			const btn = item.addButton();

			if (bCfg.handler)
			{
				bCfg.handler(btn,data);
			}
			else
			{
				if (bCfg.icon) btn.icon(bCfg.icon);
				if (bCfg.label) btn.label(bCfg.label);
				if (bCfg.title) btn.title(bCfg.title);
				if (bCfg.click && !isHeader) 
				{
					btn.on('click',(evt) => {
						evt.stopPropagation();
						bCfg.click(data,btn);
					});
				}
			}
			
			//add dropdown options
			if (bCfg.dropdown && !isHeader)
			{
				const dd = btn.dropdown();
				
				bCfg.dropdown.forEach( ddCfg => {

					if (ddCfg?.divider)
					{
						dd.addDivider();
					}
					else
					{
						const ddBtn = dd.add();

						if (ddCfg.handler)
						{
							ddCfg.handler(ddBtn,data);
						}
						else
						{
							if (ddCfg.icon) ddBtn.icon(ddCfg.icon);
							if (ddCfg.label) ddBtn.label(ddCfg.label);
							if (ddCfg.click) 
							{
								ddBtn.on('click',(evt) => {
									evt.stopPropagation();
									ddCfg.click(data,ddBtn);
								});
							}
						}						
					}
									
				});
			}
		});
	};

	addListHeaderBase()
	{
		//shortcut
		const cfg = this.#config;
		const h = this.header();

		//add icons and checkboxes
		if (cfg.checkbox) h.checkbox();

		if (cfg.allow_sort) h.icon('fa-solid fa-sort');
		else if (cfg.icon) h.icon(cfg.icon);
		
		//button config
		if (cfg.buttons.length > 0) this.addListItemButtons(h,null,true);

		//disclosure indicator		
		if (cfg.disclosure) h.disclosure();
	};

	addListHeader()
	{
		const cfg = this.#config;
		const h = this.header();

		this.addListHeaderBase();
		
		//add each configured column to the list item
		cfg.columns.forEach( col => {
			const cell = h.add(col.label);
			if (typeof(col.value) != 'undefined') cell.classList.add(col.value);
		});

	};

	/**
		* allows the current list order to be rearranged
		*/
  setupSortable()
  {	
  	import('sortable').then( module => {

      this.#sortable = module.Sortable.create( this.body(), {
                          draggable: '.wd-list-item',
                          handle: '.fa-sort',
                          animation: 150,
                          fallbackOnBody: true,
                          swapThreshold: 0.65,
                          onStart: (evt) => this.handleSortBegin(evt),
                          onEnd: (evt) => this.handleSortEnd(evt),
                          //onMove: (evt) => this.handleSortMove(evt),
      });
    });
  };

  handleSortBegin(evt)
  {
  	this.trigger('items:sort',evt);
  };


  handleSortEnd(evt)
  {
    this.trigger('items:sorted',evt);
  };

};

customElements.define('wd-list-proxy',WDListProxy);
