
export default class WDUtil {

  static isObject(item)
  {
    return (item !== null && typeof item === 'object' && !(item instanceof Array) );
  }

  /**
    * for the purpose of merging.  these aren't cloned
    */
  static isReference(item)
  {
    return (	item instanceof Promise || 
              typeof(item) == 'function' || 
              item.constructor.toString().substring(0, 5) == 'class' ||
              item.isProxy
            );
  };

  /* From ChatGPT - need to test - also need to decide if it should clone or reference Proxies */
  static mergeObjects(target, ...sources)
  {
    if (!WDUtil.isObject(target))  throw new Error('Target must be an object');

    for (const source of sources) 
    {
      if (!WDUtil.isObject(source)) throw new Error('Source must be an object');

      for (const key in source) 
      {
        if (WDUtil.isObject(source[key])) 
        {
          if (!target[key] || !WDUtil.isObject(target[key])) 
          {
            target[key] = {};
          }
          WDUtil.mergeObjects(target[key], source[key]);
        } 
        else 
        {
          if (source[key]?.isProxy)
          {
            target[key] = source[key].toObject();
          }
          else if (WDUtil.isReference(source[key])) 
          {
            target[key] = source[key];
          } 
          else 
          {
            target[key] = JSON.parse(JSON.stringify(source[key]));
          }
        }
      }
    }
    
    return target;
  }


  /**
  * Deep merge two objects.
  * @param target
  * @param ...sources
  */
  static merge(target, ...sources)
  {
    if (!sources.length) return target;

    const source = sources.shift();

    if (WDUtil.isObject(target) && WDUtil.isObject(source)) 
    {
      for (const key in source) 
      {
        //handle nested objects
        if (WDUtil.isObject(source[key])) 
        {
          //promises, functions, classes and proxies aren't cloned, just passed by reference
          if ( WDUtil.isReference(source[key]) )
          {
            target[key] = source[key];
          }
          else
          {
            //target doesn't exist or is null, clone into a new object
            if (target[key] === undefined || target[key] === null) Object.assign(target, { [key]: {} });

            //
            WDUtil.merge(target[key], source[key]);
          }

        }
        else if (source[key] !== undefined)
        {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    
    return target;
  };

  static extend(...items)
  {
    const target = items.shift();
    return this.merge(target,items);
  };

  static param(params)
  {
    if (!params) return '';

    const urlParams = new URLSearchParams();

    for (const [name, value] of Object.entries(params))
    {
      urlParams.append(name, value || '');
    }

    return urlParams.toString();

  };

  static uuid()
  {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };  

  static belowBreakpoint()
  {
    return (window.innerWidth < WDConfig.panes.breakpoint);
  };

  static fileToClass(fileName)
  {
    return fileName
      .split('-') // Split by hyphen
      .map((word, index) =>
        index === 0 && word.toLowerCase() === 'wd'
          ? 'WD' // Ensure 'WD' is always capitalized
          : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the rest
      )
      .join('');
  };

  static newClassToFile(className) {
    return className
        .replace(/([A-Z]+)([A-Z][a-z]|[a-z]|$)/g, (match, p1, p2) => {
            return `${p1.toLowerCase()}-${p2.toLowerCase()}`;
        })
        .replace(/-$/, ''); // Remove trailing hyphen if any
  };
  
  static classToFile(className)
  {
    const cn = className.replace('WD','wd');
    return cn
      .replace(/([A-Z])/g, '-$1') // Insert hyphen before each capital letter
      .toLowerCase() // Convert the whole string to lowercase
      .replace(/^-/, ''); // Remove leading hyphen if any
  }

  static isLDAPEnabled()
  {
    return (typeof(WDConfig.api.config.LDAPEnabled) != 'undefined' && WDConfig.api.config.LDAPEnabled == true);
  };

};

