
import WDApiObject from '../../core/fetch/wd-api-object';
import WDButton from '../button/wd-button';
import WDActivity from '../modal/wd-activity';
import WDListSort from './wd-list-sort';
import WDListFormItem from './wd-list-form-item';
import WDUtil from '../../core/wd-util';

export default class WDListForm extends WDListSort {

	#dataSources = {};

	constructor(config) 
	{
		//this gives us an empty form in case there are no results for the fetch,
		//which gives us a starting point for entering new data
		config.noResults = () => {
			return this.noResults();
		};
		
		super(config).classList.add('wd-list-forms');

		if (this.config().allow_add) this.header().addButton().fasIcon('plus');
		if (this.config().allow_remove) this.header().addButton().fasIcon('trash');

		if (this.config().buttons)
		{
			this.config().buttons.forEach( btnCfg => {
				const btn = this.header().addButton();
				if (btnCfg.icon) btn.icon(btnCfg.icon);
			});
		};

		//add the forms - they will be populated by the item data
		this.config().forms.forEach( formItem => {
			const cell = this.header().add(formItem.label);
			if (formItem?.name) cell.classList.add(formItem?.name);
		});

		return this;
	};

	noResults()
	{
		const item = wdc('<div class="no-items-wrapper"/>');
		const btn = new WDButton({label:'Add New Item',size:'sm',theme:'primary',fasIcon:'plus'});
		btn.on('click',() => this.addFormItem() );

		item.append(btn);
		return item;
	};

	/**
		* localizes any remote data sources, fetches our rows
		*/
	load()
	{
		return new Promise( (resolve,reject) => {
		
			if (this.items().length == 0) 
			{
				this.handleNoResults();
				resolve([]);
			}
			
			this.fetchDataSources().then( () => {
				this.req().then( () => {
					resolve( this.proxy() );
				});
			});

		});
	};
	
	fetchDataSources()
	{
		const ddForms = this.config().forms.filter(x => (x?.fetch != undefined || x?.saveFetch != undefined) );
	
   	return new Promise( (resolve,reject) => {

   		let formsLoaded = 0;

   		if (ddForms.length == 0) resolve(true);
			
   		ddForms.forEach( form => {

				if (form?.saveFetch == undefined) form.saveFetch = form.fetch;
				delete(form.fetch);
				   		
   			form.saveFetch.req().then( results => {
   			
					this.#dataSources[form.name] = results;
					form.options = results;

					//we already have loaded forms, update thier options
					if (this.items().length > 0)
					{
						this.items().forEach( item => {
							item.getForm(form.name).setOptions(results);
						});
					}

					formsLoaded++;
					
					if (formsLoaded == ddForms.length) resolve(true);
   			});
   		
   		});
		});
		
	};

	addListItem(item,data)
	{
		const itemIdx = item.getIndex();

		if (data?.isProxy) item.setProxy( data );		
		else if (data?.fetch) item.setProxy( data.fetch );
		else if (data?.uri) 
		{
			const p = new WDApiObject(data.uri);
			p.add(data);
			item.setProxy(p);
		}

		if (this.config()?.allow_sort) 
		{
			item.icon('fa-solid fa-sort');
		}
		
		if (this.config()?.allow_add) 
		{
			const btn = item.addButton({fasIcon:'plus'});
			btn.on('click',() => this.addFormItem(item) );
		}

		if (this.config()?.allow_remove || this.config()?.allow_delete)
		{
			const btn = item.addButton({fasIcon:'trash'});
			btn.on('click',() => this.removeFormItem(item) );

			//don't allow removal of the last item in the list
			if (this.items().length == '0') btn.style.visibility = 'hidden';
		}

		if (this.config().buttons)
		{
			this.config().buttons.forEach( btnCfg => {
				const btn = item.addButton();

				if (btnCfg.text) btn.text(btnCfg.text);
				if (btnCfg.icon) btn.icon(btnCfg.icon);
				if (btnCfg.click) btn.on('click',() => btnCfg.click(item) );

			});
		}

		this.config().forms.forEach( async formItem => {

			//clone the formItem.  The variables are stomping on each other
			const formCfg = WDUtil.merge({},formItem);

			item.add(formCfg).then( newForm => {

				//if we update an option list, re-cache all our data sources.
				//eventually just fetch the updated source so it's cached again
				newForm.on('options:updated',() => this.fetchDataSources() );
				newForm.on('change',() => {

					if (this.fetch())
					{
						item.post().then( () => this.trigger('item:updated',item) );
					}
					else
					{
						this.trigger('item:updated',item);
					}
				});
				
			});

		});

		return item;
	};

	/**
		* overwrite createItem so we can use our FormsItem instead
		*/	
	createItem()
	{
		return new WDListFormItem(this);
	};

	addFormItem(item)
	{
		const position = (item) ? item.getIndex() + 1 : '0';

		if ( this.fetch() )
		{
			const p = new WDApiObject( this.config().fetch.getURI() );
			this.proxy().insert(position,p);
		}
		else
		{
			this.proxy().insert(position,item || {});
		}
		
	};
	

	removeFormItem(item)
	{
		//if not set, or set to true - new syntax for me
		if (this.config()?.delete_confirm ?? true)
		{
			new WDActivity().showDelete( () => this.runRemoveFormItem(item) );
		}
		//no confirmation, just do
		else
		{
			this.runRemoveFormItem(item);
		}
	};
	
	runRemoveFormItem(item)
	{
		//delete the item if it was saved (how can we tell?)
		if ( this.fetch() && item.proxy().getURI() != this.config().fetch.getURI() )
		{
			item.proxy().del().then( () => {
				this.proxy().remove( item.getIndex() );
			});
		}
		else
		{
			this.proxy().remove( item.getIndex() );
		}
	};

  formHasValue(itemData)
  {
    let ret = false;

    Object.values(itemData).some( val => {

	    if ( typeof(val) == 'object' )
	    {
	      if ( val instanceof Array && val.length > 0) 
	      {
	      	ret = true; 
				}
	    }
	    else if (String(val).length> 0)
	    {
	    	ret = true;
	    }

	    if (ret == true) return true;

		});
		
    return ret;
  };

	serialize()
	{
		const data = [];
		this.items().forEach( item => {

			const itemData = item.serialize();

			//ignore empty rows
			if (this.formHasValue(itemData)) data.push(itemData);
		});

		return data;
	};

	populate(vals)
	{
		this.reset();
		
		vals.forEach( (val,idx) => {

			const item = this.insert();
			item.populate(val);
		});

	};

};

customElements.define('wd-list-form',WDListForm);
