/**
	*	stores the results of a WDFetch in a proxy and allows for direct updating
	* with the remote uri
	*
	*	@class WDApiObject
	*/

import WDFetchObject from './wd-fetch-object'

export default class WDApiObject extends WDFetchObject {

  constructor(baseCfg,values)
  {
    let authHeader = null;
    const cfg = { baseURL: WDConfig.api.url };

    //cfg passed, use that to talk to the api.  Otherwise fallback on our config and auth classes
    if (typeof(baseCfg) == 'string')
    {
      cfg.baseURI = baseCfg;
    }

   //normal username/password info
    if ( WDConfig.auth.mode != 'none' )
    {
      cfg.headers = { 'Authorization': WDAuth.getAuthorization() };
    }

    super(cfg,values);
  };

};

