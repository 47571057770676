
import WDPane from './wd-pane';
import WDListForm from '../lists/wd-list-form';

export default class WDPaneListForm extends WDPane {

	#config;
	#proxy;
	#fetch;
	#list;
	#filterMode = 'default';
		      	
	constructor(cfg)
	{
		super(cfg).classList.add('wd-pane-list');

		this.#config = Object.assign({},cfg);

	};

	/** public accessors **/
	config() { return this.#config; };
	proxy() { return this.#list.proxy(); };
	fetch() { return this.#list.fetch(); };
	results() { return this.#list.results(); };
	sortField() { return this.#list.sortField(); };
	sortDir() { return this.#list.sortDir(); };
  refresh() { return this.runFetch(); };

	/**
		loads the list with the config and executes a fetch
		*/
	load(cfg)
	{
		return new Promise( (resolve,reject) => {

			//init the list
			this.list(cfg);	

			if (cfg?.fetch)
			{
				this.runFetch().then( () => {
					resolve( this.#list.proxy() );
				});
			}
			else
			{
				resolve( this.#list.proxy() );
			}
		});
	};			


	/**
		* sets up the underlying list for the pane
		*/
	list(cfg) { 
	
		if (typeof(cfg) == 'undefined')
		{
			return this.#list; 
		}
		else
		{
			this.#list = new WDListForm(cfg);
			this.#list.classList.add('scrollable');
			this.body().append(this.#list);

			let filterCfg = null;

			//I can't decide on naming yet
			if (cfg?.filters) filterCfg = {filters:cfg.filters};
			else if (cfg?.filterConfig) filterCfg = cfg.filterConfig;
			
			if (filterCfg)
			{
				this.filters(filterCfg).on('filter:apply',() => this.runFetch() );
			}
			
			return this.#list;
		}
	};

	/**
		* serialize our filters and run our api fetch, 
		* then update our list with the results
		*/
  runFetch()
  {
		if (this.hasFilters())
		{
			const formData = this.serializeFilters();

			//clean up our filter data to remove any nulls or empty values
			for (let key in formData)
			{
				const val = formData[key];

				//remove empty form data so it doesn't get passed to the api			
				if (val === null || String(val).length == 0) delete( this.fetch()[key] );

				//otherwise set the value to pass along
				else this.fetch()[key] = val;
			}
		}
		
		//merge in sort data for the request - should this be done in 
		//WDListProxy since that's where the sorting is...
		if ( this.#list.sortField() != null ) this.fetch().sort_field = this.#list.sortField();
		if ( this.#list.sortDir() != null ) this.fetch().sort_dir = this.#list.sortDir();

		//run the search
		return this.#list.load();
  };

  serializeFilters()
  {
  	const formData = {};
  	const fbData = this.filters().serialize();

		//convert to simple key=>value for this mode  	
  	if (this.#filterMode == 'simple')
  	{
  		fbData.forEach( f => {
  			formData[f.field] = f.value;
  		});
  	}
  	//otherwise store the whole filter
  	else
  	{
			formData.filters = fbData;
  	}

  	return formData;
  };

};

customElements.define('wd-pane-list-form',WDPaneListForm);
