
import WDElement from '../wd-element.js'
import WDDropdown from '../dropdown/wd-dropdown';

export default class WDButton extends WDElement {

	#label = null;
	#loading = null;
	#icon = null;
	#suffix = null;
	#curState = {};
	#wrapper = null;
	#dropdown = null;
	#caret = null;
	#config = {
		indicator: 'fa-solid fa-caret-down',
		split: false
	};
	
	constructor(params)
	{
		super().setAttribute('type','button');

		this.classList.add('btn');
		this.addEventListener('click', this.checkDisabled);

		if (params !== undefined)
		{
			if (typeof(params) == 'string')
			{
				this.#config.label = params;
			}
			else
			{
				//some custom types for quick-setting button configs
				if (params?.type)
				{
					if (params.type == 'save')
					{
						this.#config.label = 'Save';
						this.#config.fasIcon = 'save';
						this.#config.theme = 'success';
					}

					if (params.type == 'delete')
					{
						this.#config.label = 'Delete';
						this.#config.fasIcon = 'trash';
						this.#config.theme = 'danger';
					}

					if (params.type == 'add')
					{
						this.#config.label = 'Add';
						this.#config.fasIcon = 'add';
						this.#config.theme = 'primary';
					}
				}

				//this allows for specific overwriting of the above types
				Object.assign(this.#config,params);
			}
		}
	};
	
	connectedCallback()
	{
		super.connectedCallback();

		const cfg = this.#config;
		
		if (cfg?.label && !cfg?.title) cfg.title = cfg.label;
		if (cfg?.fasIcon) cfg.icon = `fa-solid fa-${cfg.fasIcon}`;
		if (cfg?.farIcon) cfg.icon = `fa-solid fa-${cfg.farIcon}`;

		//setup the button		
		if (cfg?.title) this.title(cfg.title);
		if (cfg?.label) this.label(cfg.label);
		if (cfg?.icon) this.icon(cfg.icon);
		if (cfg?.theme) this.theme(cfg.theme);
		if (cfg?.size) this.size(cfg.size);
		if (cfg?.click) this.on('click',cfg.click);
	};
	
	label(str)
	{
		if (!this.#label)
		{
			this.#label = wdc('<span class="label"/>');

			if (this.#icon) this.#icon.after(this.#label);
			else this.prepend(this.#label);
		}

		if (str !== undefined)
		{
			this.#label.replaceChildren();
			if (str !== null) this.#label.append(str);
		}
		
		return this.#label;
	};

	title(val)
	{
		if (val !== 'undefined')
		{
			this.setAttribute('title',val);
			return this;
		}
		else
		{
			return this.getAttribute('title');
		}
	};
	
	text = this.label;
	
	icon(str)
	{
		if (!this.#icon)
		{
			this.#icon = wdc('<span class="icon"/>');
			this.prepend(this.#icon);
		}

		if (typeof(str) == 'undefined') return this.#icon;
		else
		{
			const iconClasses = Array.isArray(str) ? str : str.split(' ');
			iconClasses.push('icon');
			
			this.#icon.setAttribute('class','');
			this.#icon.classList.add(...iconClasses);

			return this;
		}

	};

	fasIcon(iconName)
	{
		return this.icon(`fa-solid fa-${iconName}`);
	};

	farIcon(iconName)
	{
		return this.icon(`fa-regular fa-${iconName}`);
	};

	theme(str)
	{
		this.classList.remove('btn-clear','btn-primary','btn-secondary','btn-success','btn-warning','btn-danger');
		this.classList.add(`btn-${str}`);

		return this;
	};

	size(str)
	{
		this.classList.remove('btn-sm','btn-md','btn-lg');
		this.classList.add(`btn-${str}`);

		return this;
	};

	suffix(str)
	{
		if (!this.#suffix)
		{
			this.#suffix = wdc('<span class="suffix"/>');
			this.append(this.#suffix);
		}

		if (typeof(str) == 'undefined') return this.#suffix;
		else
		{
			this.#suffix.setAttribute('class',str);
			return this;
		}

	};


	busy(mode)
	{
		if (mode === true && !this.disabled )
		{
			//disable the button
			this.disabled = true;
		
			if (this.#icon)
			{
				this.#curState.icon = this.#icon.getAttribute('class');
				this.icon('fa-solid fa-circle-notch fa-spin');
			}	
		}
		else if ( this.disabled )
		{
			this.disabled = false;
			if (this.#icon) this.icon(this.#curState.icon);
			this.#curState = {};
		}
	
	};
	
  // Property getter
  get disabled() {
    return this.hasAttribute('disabled');
  }

  // Property setter
  set disabled(value) {
    if (value) {
      this.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
    }
  }

	checkDisabled(event) 
	{
    if (this.disabled) {
      // Prevent default action and stop propagation if disabled
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
  	* dropdown-related methods
  	*/
	wrapper() { return this.#wrapper; };
	dropdown() { 
		if (!this.#dropdown) this.addDropdown();
		return this.#dropdown; 
	};
	
	addDropdown()
	{
		//insert the wrapper before our button, then add the button to the wrapper
		this.#wrapper = wdc('<div class="btn-group"/>');

		//make the dropdown
		this.#dropdown = new WDDropdown();

		//put the wrapper after the button, then move the button into the wrapper
		this.after(this.#wrapper);
		this.#wrapper.append(this);
		this.#wrapper.append(this.#dropdown);

		const icons = Array.isArray(this.#config?.indicator) ? this.#config.indicator : this.#config.indicator.split(' ');
		const ddIcon = wdc('<span/>');
		ddIcon.classList.add(...icons);

		//if it's a split button, make a new button to manage the dropdown
		if (this.#config.split == true)
		{
			const splitBtn = new WDButton(ddIcon).classList.add( this.getAttribute('class') );
			splitBtn.classList.add('dropdown-toggle','dropdown-toggle-split');
			splitBtn.setAttribute("data-bs-toggle","dropdown");
			this.#wrapper.append(splitBtn);
		}
		else
		{
			//setup the element to activate us when clicked
			this.append(ddIcon);
			this.classList.add("dropdown-toggle");
			this.setAttribute("data-bs-toggle","dropdown");
		}

		this.on('show.bs.dropdown',ev => this.trigger('dropdown:show') );
		this.on('shown.bs.dropdown',ev => this.trigger('dropdown:shown') );
		this.on('hide.bs.dropdown',ev => this.trigger('dropdown:hide') );
		this.on('hidden.bs.dropdown',ev => this.trigger('dropdown:hidden') );

	};

};

customElements.define('wd-button',WDButton);
