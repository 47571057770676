
import WDPaneCards from './wd-pane-cards';
import WDAccordion from './wd-accordion';

export default class WDPaneAccordion extends WDPaneCards {

	#options = {show_navigation:false,select:'single',sortable:false};
	#isScrolling = false;
	#accordion = null;
	
	constructor(opts)
	{
		//if collapsible isnt set, remove it from the default config that PaneCards uses
		if (opts?.collapsible == undefined) opts.collapsible = false;

		super(opts).classList.add('wd-pane-accordion-card');

		if (opts) Object.assign(this.#options,opts);

		this.accordion();
	};

	reset()
	{
		return this.accordion().reset();
	};			
			
	accordion()
	{
		if (!this.#accordion)
		{
			this.#accordion = new WDAccordion(this.#options);
			this.body().append(this.#accordion);
		}

		return this.#accordion;
	};

	addCard(card,labelName,icon)
	{
		super.addCard(card,labelName,icon);
		this.accordion().add(card);
	};

};

customElements.define('wd-pane-accordion',WDPaneAccordion);
