
import { WDFormBasic } from './wd-form-basic';
import WDFilter from '../filter/wd-filter';

export class WDFormFilter extends WDFormBasic {

  _filter = null;
  
  constructor(cfg)
  {
    cfg.type = 'filter';

  	//make sure we have a config parameters
  	if (typeof(cfg.config) == 'undefined') 
  	{
  		throw new Error('No config passed for WDFormFilter');
		}

 	  const filter = new module.default(cfg.config);
 	  super(filter,cfg);
 
   	//store a reference
   	this._filter = filter;

   	this._filter.on('filters:updated',(evt) => {
   		this.trigger('change');
   		this.trigger('input');
   		this.trigger('value:set');
   	} );			//need to revisit this

   	//populate the form
   	if (cfg.value) this._filter.val( cfg.value );
  };
  
  val(data) { 
    if (typeof(data) != 'undefined') this._filter.reset();
    return this._filter.val(data); 
  };
  
};

customElements.define('wd-form-filter',WDFormFilter);

