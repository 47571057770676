
import { WDFormBasic } from './wd-form-basic';
import WDButton from '../button/wd-button';

export class WDFormDaterangedropdown extends WDFormBasic {

	#beginCfg = null;
	#endCfg = null;
	#beginForm = null;
	#endForm = null;
	#updateTimer = null;
	
	constructor(cfg) {

		const btn = new WDButton('Not Selected').size('sm');
		super(btn,cfg);

		const beginName = `${cfg.name}[begin]`;
		const endName = `${cfg.name}[end]`;
		
		this.#beginForm = wdc(`<input type="date" name="${beginName}" placeholder="From" class="form-control-sm"/>`);
		this.#endForm = wdc(`<input type="date" name="${endName}" placeholder="To" class="form-control-sm"/>`);
		
		//let's see about this
		if (cfg?.value_begin) this.#beginForm.value = cfg.value_begin;
		if (cfg?.value_end) this.#endForm.value = cfg.value_end;

		this.#beginForm.on('input',() => this.handleUpdate() );
		this.#endForm.on('input',() => this.handleUpdate() );

		btn.dropdown().add(this.#beginForm);
		btn.dropdown().add(this.#endForm);
		
		this.updateButton();
	};

	handleUpdate()
	{
		if (this.#updateTimer) clearTimeout(this.#updateTimer);

		this.#updateTimer = setTimeout( () => {

			this.trigger('change');
			this.updateButton();

			this.#updateTimer = null;

		}, 250);
	};

	updateButton()
	{
		let beginVal = String(this.#beginForm.value).trim();
		let endVal = String(this.#endForm.value).trim();
		let retStr = 'Not Selected';

		if (beginVal.length > 0 && endVal.length == 0) retStr = 'From ' + beginVal;
		else if (beginVal.length == 0 && endVal.length > 0) retStr = 'To ' + endVal;
		else if (beginVal.length > 0 && endVal.length > 0) retStr = 'From ' + beginVal + ' to ' + endVal;

		this.input().title(retStr);
	};

	/**
		*/
	val(valobj)
	{
		if (typeof(valobj) != 'undefined')
		{
			if (valobj.begin) this.#beginForm.value = valobj.begin;
			if (valobj.end) this.#endForm.value = valobj.end ;

			this.updateButton();
		}
		else
		{
			let beginVal = this.#beginForm.value;
			let endVal = this.#endForm.value;
			let ret = null;
						
			if (beginVal || endVal)
			{
				ret = {};
				if (beginVal) ret.begin = beginVal;
				if (endVal) ret.end = endVal;
			}

			return ret;
		}
	};

};

customElements.define('wd-form-daterange-dropdown',WDFormDaterangedropdown);
