
/**
	*	@class WDListSort
	*/

import WDListProxy from './wd-list-proxy';
import WDDropdownForm from '../dropdown/wd-dropdown-form';
import WDButton from '../button/wd-button';

export default class WDListSort extends WDListProxy {

	#ms = null;
	#mobileSort = null;
	#sortButtons = {};
	#sortField = null;
	#sortDir = 'asc';
			
	constructor(cfg)
	{
		cfg.header = false;			//keep the parent from creating a header on init so we can do it
		super(cfg);

		//inherit our sort values from fetch, if there are any
		if (cfg.fetch)
		{
			if (cfg.fetch.sort_field) this.#sortField = cfg.fetch.sort_field;
			if (cfg.fetch.sort_dir) this.#sortDir = cfg.fetch.sort_dir;
		}
		else 
		{
			if (cfg.sort_field) this.#sortField = cfg.sort_field;
			if (cfg.sort_dir) this.#sortDir = cfg.sort_dir;
		}
					
		//if no sort field set, pull one from the columns
		if (!this.#sortField && this.sortColumns().length > 0) this.#sortField = this.sortColumns()[0].value;			

		this.addListHeader();
		
		if (cfg.data && cfg.data.length > 0)
		{
			this.setSort(this.#sortField,this.#sortDir);
		}
				
	};

	sortColumns()
	{
		return this.config().columns.filter( c => (c.sort == true) );
	};

	addListHeader()
	{
    const cfg = this.config();
    const h = this.header();
    let hasSort = false;

		this.addListHeaderBase();

		//add each configured column to the list item
		cfg.columns.forEach( col => {

			if (typeof(col.sort) != 'undefined' && typeof(col.value) != 'undefined' && col.sort == true)
			{
				hasSort = true;
				this.addSortButton(col);
			}
			else
			{
				const cell = h.add(col.label);
				if (typeof(col.value) != 'undefined') cell.classList.add(col.value);
			}
		});

		if (hasSort) this.updateSortHeaders();
	};
	
	addSortButton(col)
	{
    //make sure we have a mobile sort button
    this.mobileSort();

    //create sort button for this field
    const btn = new WDButton(col.label);
    
    //the click updates our mobile btn which triggers the sort update.  this helps
    //us keep everything in sync, no matter what part of the interface they click on
    btn.on('click',() => {
    	this.setSort(col.value);
    	this.#mobileSort.val(col.value); //setSort(col.value);
    });
    
    btn.append(wdc('<span class="caret fa-solid fa-caret-up"/>'));

    //add the sort button to the header
		this.header().add(btn).classList.add(col.value);

		//store for easy access later
		this.#sortButtons[col.value] = btn;
	};

  mobileSort()
  {
  	if (!this.#mobileSort)
  	{
  		//get the fields that sort is enabled for
  		const sortFields = this.config().columns.filter( col => (col?.sort == true) );

			//setup the mobile dropdown  		
      const opts = { 	options: sortFields,
      								allow_clear: false,
      								value: this.#sortField,
      								button: { 
      									text:'Sort',
      									update_text: (val) => this.updateMobileSortText(val)
											}
										};

  		this.#mobileSort = new WDDropdownForm(opts);
      this.#mobileSort.on('change', () => {
      	this.setSort( this.#mobileSort.val() );
			});

			//add the button to the header
  		const cell = this.header().add( this.#mobileSort );
  		cell.classList.add('wd-mobile-sort-cell');

			//move the sort button to the front of the line
			this.header().content().prepend(cell);
  	}
  	
  	return this.#mobileSort;
	};

	updateMobileSortText(val)
	{
		//get the fields that sort is enabled for
 		const sortFields = this.config().columns.filter( col => (col?.sort == true) );
 		let valObj = null;

		//passed an empty value (usually happens at init)
		if ( val === null )
		{
  		sortFields.some( f => {
  			if (f.value == this.#sortField)
  			{
  				valObj = f;
  				return true;
				}
			});
			
		}
		else
		{
  		sortFields.some( f => {
  			if (f.value == val)
  			{
  				valObj = f;
  				return true;
				}
			});
		}

		//show something dammit
	if (!valObj) valObj = sortFields[0];

		//sortField
		return valObj.label + ' - ' + this.#sortDir.capitalize();

	};

	updateSortHeaders()
	{
		//update our sort field classes for the buttons
		const curSort = this.header().querySelector('.sort-by');
		if (curSort) curSort.classList.remove('sort-by','sort-asc','sort-desc');

		if (this.#sortButtons[ this.#sortField ]) 
		{
			this.#sortButtons[ this.#sortField ].classList.add('sort-by','sort-' + this.#sortDir);
		}
	};

	getColumnDataType(colName)
	{
		let colType = 'string';
		
		this.config().columns.some( col => {
		
			if (col.value == colName && col?.value_type)
			{
				colType = col?.value_type;
				return true;
			}
		});
		
		return colType;
	};

	setSort(val,sortDir)
	{
		if (sortDir)
		{
			this.#sortDir = sortDir;
		}
		else if (val == this.#sortField)
		{
			this.#sortDir = (this.#sortDir == 'asc') ? 'desc' : 'asc';
		}
		else
		{
			this.#sortField = val;
			this.#sortDir = 'asc';
		}

		//update the column headers
		this.updateSortHeaders();
		
		//we have a fetch, update the sort params and re-fetch
		if (this.config().fetch)
		{
			this.config().fetch.sort_field = this.#sortField;
			this.config().fetch.sort_dir = this.#sortDir;
			this.req();
		}
		//no fetch, sort the data internally
		else if (this.proxy().length > 0)
		{
			const colType = this.getColumnDataType(this.#sortField);
			
			if ( colType == 'number' )
			{
				if (this.#sortDir == 'asc') this.sortNumberAsc();
				else this.sortNumberDesc();
			}
			else
			{
				if (this.#sortDir == 'asc') this.sortStringAsc();
				else this.sortStringDesc();
			}
		}

		this.trigger('sort:updated');
	};

	sortNumberAsc()
	{
		this.proxy().sort((a, b) => {
			return parseFloat(a[this.#sortField]) - parseFloat(b[this.#sortField]);
		});
	};

	sortNumberDesc()
	{
		this.proxy().sort((a, b) => {
			return parseFloat(b[this.#sortField]) - parseFloat(a[this.#sortField]);
		});
	};
	
	sortStringAsc()
	{
		this.proxy().sort( (a,b) => {
			return String(a[ this.#sortField ]).localeCompare( b[this.#sortField] );
		});
	};

	sortStringDesc()
	{
		this.proxy().sort( (a,b) => {
			return String(b[ this.#sortField ]).localeCompare( a[this.#sortField] );
		});
	};

	sortField() { return this.#sortField; };
	sortDir() { return this.#sortDir; };

};

customElements.define('wd-list-sort',WDListSort);
