
/**
  * @class - WDSiteToolbar
  * @desc - manages the top bar of the site, which consists of the user profile menu,
	*					navigation stack breadcrumbs, and the site title control
  */

import WDNavbar from '../gui/navbar/wd-navbar';

export default class WDSiteToolbar extends WDNavbar {

	#siteNav = null;
	#crumbNav = null;
							
	connectedCallback()
	{
		if (WDConfig?.siteToolbar?.title)
		{
			let titleObj = WDConfig?.siteToolbar?.title;
			
			if ( typeof(titleObj) !== 'object' && titleObj.charAt(0) == '<' )
			{
				titleObj = wdc(titleObj);
			}
			
			this.right().add(titleObj).classList.add('site-title');
		}

    if (this.showSite()) this.addSiteMenu();
    this.addCrumbMenu();
	};

	controller() { return document.querySelector('wd-site-controller'); };
	siteMenu() { return document.querySelector('wd-site-menu'); };
	showSite() { return (this.siteMenu() && WDConfig?.siteToolbar?.show_site == true); };
	
	addCrumbMenu()
	{
		this.#crumbNav = this.addBreadcrumbs();
			
		this.controller().on('pane:added',() => this.updateCrumbs() );
		this.controller().on('pane:removed',() => this.updateCrumbs() );
		this.controller().on('site:panes:reset',() => this.#crumbNav.empty() );
	};

	addSiteMenu()
	{
		this.#siteNav = this.add();
		this.#siteNav.classList.add('wd-navbar-sitemenu');

		this.siteMenu().addEventListener('site:ready',() => this.updateSite() );
		this.siteMenu().addEventListener('site:changed',() => this.updateSite() );
		this.controller().on('site:panes:reset',() => this.#siteNav.empty() );
	};
	
	updateSite()
	{
		this.#siteNav.empty();
		this.#siteNav.add( WDSites.get().label );
	};

	updateCrumbs()
	{
		this.#crumbNav.empty();
		const curPane = this.controller().currentPane();

		//
		this.controller().itemArray().forEach( (pane,paneIdx) => {

			//if the pane has no title, skip this one
			if (pane.title())
			{
				//show crumbs for each pane in the stack
				const item = this.#crumbNav.add(pane.title());
				item.on('click',() => this.controller().popToIndex(paneIdx));

				//sometimes the title can be updated after the pane is added, 
				//make sure we update if that happens
				pane.on('title:updated',(evt) => item.text(evt.detail.title) );
			}
		});	 

	};
 
};

customElements.define('wd-site-toolbar',WDSiteToolbar);
