
import WDElement from '../wd-element';
import WDButton from '../button/wd-button';

export class WDListItem extends WDElement {

	#prefix = null;
	#suffix = null;
	#list = null;
	#content = null;
	#data = null;
	#checkbox = null;
	#icon = null;
	#disclosure = null;
	#selected = false;
	#buttons = [];
	#clickable = false;
			
	constructor(listItem) {

		super().classList.add('wd-list-item');

		//required
		this.content();

		this.on('click',(evt) => {
		

		});
		
		return this;
	};

	list() { 
		if (!this.#list) this.#list = this.closest('.wd-list');
		return this.#list;
	};

	on(evtName,func)
	{
		if (evtName == 'click' && this.#clickable == false)
		{
			this.setupClickable();
		}
		
		return super.on(evtName,func);
	};

	setupClickable()
	{
		this.#clickable = true;
		this.classList.add('is-clickable');
		
		super.on('click', evt => {

			const elem = evt.srcElement;
			const tagName = elem.tagName.toLowerCase();
			
			//don't handle click actions on elements w/n the row that will be interacted with
			if (tagName == 'input' || tagName == 'button' || elem.parentElement.tagName.toLowerCase() == 'button')
			{
				evt.stopPropagation();
				evt.stopImmediatePropagation();
			}
			else
			{
				//mark the row as selected
				this.selected(true);
			}

		});
		
	};

	content()
	{
		if (!this.#content)
		{
			this.#content = wdc('<div class="wd-list-item-content"/>');
			this.append(this.#content);
		}
		
		return this.#content;
	};

	prefix()
	{
		if (!this.#prefix)
		{
			this.#prefix = wdc('<div class="wd-list-item-prefix"/>');
			this.prepend(this.#prefix);
		}
		
		return this.#prefix;
	};

	suffix()
	{
		if (!this.#suffix)
		{
			this.#suffix = wdc('<div class="wd-list-item-suffix"/>');
			this.append(this.#suffix);
		}
		
		return this.#suffix;
	};

	add(content)
	{
		const cell = wdc('<div class="wd-list-item-cell"/>');

		if (content === undefined || content === null) content = '';
		cell.append(content);

		this.content().append(cell);
				
		return cell;
	};

	//replace value with data -> "Value" should point to data.value
	//and then our usage of "value" vs "val" in the WDRecords class
	//needs to be re-evaluated - it's just all not very clear from
	//trying to re-use the jquery nomenclature
	data(data)
	{
		if (typeof(data) == 'undefined') 
		{
			return this.#data;
		}
		else 
		{
			//remap some of our data for value matching
			if (this.list().map()) this.list().remap(data);

			this.#data = data;
			return this;
		}
	
	};

	/**
		should val() return the data value, or the data.value if checked
		*/
	val(data)
	{	
		return (typeof(this.#data.value) != 'undefined') ? this.#data.value : null;
	};

	checkbox(val)
	{
		if (!this.#checkbox)
		{
			this.#checkbox = wdc('<input type="checkbox" name="list_item[]"/>');
			if (val) this.#checkbox.attr('value',val);
			
			this.#checkbox.on('change',(evt) => {

				const evtName = (this.#checkbox.prop('checked') == true) ? 'item:checked' : 'item:unchecked';
				this.trigger(evtName,this);
			} );
			
			const cell = wdc('<div class="wd-list-item-checkbox"/>').append(this.#checkbox);
			this.prefix().prepend(cell);

			this.on('item:checked item:unchecked',() => this.handleChecked() );
		}
		
		return this.#checkbox;
	};

	//adds a button onto the row
	addButton(cfg)
	{
		const btn = new WDButton(cfg);
		this.suffix().append(btn);

		this.#buttons.push(btn);

		return btn;
	};

	buttons() { return this.#buttons; };

	/**
		icon, thumbnail, buttons, disclosure
		- should we just have a prefix/suffix?
		- may end up being more code having to write the the icon or disclosure
		- what about thumbnails?  are they used enough to keep?  (or call them image?)
		*/
	
	icon(icon)
	{
		if (!this.#icon)
		{
			this.#icon = wdc('<span/>');
			const cont = wdc('<div class="wd-list-item-icon"/>').append(this.#icon);
			this.prefix().append( this.#icon );
		}
		
		if (icon) 
		{
			const icons = Array.isArray(icon) ? icon : icon.split(' ');
			this.#icon.setAttribute('class','');
			this.#icon.classList.add(...icons);
		}
		
		return this.#icon;
	};

	disclosure(icon)
	{
		if (!this.#disclosure)
		{
			//allow icon override
			if (!icon) icon = 'fa-solid fa-chevron-right';
			this.#disclosure = new WDButton({theme:'clear',icon:icon});

			const wrapper = wdc('<div class="wd-list-item-disclosure"/>');
			wrapper.append(this.#disclosure);

			this.append(wrapper);
		}
		return this.#disclosure;
	};

	checked(val)
	{
		if (typeof(val) == 'undefined')
		{
			return (this.#checkbox) ? this.#checkbox.checked : false;
		}
		else
		{
			if (this.#checkbox.disabled == true) return this;
			
			this.#checkbox.checked = val;
			const evtName = (val === true) ? 'item:checked' : 'item:unchecked';
			
			this.trigger(evtName,this);
			return this;
		}
	};

	handleChecked()
	{
		if (this.#checkbox.checkd == true) this.classList.add('is-checked');
		else this.classList.remove('is-checked');
	};

	selected(val)
	{
		if (typeof(val) == 'undefined')
		{
			return this.#selected;
		}
		else
		{
			this.#selected = val;
			const evtName = (val === true) ? 'item:selected' : 'item:unselected';
			
			this.trigger(evtName,this);
			return this;
		}
	};
	
	getIndex()
	{
		return this.list().itemArray().indexOf(this);
	};
	
	nextItem()
	{
		const idx = this.getIndex();
		return (idx < this.list().items().length-1) ? this.list().items()[idx+1] : null;
	};

	prevItem()
	{
		const idx = this.getIndex();
		return (idx > 0) ? this.list().items()[idx-1] : null;
	};

	removeItem()
	{
		this.list().removeItemAt( this.getIndex() );
	};

	insertAfterItem()
	{
		return this.list().insert( this.getIndex() + 1 );
	};

	insertBeforeItem()
	{
		return this.list().insert( this.getIndex() - 1 );
	};
	
	scrollTo(opts)
	{
		if (!this.list()) return;

		//if it's not in view, scroll to it
		if (this.offsetTop > this.list().wrapper().clientHeight )
		{
			this.list().wrapper().scrollTop = this.offsetTop;
		}
		
  };

};

customElements.define('wd-list-item',WDListItem);

export class WDListHeader extends WDListItem {

	#checkbox = null;
	
	constructor()
	{
		super().classList.add('wd-list-header');
	};

	checkbox()
	{
		if (!this.#checkbox)
		{
			this.#checkbox = wdc('<span class="fa-solid fa-check"/>');

			const cell = wdc('<div class="wd-list-item-checkbox"/>');
			cell.append(this.#checkbox);
			cell.addEventListener("click",() => this.checkAll() );

			this.prefix().prepend(cell);
			
		}

		return this.#checkbox;
	};

	disclosure()
	{
		const ds = super.disclosure();
		ds.style.visibility = 'hidden';

		return ds;
	};

	addButton()
	{
		const btn = super.addButton();
		btn.style.visibility = 'hidden';

		return btn;
	};

	icon(icon)
	{
		const ic = super.icon(icon);
		ic.style.visibility = 'hidden';
		
		return ic;
	};

	checkAll()
	{
		const items = this.list().items();
	
		if (items.length > 0)
		{
			const checkMode = (items[0].checked() == true) ? false : true;
			items.forEach( item => item.checked(checkMode) );
		}

		this.list().trigger('change');
	};

	checked() { return false; };
	selected() { return false; };

};

customElements.define('wd-list-header',WDListHeader);

export class WDListFooter extends WDListItem {

	#checkbox = null;

	constructor()
	{
		super().classList.add('wd-list-footer');
	};

	checked() { return false; };
	selected() { return false; };
};

customElements.define('wd-list-footer',WDListFooter);
