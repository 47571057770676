
globalThis.WDFilterMatches = {

    text:	[   
      { label:'contains', value:'contains', short_label: '**' },
      { label:'does not contain', value:'not_contains', short_label: '!**' },
      { label:'equals', value:'equals', short_label: '=' },
      { label:'does not equal', value:'not_equals', short_label: '!=' },
      { label:'is not set', value:'is_null', short_label: '= null' },
    ],

    textlistDisabled:	[   
      { label:'contains any', value:'contains_any', short_label: '** ANY' },
      { label:'contains all', value:'contains_all', short_label: '** ALL' },
      { label:'does not contain all', value:'not_contains_all', short_label: '!** ALL' },
      { label:'equals any', value:'equals_any', short_label: '= ANY' },
      { label:'does not equal all', value:'not_equals_all', short_label: '!= ALL' },
    ],
            
    number: [ 
      { label:'equals', value:'equals', short_label: '=' },
      { label:'does not equal', value:'not_equals', short_label: '!=' },
      { label:'greater than', value:'greater_than', short_label: '>' },
      { label:'greater than or equals', value:'greater_than_equals', short_label: '>=' },
      { label:'less than', value:'less_than', short_label: '<' },
      { label:'less than or equals', value:'less_than_equals', short_label: '<=' },
      { label:'is not set', value:'is_null', short_label: '= null' },
    ],

    date: [   
      { label:'equals', value:'equals', short_label: '=' },
      { label:'does not equal', value:'not_equals', short_label: '!=' },
      { label:'greater than', value:'greater_than', short_label: '>' },
      { label:'greater than or equals', value:'greater_than_equals', short_label: '>=' },
      { label:'less than', value:'less_than', short_label: '<' },
      { label:'less than or equals', value:'less_than_equals', short_label: '<=' },
      { label:'is not set', value:'is_null', short_label: '= null' },
    ],

    dropdown: [ 
      { label:'equals', value:'equals', short_label: '=' },
      { label:'does not equal', value:'not_equals', short_label: '!=' },
    ],

};

//some fallbacks I don't think we actualy need these anymore
WDFilterMatches.basic = WDFilterMatches.text;
WDFilterMatches.string = WDFilterMatches.text;
WDFilterMatches.currency = WDFilterMatches.number;
WDFilterMatches['datetime-local'] = WDFilterMatches.date;
WDFilterMatches.boolean = WDFilterMatches.dropdown;
WDFilterMatches.select = WDFilterMatches.dropdown;
