
import WDElement from '../wd-element';
import WDButton from '../button/wd-button';

export default class WDPillBox extends WDElement {

  #prefix;
  #suffix;
  #content;
  #sortable;
  
  constructor()
  {
    super().classList.add('wd-pill-box');
  };

  disconnectedCallback()
  {
    super.disconnectedCallback();
    
    this.offAll();
    this.replaceChildren();
  };

  reset()
  {
    if (this.#prefix) this.#prefix.replaceChildren();
    if (this.#content) this.#content.replaceChildren();
    if (this.#suffix) this.#suffix.replaceChildren();
  };
  
  prefix()
  {
    if (!this.#prefix)
    {
      this.#prefix = wdc('<div class="wd-pill-box-prefix"/>');
      this.prepend(this.#prefix);
    }
    
    return this.#prefix;
  };
  
  suffix()
  {
    if (!this.#suffix)
    {
      this.#suffix = wdc('<div class="wd-pill-box-suffix"/>');
      this.append(this.#suffix);
    }
    
    return this.#suffix;
  };
  
  content()
  {
    if (!this.#content)
    {
      this.#content = wdc('<div class="wd-pill-box-content"/>');
      this.append(this.#content);
    }
    
    return this.#content;
  };
  
  addPill()
  {
    const pill = new WDPill();
    this.add(pill);
    return pill;
  };
  
  addBox()
  {
    const box = new WDPillBox();
    this.add(box);
    return box;    
  };
  
  add(elem)
  {
    this.content().append(elem);
    return this;
  };

  items() { return this.content().children; };
  itemArray() { return Array.from(this.items()); };
  itemAt(idx) { return this.items()[idx] || null; };  
};

export class WDPill extends WDButton {

  #box;
  #data;

  constructor()
  {
	  super().classList.add('wd-pill');
  }; 

  content()
  {
    return this.label();
  };

  box()
  {
    return this.closest('wd-pillbox');
  };

  data(data)
  {
    if (typeof(data) == 'undefined') 
    {
      return this.#data;
    }
    else 
    {
      this.#data = data;
      return this;
    }
  
  };

}

customElements.define('wd-pillbox',WDPillBox);
customElements.define('wd-pill',WDPill);
