/**
	*	@class - WDHistory
	*	@desc - a wrapper for the window.history object, along with the ability to supplement
	          the history queue based on panes
	*/

import WDElement from '../gui/wd-element';

//extend wdelement so we can throw events	
export default class WDHistory extends WDElement {

	#history = window.history;
	#location = window.location;
	#controller;
	//storage = null;
	
	constructor()
	{
		super();
		
		//makes the back button work
		window.addEventListener('popstate',(evt) => this.trigger('history:back') );

		this.#controller = document.querySelector('wd-site-controller');
		this.#controller.on('pane:added',(evt) => this.handlePaneAdded(evt) );
		this.#controller.on('pane:removed',(evt) => this.handlePaneRemoved(evt) );
	};
	
	state() { return this.#history.state; };

	/**
		*	@method getParam
		*	@desc - returns the stored value for the passed key from #history.state
		*	@param {string} key - key of value we want to retrieve
		*/
	get(key)
	{
		return;
		let obj = this.#history.state;

		return (!obj || typeof(obj[key]) == 'undefined') ? null : obj[key];
	};

	put(key,val)
	{
		return;
		let params = (this.#history.state) ? this.#history.state : {};

		//merge in the changes
		params[key] = val;

		//rewrite to the histoyr api		
		this.#history.replaceState(params,this.#location.title,this.#location.pathname); //params.uri);
	};

	storage(val)
	{
		let mode = (typeof(val) == 'undefined') ? 'Get' : 'Put';
		
		if (typeof(val) == 'undefined')
		{
			let p = this.get('storage') || {};

			//not quite sure where that's coming from			
			if (p.undefined) delete(p.undefined);

			return p;
		}
		else
		{
			this.put('storage', JSON.parse(JSON.stringify(val)) );
		}

	};

	push(params)
	{	
		return;
 		this.#history.pushState(params,params.title,params.uri);		//push onto history stack
 		//this.title(params.title);

		this.trigger('state:updated');
	};

	pop(idx)
	{
		return;
		//allow overrides of how far back we go
		if (typeof(idx) != 'undefined') this.#history.go(idx);
		else this.#history.back();
	};
	
	replace(params)
	{
 		this.#history.replaceState(params,params.title,params.uri);		//push onto history stack
 		this.title(params.title);
 		this.trigger('state:updated');
	};

	handlePaneAdded(evt)
	{
		const pane = evt.detail.pane;
		const params = pane.appConfig();

		console.log('Handle Pane Added',params);

		if ( params.transition == 'push' )
		{
			this.#history.pushState(params,params.title,params.uri);
		}
		else if (params.transition == 'replace')
		{
			this.#history.replaceState(params,params.title,params.uri);
		}		

		if (params.title) this.title(params.title);		
	};

	handlePaneRemoved(evt)
	{
		const oldPane = evt.detail.pane;
		const newPane = this.#controller.currentPane();

		if (oldPane.appConfig?.transition == 'push')
		{
			const idxDiff = parseInt(newPane.dataset.paneIndex) - parseInt(oldPane.dataset.paneIndex);
			this.#history.go(idxDiff);
		}
		else if (newPane)
		{
			this.replace( newPane.appConfig() );
		}
	};

	/**
		*	@method uri
		*	@desc - returns our current uri for the page
		*/
	uri()
	{
		let path = this.#location.pathname;
		if (uri == '/') uri = DEFAULT_URI;
		
		return uri;
	};


	title(val)
	{
		if (typeof(val) == 'undefined')
		{
			return (this.#history.title) ? this.#history.title : null;
		}
		else
		{
			document.title = val;		 
		}
	};

};

customElements.define('wd-history',WDHistory);

