
export default class WDElement extends HTMLElement {
    
  //some shortcuts
  query = this.querySelector;
  queryAll = this.querySelectorAll;

  //maybe these?
  q = this.querySelector;
  qa = this.querySelectorAll;

  #listeners = new Map();
  #listenerId = 0;
  
  connectedCallback()
  {
    /*
    for ( const [key,listener] of this.#listeners )
    {
      this.addEventListener(listener.type,listener.callback,listener.options);
    }
    */
  };

  disconnectedCallback()
  {
    /*
    for ( const [key,listener] of this.#listeners )
    {
      this.removeEventListener(listener.type,listener.callback,listener.options);
    }
    */
  };

  empty(elem)
  {
    this.replaceChildren(elem);
  };

  getTag() { return this.tagName.toLowerCase(); };


  text(str)
  {
    if (str === undefined) return this.innerText;
    else 
    {
      this.innerText = str;
      return this;
    }
  };

  html(str)
  {
    if (str === undefined) return this.innerHTML;
    else 
    {
      this.innerHTML = str;
      return this;
    }
  };

  hide() { this.style.display = 'none'; };
  show() { this.style.display = ''; };

  /**
    * event and listener handling
    */
  on(evtNames,callback,options)
  {
    const evts = Array.isArray(evtNames) ? evtNames : evtNames.split(' ');
    const evtIds = [];
    
    evts.forEach( evt => {

      try {
        const evtId = this.addListener({type:evt,callback:callback,options:options});
        evtIds.push(evtId);
      }
      catch (err)
      {
        console.log('Error adding event listener',err);
      }
      
    });

    return Array.isArray(evtNames) ? evtIds : evtIds[0];
  };
  
  
  off(evtNames,callback,options)
  {
    //try to find the key and remove it that way
    const evts = Array.isArray(evtNames) ? evtNames : evtNames.split(' ');
    
    evts.forEach( evt => {
    
      const listenerKeys = this.getListenerKeys({type:evt,callback:callback,options:options});

      if (listenerKeys.length > 0)
      {
        listenerKeys.forEach( key => this.offByKey(key) );
      }
      else
      {
        this.removeEventListener(evt,callback,options);
      }
    
    });

  };

  offByKey(evtKeys)
  {
    const keys = Array.isArray(evtKeys) ? evtKeys : [evtKeys];
    
    keys.forEach( key => {

      if ( this.#listeners.has(key) )
      {
        const l = this.#listeners.get(key);

        this.removeEventListener(l.type,l.callback,l.options);
        this.#listeners.delete(key);
      }
    });
  };

  offAll()
  {
    for (const [key,value] of this.#listeners)
    {
      this.offByKey(key);
    }
    
    this.#listeners.clear();
  };

  once(evtNames,callback)
  {
    //try to find the key and remove it that way
    const evts = Array.isArray(evtNames) ? evtNames : evtNames.split(' ');
    const evtIds = [];
    
    evts.forEach( evt => {

      //get the listener key first, so we can remove this listener from the key once its fired
      const params = {
        type:evt, 
        options:{once:true},
        key: this.nextListenerKey(),
      };

      //our callback wrapper to handle listener cleanup
      params.callback = (event) => {
        callback(event);
        this.#listeners.delete(params.key);
      };
      
      const evtId = this.addListener(params);
      evtIds.push(evtId);
    });

    return Array.isArray(evtNames) ? evtIds : evtIds[0];
  };

  one(evtNames,callback)
  {
    return this.once(evtNames,callback);
  };

  nextListenerKey()
  {
    this.#listenerId++;
    return `e${this.#listenerId}`;
  };

  getListenerKeys(params)
  {
    const listenerKeys = [];

    //just passed an event type, so we're removing everything with that type
    if (params?.callback === undefined)
    {
      for (const [key,value] of this.#listeners)
      {
        if (params?.type == value?.type) listenerKeys.push(key);
      }
    }
    else
    {
      for ( const [key,value] of this.#listeners )
      {
        if (params?.type == value?.type && params?.callback === value?.callback && params?.options == value?.options)
        {
          listenerKeys.push(key);
          break;
        }
      }
    }

    return listenerKeys;
  }

  addListener(params)
  {
    try { 	   
    
      this.addEventListener(params.type,params.callback,params.options);
      
      const key = params?.key || this.nextListenerKey();
      delete(params.key);
      
      this.#listeners.set( key, params );
      return key;
    }
    catch (err)
    {
      console.log('Error adding event listener',err);
    }
  };
  
  trigger(evtNames,data)
  {
    const evts = Array.isArray(evtNames) ? evtNames : evtNames.split(' ');

    evts.forEach( evt => {

      const params = data || {};
      const event = new CustomEvent(evt,{detail:params});

      this.dispatchEvent(event);
    });
    
    return this;
  };
};

customElements.define('wd-element',WDElement);
