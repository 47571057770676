
/** 
	modes obtained by adding classes
	
	Parameters
		displayMode: 'app' (default) to fill the whole container.  navbars are fixed, content fills space between
								 'normal' to function like a regular web page
	
	WDPanes Events:
		panes:add => called before a pane is added
		panes:added => called after the pane is added
		panes:remove => called before a pane is removed
		panes:removed => called after the pane is removed
		panes:change => called before a pane is added or removed
		panes:changed => called after a pane is added or removed

	WDPane Events:
		pane:add => called before a pane is added
		pane:added => called after a pane is added
		pane:remove => called before a pane is removed
		pane:removed => called after a pane is removed
		pane:show => called before a pane is displayed
		pane:shown => called after a pane is displayed
*/


import WDElement from '../wd-element'
import WDPane from './wd-pane'

export default class WDPanes extends WDElement {

	#wrapper = null;
	#removeQueue = [];
	#isMobile = false;
	#mutationObserver;
	#resizeObserver;
			
	#options = { inModal: false };

	constructor(opts)
	{
		super().classList.add('wd-panes');
		if (opts) Object.assign(this.#options,opts);
	};
	
	connectedCallback()
	{
		this.wrapper();
		
		//higher performance resize watcher
		this.resizeObserver().observe(this);
	};

	disconnectedCallback()
	{
		super.disconnectedCallback();

		this.replaceChildren();
		this.offAll();
				
		this.#wrapper = null;
		this.#removeQueue = null;
		//this.#isMobile = null;
		//this.#options = null;
		
		if (this.#resizeObserver)
		{
			this.#resizeObserver.disconnect();
			this.#resizeObserver = null;
		}

		if (this.#mutationObserver)
		{
			this.#mutationObserver.disconnect();
			this.#mutationObserver = null;
		}
	};

	items() { return this.wrapper().children; };
	itemArray() { return Array.from( this.items() ); };
	itemAt(idx) { return this.items()[idx] || null; };
		
	panes() { return this.items(); };

	resizeObserver()
	{
		if (!this.#resizeObserver)
		{
			this.#resizeObserver = new ResizeObserver( entries => this.handleResize() );
		}
		
		return this.#resizeObserver;
	};

	/*
	mutationObserver()
	{
		if (!this.#mutationObserver)
		{
      this.#mutationObserver = new MutationObserver( mutationsList => this.handleMutations(mutationsList) );
		}
		
		return this.#mutationObserver;
	};
	*/
	
	wrapper() 
	{ 
		if (!this.#wrapper)
		{
			this.#wrapper = wdc('<div class="wd-panes-wrapper"/>');
			this.append(this.#wrapper);

			//this.mutationObserver().observe( this.#wrapper, {childList:true} );
		}
		
		return this.#wrapper;
	};

	handleResize()
	{
		//mobile to mobile
		if (window.innerWidth < WDConfig?.panes?.breakpoint && this.#isMobile === false)
		{
			this.#isMobile = true;
			this.resetToMobile();
		}
		else if (window.innerWidth >= WDConfig?.panes?.breakpoint && this.#isMobile === true)
		{	
			this.#isMobile = false;
			this.resetToDesktop();
		}
	};

	/*
	handleMutations( mutationsList )
	{
	  for (const mutation of mutationsList) 
	  {
	    if (mutation.type === 'childList') 
	    {
	      // Handle added nodes
	      mutation.addedNodes.forEach( node => {
					//node.trigger('pane:added');      	
	      	this.trigger('pane:added',{pane:node});
				});
			
	      // Handle removed nodes
	      mutation.removedNodes.forEach((node) => {
					//node.trigger('pane:removed');      	
	      	this.trigger('pane:removed',{pane:node});
	      });
	    }
	  }
	};
	*/
	
	isSinglePane()
	{
		return (this.#options.inModal === true || this.#isMobile === true || window.innerWidth < WDConfig?.panes.breakpoint);
	};
	
	resetToMobile()
	{
		const visiblePanes = this.visiblePanes();
		const viewportPanes = this.viewportPanes();
		
		visiblePanes.forEach( pane => {
		
			if (viewportPanes.indexOf(pane) == -1) pane.visible(false);

			pane.size(null);
			pane.setPercentWidth();
		});
	};

	resetToDesktop()
	{
		const visiblePanes = this.visiblePanes();
		const viewportPanes = this.viewportPanes();

		//
		viewportPanes.forEach( pane => {
			if (visiblePanes.indexOf(pane) == -1) pane.visible(true);
		});
		
		this.setDisplaySizes();
		this.visiblePanes().forEach( pane => pane.setPercentWidth() );
	};

  reset()
  {
  	if (this.#mutationObserver) this.mutationObserver().disconnect();

  	this.#wrapper.replaceChildren();
  	this.#wrapper.remove();
  	this.#wrapper = null;

    this.setAttribute('class','');
    this.classList.add('wd-panes');

    return this;
  };

	empty()
	{
		this.wrapper().replaceChildren();
		return this;
	};

	push(animated)
	{
		//build the pane and save
		return this.add(new WDPane(),animated);
	};

	pushAt(idx,animated)
	{
		//build the pane and save
		return this.addAt(new WDPane(),idx,animated);
	};

	add(pane,animated) { return this.addPane(pane,animated); };

	//allows us to make a separate pane and add it - for subclass WDPane
	addPane(pane,animated)
	{
		//add the pane to our wrapper, and to our pane storage object
		this.wrapper().append(pane);

		//set to visible and to its real width
		pane.visible(true);

		//set all the visible panes to their real px width so we can transition
		this.visiblePanes().forEach( pane => pane.setPixelWidth() );

		//if there are panes already, we'll need to setup/handle the transition
		if (pane.getIndex() > 0)
		{
			//set our relative sizes for the visible pane
			this.setDisplaySizes();
			this.wrapper().style.width = this.wrapperAddWidth(pane) + 'px';

			//get the width of the panes we are sliding out of the viewport
			const translateWidth = 0 - this.hidingPanesWidth();

			//turn on acceleration so we have transitions for transform and pane width
			this.accelerate(true);

			//set all the panes to their real px width
			this.viewportPanes().forEach( pane => pane.setPixelWidth() );

			//slide the wrapper to show the transition
			if (translateWidth < 0)
			{
				const transform = 'translateX(' + translateWidth + 'px)';
				this.wrapper().style.transform = transform;
			}
		};

    //setup a handler for the transition ending
    if (animated == true)
    {
      setTimeout(() => this.finishAdd(pane,animated) ,WDConfig?.panes?.transition || 500);
    }
    else
    {
      this.finishAdd(pane,animated);
    }

	};

	/** called after the pane is added **/
	finishAdd(pane,animated) 
	{
		if (pane.getIndex() > 0)
		{
			//end the transitions
			this.accelerate(false);

			//hide the panes that are out of the viewport
			this.hidingPanes().forEach( pane => pane.visible(false) );
		}

		//set the wrapper back to
		this.wrapper().style.transform = '';
		this.wrapper().style.width = '100%';

		//set all the panes to their percentage-based width
		this.visiblePanes().forEach( pane => {
			pane.setPercentWidth();
		});

    this.trigger('pane:shown',{pane:pane});
    pane.trigger('pane:shown');
		
	};

	/** called before the pane is removed **/
	removePane(pane,animated)
	{		
    const paneIdx = pane.getIndex();

    //if there are panes after this, we need to remove them first
    while (pane.nextPane()) 
    {
    	pane.nextPane().remove();
		}
		
    //get our config for removing the pane
    const removeCfg = this.getRemoveConfig(pane);
    pane.transitionConfig = removeCfg;

    //more than one pane left, have transitions probably
		if (this.items().length > 1)
		{
			//if we are sliding a pane in, make it visible
			removeCfg.panesToShow.forEach( pane => pane.visible(true) );
			delete(removeCfg.panesToShow);
			
      //set all the visible panes to their pixel widths
      this.visiblePanes().forEach( pane => pane.setPixelWidth() );

			if (animated == true)
			{
				//slide pane-to-show into view
				if (removeCfg.transformWidth > 0)
				{
					//shift over to include the pane
					const translate = 'translateX(-' + removeCfg.transformWidth + 'px)';
					this.wrapper().style.transform = translate;
				}

				//set our relative sizes for after the transition
				this.setDisplaySizes(true);

				//set the wrapper width to hold the current panes
				let wrapperWidth = pane.realWidth();
				this.visiblePanes().forEach( pane =>  wrapperWidth += pane.realWidth() );
				this.wrapper().style.width = wrapperWidth + 'px';

				//start our transitions
				this.accelerate(true);

				//resize the panes to their new size
				this.visiblePanes().forEach( pane => pane.setPixelWidth() );

				//slide the wrapper back to its starting point
				this.wrapper().style.transform = 'translateX(0)';

				//
				setTimeout(() => this.finishRemove(pane,animated) ,WDConfig?.panes?.transition || 500);
			}
			else
			{
				this.setDisplaySizes(true);
				this.finishRemove(pane,animated);
			}
		}

	};

	/** called after the pane is removed **/
	finishRemove(pane,animated)
	{
		pane.remove();
		
		//end the transitions    
		this.accelerate(false);

		//clear our transforms and revert to percentage-based widths
		this.wrapper().style.transform = '';
		this.wrapper().style.width = '100%';

		//set all the panes to their percentage based width
		this.itemArray().forEach( pane => pane.setPercentWidth() );
		
		//trigger show events for the now visible pane
		if (this.items().length > 0) 
		{
			this.currentPane().trigger('pane:shown');
		}
	};

	insertAt(pane,idx) 
	{
		let p = this.add(pane,false);
		
		if (this.items().length >= idx+1)
		{
			//move the pane to the desired location
			let cur = this.items()[idx];
			cur.before(pane);
		}
	};

	replacePane(curPane,newPane)
	{
		curPane.before(newPane);
		curPane.remove();
	};

	addAt(pane,idx,animated)
	{
		//we already have a pane at this location
		if (this.items()[idx]) 
		{
			this.items()[idx].remove(false);
			animated = false;
		}

		return this.add(pane,animated);
	};

	addRoot(pane)
	{
		this.addAt(pane,0,false);
	};

	accelerate(mode)
	{
		if (mode==true) 
		{
			this.classList.add("accelerated");
			this.trigger('accelerated:on');
		}
		else 
		{
			this.classList.remove("accelerated");
			this.trigger('accelerated:off');
		}
	};

	accelerated()
	{
		return this.classList.contains("accelerated");
	};

	/**
		transition-related methods
		*/

	/**
		returns all the panes that are set to visible.  This is does not check to 
		see if they are in the viewport or not (scrolled in or out of view)
		*/
	visiblePanes()
	{
		return this.itemArray().filter( p => p.visible() === true );
	};

	viewportPanes(isPanePop)
	{
    const panes = this.items();
    const vp = [];

    if (this.isSinglePane())
    {
    	const usePane = (isPanePop && panes.length > 1) ? panes[panes.length-1] : panes[panes.length-1];
    	vp.push(usePane);
    }
    else
    {
	    let totalSize = 0;

			//start from last pane and go forward    
	    for (let i = (panes.length-1); i >= 0; i--)
	    { 
	    	if (isPanePop && i == (panes.length-1) ) continue;

	    	vp.push(panes[i]);

	    	//popping a pane, reset the dominant pane in the view to its base size
	    	if (isPanePop && i == (panes.length - 2))
	    	{
	    		totalSize += panes[i].baseSize();
	    	}
	    	else
	    	{
		      totalSize += panes[i].size();
				}
				
				//first time we cross 100% used, bail      
	      if (totalSize >= 100 || vp.length == 2) break;
			}
		}

    return vp.reverse();

	};

	/**
		when adding a pane, these are the panes that will get pushed out of view
		*/
	hidingPanes()
	{
		const visible = this.visiblePanes();
		const viewport = this.viewportPanes();

		return visible.filter( p => viewport.indexOf(p) == -1 );
	};

	/**
		returns the total pixel width of the panes that will be pushed out
		of view when a new pane is added
		*/
	hidingPanesWidth()
	{
		let hideWidth = 0;
		
		this.hidingPanes().forEach( pane => hideWidth += pane.realWidth() );

		return hideWidth;
	};

	setDisplaySizes(isPanePop)
	{
		const viewportPanes = this.viewportPanes(isPanePop);

		viewportPanes.forEach( pane => pane.size(null) );
		
		//the panes we'll display in the window once the transition is done
		if ( viewportPanes.length > 1 )
		{
			const dPane = viewportPanes[viewportPanes.length-1];		//last pane in the viewport
			const mPane = viewportPanes[viewportPanes.length-2];		//next to the last pane in the viewport

			mPane.size( 100 - dPane.size() );
		}
	}


	/** 
		event handlers 
		*/
	wrapperAddWidth(pane)
	{
		const baseWidth = this.clientWidth;
		let wrapperWidth = 0;
		
		if (this.isSinglePane())
		{
			wrapperWidth = baseWidth * 2;
		}
		else
		{
			wrapperWidth = baseWidth + (pane.realWidth() * 2);
		}

		return wrapperWidth;
	};


	/**
		returns an object containing the panes that will be affected
		by the pane removal, and how they are affected
		*/
	getRemoveConfig(poppedPane)
	{
		const cfg = {};
		
		//get the current displayed panes
		const displayedPanes = this.visiblePanes();										//current visible panes (regardless of viewport status)
		cfg.panesToShow = this.getRemovePanesToShow(poppedPane);		//panes that will become visible in viewport

		//the width of the transform we'll need to slide our panesToShow into view
		cfg.transformWidth = 0;
		cfg.panesToShow.forEach( pane => {
			if ( displayedPanes.indexOf(pane) == -1 ) cfg.transformWidth += pane.realWidth();
		});
		
		return cfg;
	};

	/**
		returns the panes that will be in the viewport after we remove the passed
		pane
		*/
	getRemovePanesToShow(poppedPane)
	{
		const toShow = [];
		const vpIndex = poppedPane.getIndex();
		let usedSize = 0;
		
		for (let i=vpIndex-1; i>=0; i--)
		{
			const pane = this.items()[i];
			toShow.push(pane);

			if (this.isSinglePane())
			{
				usedSize += 100;
			}
			else
			{
				//the first pane in our reverse list will the the dominant pane, so we use it's original size
				usedSize += (i == vpIndex-1) ? pane.baseSize() : pane.size();
			}
			
			if (usedSize >= 100) break;
		}

		return toShow;
	};


	checkRemoveQueue()
	{
		return;
		this.#removeQueue.forEach( (pane,idx) => {

			//finish the remove and kill the animation
			pane.remove();

		});

	};

	/**
		convenience functions
		*/
	pop(animated)
	{
		this.currentPane().pop(animated);
	};

	/**
		removes all panes after the index of the one passed
		*/
	popTo(pane,animated)
	{
		this.popToIndex( pane.getIndex(), animated);
	};

	popToIndex(idx,animated)
	{
		const paneIdx = parseInt(idx) + 1;
		this.removePane( this.items()[paneIdx], animated );
	};

	paneAtIndex(idx)
	{
		return this.items()[idx];
	};

	currentPane() 
	{
		return this.items()[this.items().length-1];
	};

	/**
		adds a pane at the current displayed pane index,
		replaces the already visible pane
		*/
	addHere(p,animated)
  {
    let idx = (this.currentPane()) ? this.currentPane().getIndex() : '0';
    this.addAt(p,idx,animated);
  };

  /**
  	returns the pane responsible for the passed uri
  	*/
	paneAtURI(uri)
  {
    let ret = null;

    this.itemArray().some( pane => {

      if (pane.uri() == uri) 
      {
        ret = pane;
        return true;
      }
    });

    return ret;
  };

  /** shortcut to assign a handler to a newly added pane, after it has joined the stack **/
  onPane(eventName,handler)
  {
    //only add this trigger once
    this.once('pane:added',(evt) => evt.detail.pane.on(eventName,handler) );
  };
	
};

customElements.define('wd-panes',WDPanes);
