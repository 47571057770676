
//initialized at bottom of file

export default class WDStorage {

	#storage = null;

	load(data)
	{
		sessionStorage.wdStorage = (typeof(data) != 'undefined') ? JSON.stringify(data) : '{}';
		sessionStorage.dbStorage = sessionStorage.wdStorage;

		this.#storage = data;
	};
	
	get(key)
	{
		if (this.#storage == null) 
		{
			this.#storage = (typeof(sessionStorage.wdStorage) != 'undefined') ? JSON.parse(sessionStorage.wdStorage) : {};
		}
		
		return (typeof(this.#storage[key]) != 'undefined') ? this.#storage[key] : null;
	};
	
	set(key,val)
	{
		if (this.#storage == null) 
		{
			this.#storage = (sessionStorage.wdStorage) ? JSON.parse(sessionStorage.wdStorage) : {};
		}

		//store in the faster-read variable
		this.#storage[key] = val;
		
		//commit our changes
		sessionStorage.wdStorage = JSON.stringify(this.#storage);

		//save this new key to our database
		const p = new WDReq('/accounts/storage');
		p[key] = val;
		return p.post();
	};
};

