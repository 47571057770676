
import WDCard from './wd-card';
import WDListForm from '../lists/wd-list-form';
import WDNavbar from '../navbar/wd-navbar';

export default class WDCardListForm extends WDCard {

	#config;
	#proxy;
	#fetch;
	#list;
	#filters;
	      	
	constructor(cfg)
	{
		super(cfg).classList.add('wd-card-list','wd-card-list-form');

		this.#config = Object.assign({},cfg);
	};

	/**
		* sets up the underlying list for the pane
		*/
	list(cfg) { 
	
		if (typeof(cfg) == 'undefined')
		{
			return this.#list; 
		}
		else
		{
			this.#list = new WDListForm(cfg);
			this.#list.classList.add('scrollable');
			this.body().append(this.#list);

			//I can't decide on naming yet
			if (cfg.filters) this.filters(cfg.filters);
			
			return this.#list;
		}
	};

	config() { return this.#config; };
	proxy() { return this.#list.proxy(); };
	fetch() { return this.#list.fetch(); };
	results() { return this.#list.results(); };
	load() { return this.#list.load(); };
		
  /**
  	* setup a filter bar for filtering our fetch requests
  	*/
  filters(cfg)
  {
    if (!this.#filters && typeof(cfg) != 'undefined')
    {
    	const nav = new WDNavbar();
    	this.header().append(nav);
    	
      //setup the navbar and use the search field as the brand
      this.#filters = nav.addFilters();
      this.#filters.on('filter:apply',() => this.runFetch() );

      this.header().append(this.#filters);
    }

    return this.#filters;
  };

  //legacy call
  refresh() { this.runFetch(); };

  runFetch()
  {
  	const formData = {};
  	
		if (this.#filters) formData.filters = this.#filters.serialize();

		//clean up our filter data to remove any nulls or empty values
		for (let key in formData)
		{
			const val = formData[key];

			//remove empty form data so it doesn't get passed to the api			
			if (val === null || String(val).length == 0) delete( this.fetch()[key] );

			//otherwise set the value to pass along
			else this.fetch()[key] = val;
		}

		//merge in sort data for the request - should this be done in 
		//WDListProxy since that's where the sorting is...
		if ( this.#list.sortField() != null ) this.fetch().sort_field = this.#list.sortField();
		if ( this.#list.sortDir() != null ) this.fetch().sort_dir = this.#list.sortDir();

		//run the search
		return this.#list.req();
  };

};

customElements.define('wd-card-list-form',WDCardListForm);
