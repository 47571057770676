
/**
	* we use maps so that any box can reference any filter after it's been moved or sorted.
	* way easier than trying to keep up with individual group and filter arrays every time
	*/
	
globalThis.WDPillFilterMap = {};

	/**
		*	@param filterConfig {Array}
		*	[
		*		{	label:'Search String', type:'text', name:'search_string', static: true },												static: always shows, no match value or display
		*		{	label:'Item List', type:'list', name:'item_list', options:this.getOptions(), simple:true },			simple: no match field, always "equals"
		*		{	label:'Adv List', type:'list', name:'advanced_list', options:this.getOptions() },								
		*		{ label:'Sale Date', type:'date', name:'sale_date' },
		*	]
		*
		*	@param filterValues {Object}
		*	{
		*		join: 'and',
		*		filters: [
		*			{ field:'area_acres', match: 'greater_than_equals', value:'50'} 
		*		]
		* }
		*/

import WDPillBox, {WDPill} from '../pill/wd-pillbox';
import WDUtil from '../../core/wd-util';
import WDDropdownForm from '../dropdown/wd-dropdown-form';
import WDButton from '../button/wd-button';
import WDAccordion from '../card/wd-accordion';
import WDAccordionCard from '../card/wd-accordion-card';
import { WDFormSelect } from '../form/wd-form-select';
import { WDFormHidden } from '../form/wd-form-hidden';

import './wd-filter-matches';

export default class WDPillBoxFilter extends WDPillBox {

	#filterConfigs = [];
	#joinForm = null;
	#addFilterBtn = null;
	#sortable;
	#uuid;
	
	constructor(filterConfigs,filterValues)
	{
		super().classList.add('wd-pill-box-filters');

		this.#uuid = WDUtil.uuid();
		this.setAttribute('data-uuid',this.#uuid);

		WDPillFilterMap[this.#uuid] = this;

		if (filterConfigs) this.#filterConfigs = filterConfigs;
		
		this.setupSortable();

		if (filterValues) this.populate(filterValues);
		else this.reset();
	};

	disconnectedCallback()
  {
    super.disconnectedCallback();

    if (this.#sortable)
    {
      this.#sortable.destroy();
      this.#sortable = null;
    }
  };

	uuid() { return this.#uuid; };
	
	reset(triggerUpdate)
	{
		super.reset();
		
		this.#joinForm = null;
		this.#addFilterBtn = null;

		this.joinForm();
		this.addFilterBtn();

		if (triggerUpdate) this.trigger('filters:updated');
	};

  setupSortable()
  {     	
  	import('sortable').then( module => {

      this.#sortable = module.Sortable.create( this.content(), {
      										group: 'wd-pill-filters',
													draggable: '.wd-pill-filter-group',
													animation: 150,
													fallbackOnBody: true,
													swapThreshold: 0.65,
													onEnd: (evt) => this.handleSort(evt),
													onMove: (evt) => this.handleSortMove(evt),
												});

    });
  };

  handleSortMove(evt)
  {
  	if (evt.related.classList.contains('no-sort') && !evt.willInsertAfter) 
  	{
  		return false;
		}
		else
		{
			return true;
		}
  }

  handleSort(evt)
  {
 		this.trigger('filters:updated');
	};

	/**
		this is the control toolbar for the filter group
		*/
	joinForm()
	{
		if (!this.#joinForm)
		{
			const cfg = {
				name: 'join',
				allow_clear: false,
				value: 'and',
				button: {
					icon: 'fa-solid fa-ellipsis-v',
					theme: 'info',
					update_text: (val) => {
						return ( String(val) == 'and' ) ? 'All' : 'Any';
					}
				},
				options:[
					{label:'Match All',value:'and'},
					{label:'Match Any',value:'or'}
				],
			};
		
			this.#joinForm = new WDDropdownForm(cfg);
			this.#joinForm.classList.add('filter-join-dropdown','no-sort');
			this.#joinForm.on('change',() => this.trigger('filters:updated') );
			this.#joinForm.on('options:set',() => {

				this.#joinForm.dropdown().append(wdc('<hr/>'));

				const addBtn = new WDButton({label:'Add Subgroup',fasIcon:'plus'});
				addBtn.on('click',() => this.addGroup() );
				addBtn.classList.add('filter-option-btn');
				this.#joinForm.dropdown().append(addBtn);

				//see if we have a parent box
				const parentBox = this.parentElement.closest('.wd-pill-box-filters');

				if ( parentBox )
				{
					const delBtn = new WDButton({label:'Remove Group',fasIcon:'trash'});
					delBtn.on('click',() => this.removeGroup() );
					delBtn.classList.add('filter-option-btn');
					this.#joinForm.dropdown().append(delBtn);
				}

				this.#joinForm.dropdown().append(wdc('<hr/>'));
				const resetBtn = new WDButton({label:'Reset Group',fasIcon:'rotate'});
				resetBtn.on('click',() => this.reset(true) );
				resetBtn.classList.add('filter-option-btn');
				this.#joinForm.dropdown().append(resetBtn);

			});

			this.content().append( this.#joinForm );
		}
		
		return this.#joinForm;
	};

	addFilterBtn()
	{
		if (!this.#addFilterBtn)
		{
			this.#addFilterBtn = new WDButton({fasIcon:'filter',theme:'primary'});
			this.content().append(this.#addFilterBtn);	

			const dd = this.#addFilterBtn.dropdown();
			dd.classList.add('filter-add-dropdown');
			this.#addFilterBtn.parentElement.classList.add('filter-add-dropdown-group');
			this.#addFilterBtn.setAttribute('data-bs-auto-close','outside');

			dd.append( wdc('<div class="filter-add-header">Filters</div>') );

			this.addFiltersWithoutCategories();
			this.addFiltersWithCategories();

			this.#addFilterBtn.parentElement.classList.add('no-sort');
		}
		
		return this.#addFilterBtn;
	};

	getCategories()
	{
		const cats = [];
		
		this.#filterConfigs.forEach( f => {
			if ( f?.category && !cats.includes(f.category) ) cats.push(f.category);
		});

		return cats;
	};

	addFiltersWithoutCategories()
	{
		const dd = this.#addFilterBtn.dropdown();

		const noCats = this.#filterConfigs.filter( x => !x?.category);
		noCats.sort((a, b) => a.label.localeCompare(b.label));

		noCats.forEach( f => {
			dd.append( this.filterAddRow(f) );
		});
	};

	addFiltersWithCategories()
	{
		const dd = this.#addFilterBtn.dropdown();

		//wrap them all into an accordion
		const accordion = new WDAccordion();
		dd.append(accordion);

		const categories = this.getCategories();

		categories.forEach( catName => {

			const filters = this.#filterConfigs.filter( x => x.category == catName);

			const card = new WDAccordionCard({collapsed:true});
			accordion.add(card);
			card.header().title().append(catName);

			filters.sort((a, b) => a.label.localeCompare(b.label));
			
			filters.forEach( f => {
				const row = this.filterAddRow(f);
				row.on('click',() => card.hide() );
				card.body().append(row);
			});

		});

	};

	filterAddRow(f)
	{
		const row = wdc(`<div class="filter-add-row">${f.label}</div>`);
		row.addEventListener('click',() => {

			const item = this.addFilter(f);
			item.on('filter:ready',() => item.dropdown().show() );
		});

		return row;
	};

	/**
		*/
	addGroup(val)
	{
		const group = new WDPillBoxFilter(this.#filterConfigs,val);
		this.content().append(group);

		group.on('group:removed',(evt) => this.handleGroupRemoved(evt) );
		this.trigger('group:added',{group:group});

		//carry the event on up the chain
		group.on('filters:updated',() => this.trigger('filters:updated') );

		return group;
	};

	addFilter(config,data)
	{
		const f = new WDPillFilter(config,data);
		f.on('filter:removed',(evt) => this.handleFilterRemoved(evt) );
		f.on('filter:updated',(evt) => this.handleFilterUpdated(evt) );
		this.content().append(f);

		//make sure the dropdown is closed
		f.on('filter:ready',() => {
			this.#addFilterBtn.dropdown().hide();
			this.trigger('filter:added',{filter:f});
		});

		return f;				
	};

	addFilterByName(filterName,data)
	{
		let cfg = this.getFilterConfigByName(filterName);
		
		if (!cfg)
		{
			cfg = {
				label: filterName.titleCase(),
				name: filterName,
				type: (data !== undefined) ? this.getTypeFromValue(data.value) : 'text'
			};
		}

		return this.addFilter(cfg,data);
	};

	getFilterConfigByName(filterName)
	{
		return this.#filterConfigs.find( f => f.name == filterName );
	};

	getFilterByName(filterName,filterVal)
	{
		let retFilter = null;
		let children = this.content().children;
		
		for (let i=0; i < children.length; i++)
		{
			const item = children[i];

			if (item.dataset.filterName && item.dataset.filterName == filterName)
			{
				const filterRef = WDPillFilterMap[item.dataset.uuid];

				if (filterVal === undefined || (filterVal !== undefined && filterVal == filterRef.value() ) )
				{
					retFilter = filterRef;
					break;
				}
			}

		}
		
		return retFilter;
	};
	
	removeFilterByName(filterName,filterVal)
	{
		const f = this.getFilterByName(filterName,filterVal);
		if (f) 
		{
			f.removeFilter();
			return true;
		}
		else
		{
			return false;
		}
	};

	removeAllFiltersByName(filterName)
	{
		while (true)
		{
			const ret = this.removeFilterByName(filterName);
			if (!ret) break
		}
	};
	
  getTypeFromValue(v)
  {
    const data = String(v);
      
    let type = 'text';

    if ( data.isNumeric() ) type = 'number';
    else if ( data.isDate() )
    {
      type = ( data.indexOf(' ') != -1 && data.indexOf('00:00:00') === 0 ) ? 'datetime-local' : 'date';
    }

    return type;
  };

	removeGroup()
	{
		delete(WDPillFilterMap[this.#uuid]);

		this.remove();
		this.trigger('group:removed',{group:this});			

		//remove all elements with uuids from the map since they won't be removed individually
		this.find('[data-uuid]').forEach( elem => {
			const uuid = elem.dataset.uuid;
			delete(WDPillFilterMap[uuid]);
		});
	};

	handleGroupRemoved(evt)
	{
		this.trigger('filters:updated',{filter:this});		
	};
	
	handleFilterRemoved(evt)
	{
		this.trigger('filters:updated',{filter:this});		
	};

	handleFilterUpdated(evt)
	{
		this.trigger('filters:updated',{filter:this});
	};

	serialize()
	{
		const data = { 
			join: this.#joinForm.val(),
			filters: []
		};

		this.itemArray().forEach( elem => {
		
			if (elem.classList.contains('wd-pill-filter-group'))
			{
				const item = WDPillFilterMap[elem.dataset.uuid];
				if (item)
				{
					const filterData = item.serialize();
					if (filterData !== null) data.filters.push(filterData);
				}
				else
				{
					delete(WDPillFilterMap[elem.dataset.uuid]);
				}
			}
			else if (elem.classList.contains('wd-pill-box-filters'))
			{
				const item = WDPillFilterMap[elem.dataset.uuid];
				if (item)
				{
					const groupData = item.serialize();
					if (groupData.filters.length > 0) data.filters.push(groupData);
				}
				else
				{
					delete(WDPillFilterMap[elem.dataset.uuid]);
				}
			}

		});
		
		return data;
	};

	filterCount()
	{
		return this.countFilters( this.serialize() );
	};
	
	countFilters(obj)
	{
    if (!obj.filters) return 0;

    // Initialize the count for the current level
    let count = 0;

    // Loop through each filter in the 'filters' array
    obj.filters.forEach(filter => 
    {
        // If the filter has a 'method' property, it means it is a nested object
        // with more filters. Recursively call countFilters on it and add to the count.
        if (filter?.join !== undefined) count += this.countFilters(filter);
        else count++;
    });

    // Return the total count of filters found at this level and below
    return count;
	};

	populate(data)
	{
		this.reset();
		this.#joinForm.val( data.join );
		
		data.filters.forEach( filterData => {
		
			if (filterData.join)
			{
				this.addGroup(filterData);
			}
			else
			{
				this.addFilterByName(filterData.field,filterData);
			}		
		});
	};
};

class WDPillFilter extends WDPill {

	#config = {};
	#uuid;
	#clearBtn;
	#matchForm = null;
	#valueForm = null;
	#applyBtn = null;
	#hideBtn = null;
	#optionsLoaded = false;
	#timer;

	constructor(cfg,val)
	{
    //try to create a label from the name if we don't have one
    super().classList.add('wd-pill-filter');
    
    this.#config = WDUtil.merge({},cfg);
    this.#uuid = WDUtil.uuid();

		WDPillFilterMap[this.#uuid] = this;

    this.reformatConfig();
    
		this.on('filter:updated',() => this.updateItemDisplay() );
    this.on('valueform:ready',() => {

			if (val) this.populate(val);
			else this.updateItemDisplay();

			this.trigger('filter:ready');
    
    });
    
	};

	connectedCallback()
	{
		if (!this.parentElement.classList.contains('btn-group'))
		{
			this.buildLayout();
		}		
	};
	
	config() { return this.#config; };
	uuid() { return this.#uuid; };
	
	reformatConfig()
	{
		//may be a better way to do this, but this works for now
		if (this.#config.type == 'boolean')
		{
			this.#config.type = 'select';
			this.#config.simple = true;
			this.#config.options = [{label:'Not Selected',value:'-1'},{label:'Yes',value:'1'},{label:'No',value:'0'}];
		}
	
	};

	buildLayout()
	{
    //leading icon if set, and the remove button
    if (this.#config?.icon) this.icon(this.#config.icon);

		this.buildDropdown();

		const removeBtn = new WDButton().fasIcon('times');
		removeBtn.on('click',() => this.removeFilter() );
		this.suffix().append(removeBtn);

		this.matchForm();
		this.valueForm();

	};

	removeFilter()
	{
		delete(WDPillFilterMap[this.#uuid]);
		this.trigger('filter:removed',{filter:this});			
		this.parentElement.remove();
	};

	buildDropdown()
	{
		this.setAttribute('data-bs-auto-close','outside'); //false);

		const mf = wdc('<div class="item-match"/>');
		const vf = wdc('<div class="item-value"/>');
		
		this.dropdown().append(mf);
		this.dropdown().append(vf);

		this.parentElement.classList.add('wd-pill-filter-group');
		this.parentElement.setAttribute('data-uuid',this.#uuid);
		this.parentElement.setAttribute('data-filter-name',this.#config.name);
		
		const tb = wdc('<div class="item-toolbar"/>');
		tb.append( this.hideBtn() );
		this.dropdown().append(tb);
		
		if (this.#config.menu_position) this.classList.add(this.#config.menu_position);

		this.on('dropdown:shown',() => this.valueForm().focus() );

	};

	hideBtn()
	{
		if (!this.#hideBtn)
		{
			this.#hideBtn = new WDButton({label:'Close Menu',fasIcon:'times'});
			this.#hideBtn.classList.add('close-dropdown-btn');
			this.#hideBtn.on('click',() => this.dropdown().hide() );
		};
		
		return this.#hideBtn;
	};

	/**
		* once a value is set, update the button text to reflect
		* what's going on 
		*/
	updateItemDisplay()
	{
		const name = this.#config.label;
		const match = this.getMatchLabel();				
		const value = this.getValueLabel();

		const str = `${name} ${match} ${value}`;
		this.content().replaceChildren(str);
	};	

	getMatchLabel()
	{
		const options = this.getMatchOptions();
		const val = this.matchForm().val();
		let label = null;
		
		options.some( opt => {
			if (opt.value == val)
			{
				label = (opt.short_label || opt.label);
				return true;
			}
		});
		
		return label;
	};

	serialize()
	{
		if ( this.hasValue() )
		{
			return { 	
				field: this.#config.name,
				match: this.#matchForm.val(),
				value: this.#valueForm.val()
			};
		}
		else
		{
			return null;
		}
	};

	populate(data)
	{
		if (!data?.match) data.match = this.#config.type == 'text' ? 'contains' : 'equals';
		
		this.matchForm().val( data.match );

		if (data.valueLabel) 
		{
			this.valueForm().setValFromLabels( data.valueLabel ).then( () => {
				this.trigger('filter:updated');
			});
		}
		else 
		{
			this.valueForm().val( data.value );
			//this.updateItemDisplay();
			this.trigger('filter:updated');	//this replaces the filter:updated call in addFilter(.  Not sure why it wasn't this way at first
		}
	};

	value()
	{
		return this.#valueForm.val();
	};
	
	getMatchOptions()
	{
		return (typeof(WDFilterMatches[ this.#config.type ]) != 'undefined') ? WDFilterMatches[ this.#config.type ] : WDFilterMatches.text;
	};
	
	matchForm()
	{
		if (!this.#matchForm)
		{
			//can't figure out how to just inherit this
			if ( this.isAdvanced() )
			{
				const matchCfg = { 	
					options: this.getMatchOptions(), 
					allow_clear: false 
				};

				this.#matchForm = new WDFormSelect(matchCfg);
				this.#matchForm.on('change',() => {
					if ( this.hasValue() ) this.triggerApply();
				});
			}
			else
			{
				//const matchVal = this.#config.type == 'text' ? 'contains' : 'equals';
				this.#matchForm = new WDFormHidden({name:'match',value:'equals'});
			}
			
			this.dropdown().querySelector('.item-match').append( this.#matchForm );
			
		}
		
		return this.#matchForm;
	};	

	hasValue()
	{
		return String( this.#valueForm.val() ).length > 0;
	};

	valueForm()
	{
    if (!this.#valueForm)
    {
      this.#config.autocomplete = 'off';

      const uri = './wd.js'; //./wd-form-${this.#config.type.toLowerCase()}`;
      const fn = `WDForm${this.#config.type.toLowerCase().replace('-','').capitalize()}`;

      import(uri).then( module => {
      
      	this.#valueForm = new module[fn]( this.#config );
      	this.dropdown().query('.item-value').append( this.#valueForm );
      	
      	if (this.#config.type == 'text')
      	{
          this.#valueForm.on('input',() => this.triggerDelayedApply() );
				}
        else
        {
          this.#valueForm.on('change',() => this.triggerApply() );
          
          this.#valueForm.on('options:set',() => {
						this.#optionsLoaded = true;
						this.updateItemDisplay();
          });
				}
				
				this.trigger('valueform:ready');

      });

    }

		return this.#valueForm;	
		
	};

	triggerDelayedApply()
	{
		if (this.#timer) clearTimeout(this.#timer);
		this.#timer = setTimeout( () => this.triggerApply(), 500);		
	};

	triggerApply()
	{
		this.trigger('filter:updated');
	};

	isAdvanced()
	{
		const advancedTypes = ['text','textlist','date','datetime','number','email'];
		return advancedTypes.includes(this.#config.type);
	};

	isMultipleChoice()
	{
		const hasOptions = ['select','dropdown','dropdownmulti','list','checklist'];
		return hasOptions.includes( this.#config.type );
	};

	getValueLabel()
	{
		let labelVal = this.#valueForm.val();
		
		//dropdown - has a method for generating the label
		if (this.#valueForm?.getValueLabel !== undefined)
		{
			labelVal = this.#valueForm.getValueLabel();
		}
		//it's a type with options, handle multiple/single selection option
		else if ( this.isMultipleChoice() )
		{
			const isMultiple = this.#valueForm.config().multiple == true;

			labelVal = (isMultiple) ? this.getMultipleLabel() : this.getSingleLabel();
		}

		return labelVal;				
	};

	getSingleLabel()
	{
		let labelVal = this.#valueForm.val();

		this.#valueForm.options().some( opt => {

			if (opt.value == labelVal)
			{
				labelVal = opt.label;
				return true;
			}
		});

		return (labelVal !== null) ? labelVal : 'Not Selected';
	};

	getMultipleLabel()
	{
		const labelVal = this.#valueForm.val();
		const labels = [];

		this.#valueForm.options().some( opt => {

			if (labelVal.indexOf( opt.value ) != -1)
			{
				labels.push(opt.label);
			}
		});

		return (labels.length > 0) ? labels.join(', ') : 'Not Selected';
	};


	/**
		* given the label of a selected option, pull the actual value of that option
		*/
	getValueFromLabel(label)
	{
		let retVal = label;
		const hasOptions = ['select','dropdown','dropdownmulti','list','checklist'];
		
		if ( this.isMultipleChoice() )
		{
			const retOpt = this.#valueForm.options().find( x => x.label == label );
			if (retOpt) retVal = retOpt.value;
		}
		
		return retVal;
	};

};

customElements.define('wd-pillbox-filter',WDPillBoxFilter);
customElements.define('wd-pill-filter',WDPillFilter);
