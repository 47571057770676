
import WDElement from '../wd-element'
import WDNavbar from '../navbar/wd-navbar';

export default class WDPane extends WDElement
{
	#panes;
	#header;
	#body;
	#footer;
	#navbar = null;
	#filters = null;
	#toolbar = null;
	#uri = null;
	#transitionMode = null;
	#backButton = null;
	#closeButton = null;
	#title = null;
	#permissions = null;
	#baseSize = null;
	#currentSize = null;
	#appConfig = {
		title: null,
		transition: null,
		uri: null,
		route: null
	};
	
	constructor(options)
	{
		super().classList.add('wd-pane');

		this.#baseSize = options?.size || 100;
		this.#appConfig.title = options?.title || null;
		this.#appConfig.uri = options?.uri || null;
		this.#permissions = options?.permissions || null;

	  this.dataset.baseSize = this.#baseSize;
	};
	
	connectedCallback()
	{
		super.connectedCallback();

		this.trigger('pane:added');
		this.panes().trigger('pane:added',{pane:this});
				
		//this allows me to know the index after the pane's removed from the DOM		
		const idx = this.panes().itemArray().indexOf(this);
		this.dataset.paneIndex = idx;

		this.body();
	};

	disconnectedCallback()
	{
		super.disconnectedCallback();
		
		this.trigger('pane:removed');
		this.panes().trigger('pane:removed',{pane:this});
				
		this.replaceChildren();
		this.offAll();

		this.#panes = null;
		this.#body = null;
		this.#footer = null;
		this.#navbar = null;
		this.#filters = null;
		this.#toolbar = null;
		this.#appConfig = null;
		this.#backButton = null;
		this.#closeButton = null;
		this.#permissions = null;
		this.#baseSize = null;
		this.#currentSize = null;
	};

	body() 
	{ 
		if (!this.#body)
		{
			this.#body = wdc('<div class="wd-pane-body"/>');
			
			if (this.#header) this.#header.after(this.#body);
			else if (this.#footer) this.#footer.before(this.#body)
			else this.append(this.#body);
		}
		
		return this.#body;
	};

	header() 
	{ 
		if (!this.#header)
		{
			this.#header = wdc('<div class="wd-pane-header"/>');
			this.prepend(this.#header);
		}
		
		return this.#header;
	};

	footer() 
	{ 
		if (!this.#footer)
		{
			this.#footer = wdc('<div class="wd-pane-footer"/>');
			this.append(this.#footer);
		}
		
		return this.#footer;
	};
	
	permissions() { return this.#permissions; };
	
	realWidth()
	{
		return parseInt( this.panes().clientWidth * (this.size() / 100) );
	};

	setPixelWidth(debug)
	{
		this.style.width = `${this.realWidth()}px`;
	};
	
	setPercentWidth()
	{
		this.style.width = `${this.size()}%`;
	};

	setCurrentSize( val )
	{
		this.#currentSize = val;
		this.setAttribute('data-current-size',val);
		this.dataset.currentSize = val;
	};
	
	baseSize()
	{
		return this.#baseSize;
	};
	
	//read/write accessors	  
	size(val) 
	{
		if (typeof(val) != 'undefined')
		{
			this.#currentSize = val;
			this.dataset.currentSize = val;
			return this;
		}
		else if (this.panes().isSinglePane() === true)
		{
			return 100;
		}
		else
		{
			return (this.#currentSize !== null) ? parseInt(this.#currentSize) : parseInt(this.#baseSize);
		}
	};

	visible(val)
	{
		if (typeof(val) == 'undefined')
		{
			return this.classList.contains('is-visible');
		}
		else
		{
			if (val === true) this.classList.add('is-visible');
			else this.classList.remove('is-visible');
			
			return this;
		}
	};

	//read/write accessors	  
	title(str) 
	{
		if ( str === undefined )
		{
			return this.#appConfig?.title || '';
		}
		else
		{
			this.#appConfig.title = str;
			this.trigger('title:updated',{pane:this, title:str});
			return this;
		}
	};

	uri(str) 
	{
		if ( str === undefined )
		{
			return this.#appConfig?.uri || '';
		}
		else
		{
			this.#appConfig.uri = str;
			return this;
		}
	};

	appConfig(cfg) 
	{
		if ( cfg === undefined)
		{
			return this.#appConfig;
		}
		else
		{
			Object.assign(this.#appConfig,cfg);
			return this;
		}
	};

	setSiteURI(uri)
	{
		this.uri(uri);
		//WDController.history().replace({uri: uri});
	};
	
	panes()
	{
		if (!this.#panes) this.#panes = this.closest('.wd-panes');
		return this.#panes;
	};

	/**
		convenience functions
		*/

	pop(animated)
	{
		this.panes().removePane(this,animated);
	};

  back() 
  {
  	return this.pop(true); 
	};
  
  /**
  	pop to this pane by finding whatever pane is after this one
  	and popping it off the stack
  	*/
	popTo(animated)
	{
		let nextPane = this.nextPane();
		if (nextPane) nextPane.pop(animated);
	};

	resetBody()
	{
		this.#body.empty();
	};

	resetToolbar()
	{
		this.#footer.empty();
		this.#toolbar = null;
	};
	
	resetNavbar()
	{
		if (this.#navbar) this.#navbar.remove();
		if (this.#backButton) this.#backButton = null;
		if (this.#closeButton) this.#closeButton = null;
				
		this.#navbar = null;
	};

	resetFilters()
	{
		if (this.#filters) this.#filters.remove();
		this.#filters = null;
	};

	empty()
	{
		this.#body.replaceChildren();

		this.resetNavbar();
		this.resetFilterbar();
		this.resetToolbar();
	};

	reset()
	{
		this.empty();
	};

	getIndex()
	{
		return ( this.panes() ) ? this.panes().itemArray().indexOf(this) : -1;
	};
	
	nextPane()
	{
		return (this.getIndex() < this.panes().items().length - 1) ? this.panes().items()[this.getIndex() + 1] : null;
	};

	prevPane()
	{
		return (this.getIndex() > 0) ? this.panes().items()[this.getIndex() - 1] : null;
	};

	isCurrent()
	{
		return this.isLastPane();
	};

	isLastPane()
	{
		return (this.getIndex() == this.panes().items().length - 1);
	};
	
	addPane(p,animated)
	{
		if (animated === undefined) animated = true;
		this.panes().addAt(p,this.getIndex()+1,animated);
	};

	/**
		build in navigation
		*/
  navbar(opts)
  {
    if (!this.#navbar)
    {
			let options = {collapsible:false};
			Object.assign(options,opts);

      this.#navbar = new WDNavbar(options);
      this.header().append(this.#navbar);
    }

    return this.#navbar;
  };

  toolbar()
  {
    if (!this.#toolbar)
    {
      this.#toolbar = new WDNavbar({collapsible:false});
      this.footer().append(this.#toolbar);
    }
    return this.#toolbar;
  };

  hasFilters()
  {
  	return this.#filters != null;
	};

  filters(opts)
  {
    if (!this.#filters)
    {
			this.#filters = this.navbar().addFilters(opts);
    }

    return this.#filters;
  };

  /**
  	* back button on the left size of the navbar
  	*/
  backButton(handler)
  {
  	if (this.getIndex() == '0') return;
  	
  	if (!this.#backButton)
  	{
  		if (!handler) handler = () => this.back();
  		this.#backButton = this.navbar().prefix().add({fasIcon:'chevron-left'});
  		this.#backButton.on('click',() => handler() );	
  		this.navbar().prefix().classList.add('has-back-button');
		}
		
		return this.#backButton;
  };

  /**
  	* close button on the right side of the navbar
  	*/
  closeButton(handler)
  {
  	if (!this.#closeButton)
  	{
  		if (!handler) handler = () => this.back();
  		this.#closeButton = this.navbar().suffix().add({fasIcon:'times'});
  		this.#closeButton.on('click',() => handler() );	
		}
		
		return this.#closeButton;
  };

};

customElements.define('wd-pane',WDPane);
