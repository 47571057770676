
import { WDFormInput } from './wd-form-input';

export class WDFormCurrency extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'number';
    cfg.step = '.01';
    super(cfg);
  };
};

customElements.define('wd-form-currency',WDFormCurrency);
