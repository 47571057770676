
/**
	* when called, check to see if we are authorized.  If not, then show the login modal.
	* my thinking is if the site doesn't need auth, then we don't put the tag in.
	*/

import WDElement from '../gui/wd-element';
import WDModal from '../gui/modal/wd-modal';
import WDUserPass from './wd-user-pass';
import WDKeypad from './wd-keypad';
import WDUtil from '../core/wd-util.js';
import WDStorage from '../core/wd-storage';
import WDApi from '../core/fetch/wd-api.js';

export default class WDSiteAuth extends WDElement {

	#title = null;
	#modal = null;
	#login = null;
	#password = null;
	#account = {};
	#authorized = false;
	#submitBtn = null;
	#visible = false;
	#loading = null;
	#loginMessage = null;
	#keypadForm = null;
	#userpassForm = null;

	constructor()
	{
		super();
		globalThis.WDAuth = this;
	};
		
	connectedCallback()
	{
		this.#title = (WDConfig.auth?.title) ? WDConfig.auth?.title : 'Login';

		//just make it publically accessible, passthru;
		this.storage = new WDStorage();

		if (sessionStorage.AuthAccount)
		{
			this.#account = JSON.parse(sessionStorage.AuthAccount);

			//pull our config info passed from the api and merge it in			
			const cfg = (sessionStorage.SiteSetup) ? JSON.parse(sessionStorage.SiteSetup) : {};
			WDUtil.merge(WDConfig.api,cfg);
			
			this.#authorized = this.#account.authorized;
			
			//break out of the main loop and show we're authorized
			this.trigger('auth:authorized');
		}

		this.on('auth:logout',() => this.modal() );
		
		this.authorize();
	};

	//accessors
	token() { return this.#account?.auth_token; };
	login() {return this.#account?.login;};
	authorized() { return this.#authorized;};
	account() { return this.#account; };
	
	formReset()
	{
		this.#account = {};
		this.#authorized = false;
		this._saveCredentials = false;
	};

	authFail(resp)
	{
		if (!this.#modal)
		{
			//there's no modal
			this.modal();
		}
		else
		{
			const msg = 'Username and/or password is invalid';

			this.#loginMessage.replaceChildren();
			this.#loginMessage.append(msg);
			this.#loginMessage.style.display = 'block';

			//reset the button so we can try again			
			this.#submitBtn.busy(false);
		}
	};

	authSuccess(data)
	{
			//if it works, we'll be passed back account and record info
			if (data.user)
			{
				//we will almost always have a user.  This is conditional because we have a couple of installs
				//that hide behind a username and password if not on the local subnet
				this.#account = data.user;
				this.#account.authorized = true;

				sessionStorage.AuthAccount = JSON.stringify(this.#account);
			}

			//record our success			
			this.#authorized = true;

			//store our server-side site variables
			if (data.client && Object.keys(data.client).length > 0) 
			{
				WDUtil.merge(WDConfig.api,data.client);
				sessionStorage.SiteSetup = JSON.stringify(data.client);
			}

			if (data.storage) this.storage.load(data.storage);
			
			this.trigger('auth:authorized');

			if (this.#modal) this.#modal.hide();
	};


	authorize()
	{
		let p = new WDApi('/');
		p.post().then( data => {
			this.authSuccess(data);
		})
		.catch( err => {
		
			console.log('Authorize Fail',err);
			this.authFail(err);
					
		});

	};

	loggedIn()
	{
		return (this.#account && this.#account.id);
	};

	//determines if the current user can do something
	can(perm)
	{
		//if not logged in, nothing works
		if (!this.loggedIn()) return false;

		//if perm = Login (are they logged in) and we made it this far, just return true
		//Login can only be passed by itself
		if (perm == 'Login') return true;
		
		//convert to an array if not passed one
		if (typeof(perm) == 'string') perm = perm.split(',');

		//always do an admin check
		if (perm.indexOf('Admin') == -1) perm.push('Admin');

		let ret = false;
		const accountBitmask = this.#account.bitmask.replace('0b','');

		perm.some( p => {

			if (typeof(WDConfig.api.permissions[p]) != 'undefined')
			{
				//p is the name of the permission (Admin,Admin, etc)
				let bitpos = WDConfig.api.permissions[p].value;
				let bitset = String(accountBitmask).substr(bitpos,1);
				let adminset = String(accountBitmask).substr(0,1);

				if (this.#authorized == true && (bitset == '1' || adminset == '1'))
				{
					ret = true;
					return true;
				}
			}
			else
			{
				console.log('Permission ' + p + ' not defined in',WDConfig.api.permissions);
			}
			
		});

		return ret;

	};

	handleKeyUp(evt) 
	{
	  if (!evt) evt = window.event;
	  if (evt.which=="13") this.authorize(true);
	};

	modal(refreshPage)
	{
		if (!this.#modal)
		{
			this.#modal = new WDModal({title:this.#title, allowClose:false, allowOutsideClose:false});
			this.#modal.on('modal:hide',() => this.#modal = null );
			this.#modal.hideFooter();

			this.formReset();
			this.hideLoading();
			
			this.#modal.on('modal:show',() => this.showLogin() );
			this.#modal.show();

			//if asked to refresh, refresh the screen when we log in			
			if (refreshPage)
			{
				this.once('auth:authorized',() => this.refreshAuthorized() );
			}
		}
		
		return this.#modal;
	};

	refreshAuthorized()
	{
		location.href = location.href;
		//this.off('auth:authorizedk',this.refreshAuthorized);
	};

	showLogin()
	{
		if (WDConfig.auth.mode == 'pin') 
		{
			this.keypadForm();
		}
		else 
		{
			this.userpassForm();
		}
		
		if (!this.isStorageSupported())
		{
			let msg = 'Your browser appears to be in Private Mode.  You will not be able to log in';
			this.#loginMessage.append(msg);
		}
		else
		{
			this.#loginMessage.style.display = 'none';
		}
	};

	getAuthorization()
	{
		let ret = '';
		
		if ( this.token() )
		{
			ret = 'Bearer ' + this.token();
		}
		else if ( (WDConfig.auth.mode == 'default' || WDConfig.auth.mode == 'pin') && this.#login && this.#password)
		{
			ret = 'Basic ' + btoa( this.#login + ':' + this.#password );
		}
		
		return ret;
	};

	
	keypadForm()
	{
		this.#modal.setPosition('top');
		
		this.#keypadForm = new WDKeypad({placeholder:'PIN'});
		
		this.#keypadForm.on('keypad:submitted',() => {
			this.#login = 'wdpinlogin';
			this.#password = this.#keypadForm.val();
			this.authorize();
		});

		this.#loginMessage = this.#keypadForm.body();
		this.#loginMessage.style.display = 'none';
		this.#submitBtn = this.#keypadForm.enterBtn();
		
		this.#modal.body().append(this.#keypadForm);

	};

	userpassForm()
	{
			this.#userpassForm = new WDUserPass();
			this.#userpassForm.on('form:submitted',() => {

				let formData = this.#userpassForm.val();

				this.#login = formData.login;
				this.#password = formData.password;

				this.#submitBtn.busy(true);
				this.authorize();
			});

			this.#loginMessage = this.#userpassForm.body();
			this.#loginMessage.style.display = 'none';

			this.#submitBtn = this.#userpassForm.enterBtn();

			this.#modal.body().append(this.#userpassForm);
	};

	logout()
  {
		//doing an actual logout, clear everything
		sessionStorage.clear();
		localStorage.clear();

		document.body.classList.add('login');

		this.#authorized = false;
		
		//log out w/ the api first
		const p = new WDApi('/logout');
		p.post().finally( resp => {
			this.trigger('auth:logout');
		});

  };	

	showLoading()
  {
    if (!this.#loading) this.#loading = new WDLoading();
    this.#loading.style.display = 'block';
  };

	hideLoading()
  {
  	if (this.#loading) this.#loading.style.display = 'hide';
  };

	isStorageSupported() 
	{
    let testKey = 'test', storage = window.sessionStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch (error) {
        return false;
    }
	};
};


customElements.define('wd-site-auth',WDSiteAuth);
