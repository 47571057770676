
/** 
	modes obtained by adding classes
*/

import WDElement from '../wd-element';
import WDAccordionCard from './wd-accordion-card';

export default class WDAccordion extends WDElement {

	#options = { 
		mode:'multiple',
		sortable: false 
	};

	#cards = [];
	#sortable = null;
	
	constructor(opts)
	{
		super().classList.add('wd-accordion');
		if (opts) Object.assign(this.#options,opts);
	
		if (!this.#options.mode) this.#options.mode = 'multiple';

		return this;
	};

	disconnectedCallback()
	{
		super.disconnectedCallback();
		this.offAll();

		if (this.#sortable)
		{			
			this.#sortable.destroy();
			this.#sortable = null;
		}
	};
	
	sortable()
	{
		import('sortable').then( module => {

			this.#sortable = module.Sortable.create(this, {
				handle: '.handle',
				dataIdAttr: 'id',
				onEnd: (evt) => this.trigger('accordion:sort',this),
			});

		});
	};

	//readonly link to panels
	cards() { return this.#cards; };
	options() { return this.#options; };

	/**
		reset the accordion back
		*/
	reset()
	{
		this.empty();
		this.#cards = [];
	};

	push(toggle)
	{
		//build the pane and save
		let card = new WDAccordionCard(toggle);
		this.add(card);

		return card;
	};

	add(card)
	{
		this.append(card);
		this.#cards.push(card);

		if (this.#options.mode == 'single')
    {
      card.on('accordion:show',() => {
        this.#cards.forEach( c => c.hide() );
      });
    }

		if (this.#options.sortable == true) this.sortable();
	};

	insertAt(idx,toggle)
	{
		let card = new WDAccordionCard(toggle);
		
		if (idx == '0')
		{
			this.prepend(card);
			this.#cards.unshift(card);
		}
		else if (idx >= this.#cards.length - 1)
		{
			this.append(card);
			this.#cards.push(card);
		}
		else
		{
			card.insertBefore(this.#cards[idx]);
			this.#cards.splice(idx,0,card);
		}

		if (this.#options.sortable == true) this.sortable();
		
		return card;
	};

	handleCardRemoved(evt,card)
	{
	};

	showAll()
	{
		this.cards().forEach( (p) => p.show() );
	};

	hideAll()
	{
		this.cards().forEach( (p) => p.hide() );
	};

	toggleAll()
	{
		let visible = 0;

		this.cards().forEach( (p) => {
			if (!p.collapsed()) visible++;
		});

		if (visible >= this.cards().length / 2) this.hideAll();
		else this.showAll();

	};

	serializeSort()
	{
		let sorted = this.#sortable.toArray();
		return sorted;
	};

	/** visibility methods **/
	show(idx) { this.#cards[idx].show(); };
	hide(idx) { this.#cards[idx].hide(); };
	toggle(idx) { this.#cards[idx].toggle(); };
	cardAtIndex(idx) { return this.#cards[idx]; };

};

customElements.define('wd-accordion',WDAccordion);
