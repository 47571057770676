
import WDUtil from '../wd-util';
import WDError from '../wd-error';
import { WDProxy } from '../wd-proxy';
import WDFetchError from './wd-fetch-error';

/**
	WDFetch
	*/

export default class WDFetch extends WDProxy {

	#controller = null;

	__className = 'WDFetch';
	__config = { 	contentType: 'application/json',
								auth: {},
								baseURL: null,
								baseURI: null,
								baseObject: null,
								allowMultipleRequests: false,
								headers: {}
							}; 

			
	constructor(config,values)
	{
		//if we are passed a base object to work with, use it to init underlying proxy.  otherise start empty
		const initObj = (typeof(config) == 'object' && typeof(config.baseObject) == 'object') ? config.baseObject : {};
		super(initObj);

		if (typeof(config) == 'string') 
		{
			this.__config.baseURI = config;
		}
		else if (typeof(config) == 'object')
		{
			WDUtil.merge(this.__config,config);

			//passed a base object with a uri to link to			
			if (!this.__config.baseURI && this.__config.baseObject && this.__config.baseObject.uri)
			{
				this.__config.baseURI = this.__config.baseObject.uri;
			}
		}

		if (values != undefined) this.add(values);
	};

	//backwards compatiblity - for now
	add(obj)
	{
		return this.assign(obj);
	};

	abort()
	{
		console.log('Aborting request');
		this.#controller.abort('queue');
	};

	getURI(addURI)
	{
		let uri = '';
		
		//given a base uri to work with
		if (this.__config.baseURI) uri += this.__config.baseURI;

		//given a supplemental uri to the base url and base uri
		if (addURI) uri += addURI;

		return uri;
	};

	setBaseURI(uri)
	{
		this.__config.baseURI = uri;
	};

	buildURL(uri)
	{
		let url = '';
		if (this.__config.baseURL) url += this.__config.baseURL;

		const addURI = this.getURI(uri);
		if (addURI) url += addURI;

		try {
			return new URL(url);
		}
		catch(err)
		{
			console.log('Error forming URL',url,err);		
		}
	};

	buildHeaders()
	{
		const headers = { 
			'Content-Type': this.__config.contentType,
			'X-Requested-With': 'XMLHttpRequest' 
		};

		this.buildAuth();

		Object.assign(headers,this.__config.headers);

		return new Headers(headers);
	};

	buildAuth()
	{
		if (this.__config.auth)
		{
			const auth = this.__config.auth;

			if (auth.mode == 'basic')
			{
				this.__config.headers.Authorization = 'Basic ' + btoa(auth.login + ':' + auth.password);
			}
			else if (auth.mode == 'digest')
			{
			}
		}
	};


	buildRequest(method,uri)
	{
		//setup a controller so we can do aborts
		this.#controller = (this.__config.allowMultipleRequests === false) ? new AbortController() : null;

		//create a base url
		const url = this.buildURL(uri);

		if (typeof(method) == 'undefined') method = 'GET';

		//options for the Request object
		const options = this.buildOptions(method,url);

		//return the request
		return new Request(url, options);
	};

	buildOptions(method,url)
	{
		const options = {	method: method,
											headers: this.buildHeaders()
										};

		if (this.#controller !== null) options.signal = this.#controller.signal;
		if (this.__config.auth != false) options.credentials = 'include';
		
		const dataObj = this.toObject();
		const dataKeys = Object.keys(dataObj);

		if (dataKeys.length > 0)
		{
			//if it's a method that can have a body, add the body	
			if (method == 'PUT' && dataObj.file)
			{
				options.body = dataObj.file;
			}
			else if (method == 'GET')
			{
				dataKeys.forEach(key => url.searchParams.append(key, dataObj[key]) );
			}
			else
			{
				options.body = JSON.stringify(dataObj);
			}
		}

		return options;
			
	};

	responseContentType(response)
	{
		const ct = response.headers.get("content-type");
		const pos = ct.indexOf(';');
		
		return (pos !== -1) ? ct.substr(0,pos) : ct;
	};

	req(method,uri)
	{
		console.log('Running ' + method + ' request',this.__config.baseURI,uri); //,this.toObject());
		
		if (this.trace_call) console.trace();		
		
		//if (this.__config.baseURI.indexOf('/components') != -1) console.trace();

		//cancel any current request
		if (this.#controller) this.abort();

		//build the request
		const request = this.buildRequest(method,uri);

		//for some error handling later
		let cloneResp = null;

		//run the fetch
		const ftch = fetch(request).then( response => {

			//clone the response for error handling
			cloneResp = response.clone();
			
			//console.log("Response",cloneResp.json());
			const contentType = this.responseContentType(response);

			//console.log('Headers',...response.headers);

			if (response.ok)
			{
				//everything came back okay, and it was the right response type
				if (contentType == this.__config.contentType)
				{
					return (contentType == 'application/json') ? response.json() : response.text();
				}
				//usually some sort of server side parse error
				else
				{
					throw new WDFetchError(response.message, {code:response.status, response:response});
				}
			}
			//this is usually a caught error, formatted properly
			else
			{
				throw new WDError(response.message, 
										{	code:response.status, 
											name:'FetchError', 
											contentType: contentType,
											response: cloneResp
										});
			}
		})
		.catch( err => {

			//do nothing on abort errors
			if (err.name == 'AbortError') 
			{
				throw new Error('AbortError');
			}
			//auth errors get special treatment
			else if (err.code == 401)
			{
				throw new Error('Unauthorized',{ code:err.code});
			}
			else if (err.name == 'FetchError')
			{
				const wdClone = cloneResp.clone();

				//once for the console				
				cloneResp.text().then( str => {
					console.log(err.name,str);
				});

				//throw another copy of the error to a catch later in the chain
				throw new WDError(err.message, 
										{	code:err.status, 
											name:err.name,
											contentType: err.contentType,
											response: wdClone
										});

			}
			else if (err.name == 'SyntaxError')
			{
				cloneResp.text().then( str => {
					console.log(err.name,str);
				});
			}
			//flush out the response for more detailed info on what happened
			else
			{
				//throw another copy of the error to a catch later in the chain
				throw new WDError(err.message, 
										{	code:err.status, 
											name:err.name,
											contentType: err.contentType,
											response: err.response
										});
			}
	
		})
		.finally( data => {
			this.#controller = null;
		});

		return ftch;
		
	};


	//wrappers for sending to the api
	post(uri) { return this.req('POST',uri); };
	put(uri) { return this.req('PUT',uri); };
	del(uri) { return this.req('DELETE',uri); };

};

