
import WDDropdownForm from './wd-dropdown-form';

/**
	Note:
	This form can store objects as values, but it uses the labels to search.  This
	way it matches what a user would type.  what they see is the value expected
	*/
export default class WDDropdownMultiForm extends WDDropdownForm {

	constructor(cfg)
	{
		if (cfg) cfg.multiple = true;
		super(cfg);
	};

};

customElements.define('wd-dropdown-multi-form',WDDropdownMultiForm);
