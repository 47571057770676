
import { WDFormInput } from './wd-form-input';

export class WDFormPassword extends WDFormInput {

  constructor(cfg)
  {
    cfg.type = 'password';
    super(cfg);
  };
};

customElements.define('wd-form-password',WDFormPassword);

