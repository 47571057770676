
import WDPillBox, {WDPill} from './wd-pillbox';
import WDNavbar from '../navbar/wd-navbar';
import { WDProxyArray } from '../../core/wd-proxy';

export default class WDPillBoxProxy extends WDPillBox {

  #sortable;
  #loading;
  #proxy;
  #config = {
    noResults: 'No results found',
    method: 'get',
    content: null,
    templates: {
      label: '${label}'
    }
  };
  #fetch;
  #results;
  #navbar;
  #filters;
  #templates = {
    label: '${label}'
  };
            
  constructor(cfg)
  {
    super().classList.add('wd-pill-box-proxy');

    Object.assign(this.#config,cfg);

    //if passed a proxy, use that as our model
    if (cfg.proxy !== undefined)
    {
      this.#proxy = cfg.proxy;

      //if it has a fetch method, this is a FetchArray proxy
      if (this.#proxy?.fetch !== undefined) this.#fetch = this.#proxy.fetch(); 
    }
    //make our own proxy-based model (legacy support)
    else
    {
      //create a proxy to work with
      this.#proxy = new WDProxyArray([]);

      //passed a separate fetch to work with
      if (this.#config?.fetch !== undefined) this.#fetch = this.#config.fetch;
    }

    //setup an event watcher for proxy set if we have a content handler
    this.#proxy.on('set', evtData => this.handleSet(evtData) );
    this.#proxy.on('delete',evtData => this.handleDelete(evtData) );

    //if passed data, populate the proxy
    if (this.#config.data) 
    {
      this.#proxy.replace(this.#config.data);
    }

    //add filters if passed
    if (this.#config.filters) this.filters(this.#config.filters);

    //setup for sorting
    if (this.#config.allow_sort) this.setupSortable();

  };

  disconnectedCallback()
  {
    super.disconnectedCallback();

    if (this.#sortable)
    {
      this.#sortable.destroy();
      this.#sortable = null;
    }
  };

  config() { return this.#config; };
  proxy() { return this.#proxy; };
  fetch() { return this.#fetch; };
    
  loading(show)
  {
    if (!this.#loading)
    {
      this.#loading = wdc('<div class="wd-pills-loading"/>');
      const icon = wdc('<span class="fa-spin"/>');
      icon.classList.add( this.#config.loadingIcon );
      this.#loading.append(icon);
      
      this.append( this.#loading );
    }

    if (show === true) this.classList.add('is-loading');
    else this.classList.remove('is-loading');
  };

  navbar()
  {
    if (!this.#navbar)
    {
      this.#navbar = new WDNavbar();
      this.#navbar.classList.add('wd-pill-box-navbar');

      this.prepend(this.#navbar);
      this.classList.add('has-navbar');
    }
    
    return this.#navbar;
  };

  filters(cfg)
  {
    if (!this.#filters)
    {
      //can be passed a full filter config. or just an array for more common usage
      const filterCfg = Array.isArray(cfg) ? {filters:cfg} : cfg;

      this.#filters = this.navbar().addFilters(filterCfg);
      this.#filters.on('filter:apply',() => this.req());
    }
    
    return this.#filters;
  };
  
  /**
    * updates the proxy contents with the results of the fetch
    */  
  req()
  {
    if (!this.#fetch) return false;
    
    if (this.#config.showLoading) this.loading(true);

    //merge in our filters if we have some setup
    if (this.#filters) 
    {
      this.#fetch.filters = this.#filters.serialize();
      this.#config.method = 'post';
    }

    //run the fetch and return it for promise functionality
    const ftch = this.#fetch.req( this.#config.method ).then( results => {

      if (this.#config.showLoading) this.loading(false);

      //setup external access to our results
      this.#results = results;
      this.trigger('results:fetched',results);

      //allow for a fetch key to be used in case the result is nested
      const fk = this.#config.resultKey;
      const resData = (fk != null && typeof(results[fk]) != 'undefined') ? results[fk] : results;

      this.#proxy.replace(resData || []);

      //not sure if this will work or not     
      //if (resData.length == 0) this.handleNoResults();

    });

    return ftch;
  };

  handleNoResults()
  {
    //check for a custom noResult handler, otherwise append the string
    const str = (typeof(this.#config.noResults) == 'function') ? this.#config.noResults() : this.#config.noResults;
    this.content().empty().append(str);
  };

  
  handleSet(evtData)
  {
    /**
      create/replace a row with this index
      */
    const idx = evtData.property;
    const value = evtData.value; 

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    //if value is undefined, the item is being removed
    if (typeof(value) == 'undefined')
    {
      this.removeItemAt(idx);
    }
    //not sure this handles replacing a row
    else
    {
      //call the handler to insert/update the item
      const item = this.replaceItemAt(idx);

      //set the data object for the list item
      item.data(value);

      //call the content handler
      if (this.#config.content != null && typeof(this.#config.content) == 'function')
      {
        this.#config.content(item,value);
      }
      else
      {
        this.addPillItem(item,value);
      }
    }
  };
  
  addPillItem(item,data)
  {
    item.label().append( this.#config.templates.label.interpolate(data) );

    //pass along some button properties    
    if (this.#config.icon) item.icon(this.#config.icon);
    if (this.#config.fasIcon) item.icon(`fa-solid fa-${this.#config.fasIcon}`);
    if (this.#config.faricon) item.icon(`fa-regular fa-${this.#config.faricon}`);
    if (this.#config.theme) item.theme(this.#config.theme);
    if (this.#config.size) item.size(this.#config.size);
    if (this.#config.suffix) item.suffix(this.#config.suffix);

    if (this.#config.click) item.on('click',() => this.#config.click(data,item) );

    return item;
  };

  handleDelete(evtData)
  {
    const idx = evtData.property;

    //if the property isn't numeric, then it's an internal array property we don't want
    if (parseInt(idx) != idx) return;

    this.removeItemAt(evtData.property);
    
    //if (this.items().length == 0) this.handleNoResults();
  };

  insert(idx)
  {
    const item = this.createItem();
    
    if (idx == 0) 
    {
      this.content().prepend(item);
    }
    else if (idx < this.items().length)
    {
      this.itemAt(idx).before(item);
    }
    else
    {
      this.content().append(item);
    }

    return item;
  };

  replaceItemAt(idx)
  {     	
    const item = this.createItem();
          
    if (idx < this.items().length)
    {   	
      const r = this.itemAt(idx);
      r.before(item);
      r.remove();
    }		
    else	
    {		
      this.content().append(item);
    }		
		
    return item;
  };		

  removeItemAt(idx)
  {
    if (this.itemAt(idx)) this.itemAt(idx).remove();
  };

  removeItems(arr)
  {
    arr.forEach( item => item.remove() );
  };

  createItem()
  {
    return new WDPill();
  };

  setupSortable()
  {
    import('sortable').then( module => {

      this.#sortable = module.Sortable.create( this.body(), {
                          draggable: '.btn',
                          animation: 150,
                          fallbackOnBody: true,
                          swapThreshold: 0.65,
                          onStart: (evt) => this.handleSortBegin(evt),
                          onEnd: (evt) => this.handleSortEnd(evt),
      });
    }); 	
  };    	
  		
  handleSortBegin(evt)
  {     	
    this.trigger('items:sort',evt);
  };    	
  		
  handleSortEnd(evt)
  {		
    this.trigger('items:sorted',evt);
  };		
  
};

customElements.define('wd-pillbox-proxy',WDPillBoxProxy);
