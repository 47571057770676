
import { WDFormInput } from './wd-form-input';

export class WDFormText extends WDFormInput {

  constructor(cfg)
  {
    if (!cfg) cfg = {};
    cfg.type = 'text';

    super(cfg);
  };
};

customElements.define('wd-form-text',WDFormText);
