/**
	*	Fetch class for interacting with the WD API
	*	@class WDF
	*/

import WDFetch from './wd-fetch'
import WDUtil from '../wd-util'

export default class WDApi extends WDFetch {

	__className = 'WDApi';

	constructor(baseCfg,values)
	{
		let authHeader = null;
		const cfg = { baseURL: WDConfig.api.url };

		//cfg passed, use that to talk to the api.  Otherwise fallback on our config and auth classes
		if (typeof(baseCfg) == 'string')
		{
			cfg.baseURI = baseCfg;
		}
		else if (typeof(baseCfg) == 'object')
		{
			 WDUtil.merge(cfg,baseCfg);
		}

   //normal username/password info
    if ( WDConfig.auth.mode != 'none' )
    {
      cfg.headers = { 'Authorization': WDAuth.getAuthorization() };
    }

		super(cfg,values);
  };

  directURL(params)
  {
    let url = this.url();
      
    url += (url.indexOf('?') != -1) ? '&' : '?';
    url += 'download=1&auth_user=' + WDAuth.login() + '&auth_pw=' + WDAuth.password();

    const str = WDUtil.param(this.data());
      
    if (str.length > 0) url += '&' + str;

    //allow additional params for the download only
    if (params)
    {
      const p = WDUtil.param(params);
      if (p.length > 0) url += '&' + p;
    }
      
    return url;
  };

  //sends the 
  download()
  {
    const p = new WDApi('/requesttoken');
    p.post().then( data => {

      let url = this.buildURL().href;
      url += url.indexOf('?') != -1 ? '&' : '?';
      url += 'nonce_token=' + data.nonce_token;
      
      const win = window.open(url,'_blank');
      if (!win) alert('It appears you have a popup blocker installed.  Please disable it and try again');
    });

  };

};

